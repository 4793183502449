import React, { useContext, useEffect, useRef, useState } from "react";
import {
  CustomStyle,
  Delivery,
  Images,
  NoCharges,
  Order,
  Payment,
  Printing,
} from "../../../assests/index";
import {
  AppBar,
  Avatar,
  Badge,
  Box,
  ButtonGroup,
  Card,
  CardContent,
  CardMedia,
  Checkbox,
  Chip,
  CircularProgress,
  ClickAwayListener,
  Container,
  Drawer,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  InputAdornment,
  LinearProgress,
  List,
  ListItem,
  ListItemText,
  Menu,
  MenuItem,
  Radio,
  RadioGroup,
  Skeleton,
  Stack,
  Switch,
  Tab,
  Tabs,
  TextField,
  ThemeProvider,
  ToggleButton,
  ToggleButtonGroup,
  Toolbar,
  Typography,
  styled,
  useMediaQuery,
  useScrollTrigger,
  useTheme,
} from "@mui/material";
import Colors from "../../../assests/style";
import { PrimaryButton, SecondaryButton } from "../../../components/Buttons";
import EastIcon from "@mui/icons-material/East";
import { PulseLoader, RingLoader } from "react-spinners";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import randomImg from "../../../assests/images/cair_03277_Red_riding_hood_in_iron_man_costume_6380ca9f-11bc-41a5-b7a5-9eaca2f76800.png";
import DownloadIcon from "@mui/icons-material/Download";
import "@fontsource/plus-jakarta-sans";
import { AccountCircle, BorderColor, Star } from "@mui/icons-material";
import "swiper/css";
import { Swiper, SwiperSlide } from "swiper/react";
import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh";
import TipsAndUpdatesIcon from "@mui/icons-material/TipsAndUpdates";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import CloudDownloadOutlinedIcon from "@mui/icons-material/CloudDownloadOutlined";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import OrderServices from "../../../services/OrderServices";
import background from "../../../assests/images/mayaccountbg.png";
import YouTube from "react-youtube";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import AuthServices from "../../../services/AuthServices";
import { getAuth, signInWithPopup, signOut } from "firebase/auth";
import { auth, provider } from "../../../config/firebase.config";
import { AuthContext } from "../../../Context/AuthContext";
import Swal from "sweetalert2";
import ReactCompareImage from "react-compare-image";
import confetti from "canvas-confetti";
import { VisibilityOff as VisibilityOffIcon } from "@mui/icons-material";
import { blue, grey, red } from "@mui/material/colors";
import SignInModal from "../../../components/LoginModal";
import { CartContext } from "../../../Context/CartContext";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import MenuIcon from "@mui/icons-material/Menu";
import { Scrollbar } from "swiper/modules";
import gif from "../../../assests/images/totourial.gif";
import { PrevIcon, NextIcon } from "../../../assests/index";
import { CancelOutlined } from '@mui/icons-material';
import WarningIcon from '@mui/icons-material/Warning';
import { WinPhoneView } from "react-device-detect";
import CloseIcon from '@mui/icons-material/Close';
import { Modal, Row, Col } from "antd"

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


// function HideOnScroll(props) {
//   const { children, window } = props;
//   const trigger = useScrollTrigger({
//     target: window ? window() : undefined,
//   });

//   return (
//     <Slide appear={false} direction="down" in={!trigger}>
//       {children}
//     </Slide>
//   );
// }

const Home = () => {
  // const { cartVisible, toggleCartVisibility } = useContext(CartContext);
  let User = localStorage.getItem("user");
  User = JSON.parse(User);
  const [loadedImages, setLoadedImages] = useState({});

  const handleImageLoad = (index) => {
    setLoadedImages((prev) => ({ ...prev, [index]: true }));
  };
  var detail = null

  const itemRefs1 = useRef([]);

  const auth = getAuth();
  const [open2, setOpen2] = useState(false);
  const [dropdown, setDropdown] = useState(false);
  const [credit, setCredit] = useState(false)
  const [selectedFinalImage, setSelectedFinalImage] = useState(false)
  const [libraryBtn, setLibraryBtn] = useState(false)

  const [cartData, setCartData] = useState(null);
  const handleOpenDrawer = (event) => {
    setOpen(event.currentTarget);
  };
  const progressRef = useRef(null);
  const upscale = useRef(null);
  const scrollToId = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };
  const [value3, setValue3] = React.useState(0);

  const handleChange2 = (event, newValue) => {
    setValue3(newValue);
  };

  const handleCloseDrawer = () => {
    setOpen(false);
  };
  const [anchorEl, setAnchorEl] = useState(null);
  let sliderData2 = [
    { img: Images.heroSlide1 },
    { img: Images.heroSlide2 },
    { img: Images.heroSlide3 },
    { img: Images.heroSlide4 },
    { img: Images.heroSlide5 },
    { img: Images.heroSlide1 },
    { img: Images.heroSlide2 },
    { img: Images.heroSlide3 },
    { img: Images.heroSlide4 },
    { img: Images.heroSlide5 },
    { img: Images.heroSlide1 },
    { img: Images.heroSlide2 },
    { img: Images.heroSlide3 },
    { img: Images.heroSlide4 },
    { img: Images.heroSlide5 },
    { img: Images.heroSlide1 },
    { img: Images.heroSlide2 },
    { img: Images.heroSlide3 },
    { img: Images.heroSlide4 },
    { img: Images.heroSlide5 },
    { img: Images.heroSlide1 },
    { img: Images.heroSlide2 },
    { img: Images.heroSlide3 },
    { img: Images.heroSlide4 },
    { img: Images.heroSlide5 },
    { img: Images.heroSlide1 },
    { img: Images.heroSlide2 },
    { img: Images.heroSlide3 },
    { img: Images.heroSlide4 },
    { img: Images.heroSlide5 },
    { img: Images.heroSlide1 },
    { img: Images.heroSlide2 },
    { img: Images.heroSlide3 },
    { img: Images.heroSlide4 },
    { img: Images.heroSlide5 },
    { img: Images.heroSlide1 },
    { img: Images.heroSlide2 },
    { img: Images.heroSlide3 },
    { img: Images.heroSlide4 },
    { img: Images.heroSlide5 },
  ];
  const items = [
    {
      id: 1,
      title: "Premium Quality",
      highlight: "Custom T-Shirts",
      description:
        "We use 180-190 GSM T-Shirts that have been rigorously tested to withstand multiple washes, ensuring durability for everyday use. Our shirts are tested with standard washing liquids and various drying techniques to guarantee quality.",
    },
    {
      id: 2,
      title: "Easy to",
      highlight: "Create, Customize",
      description:
        'After generating an image, click on "Customize Your T-Shirt" to select your preferred size, and then re-size and position the image. We’ll take care of the rest to ensure your design is accurately placed and sized. Refer to our T-Shirt size chart to find the perfect fit for your needs.',
    },
    {
      id: 3,
      title: "Standard",
      highlight: "Shipping",
      description:
        "Our shipping charges are AED 15 for up to 5 T-Shirts to a single location in the UAE. Additional T-Shirts will incur extra shipping charges. For orders of 25 or more T-Shirts to a single location within Dubai, Sharjah, and Ajman, the shipping fee is a fixed AED 50. For other emirates, the fixed charge is AED 100.",
    },
  ];



  const customize = [
    {
      id: 1,
      title: "AED 40",
      highlight: "No Hidden Charges",
      description:
        "Forget about extra charges Our pricing is simple and transparent.With PrintMeUp, you don't need to drive to a printer or pay for an image generator tool. We offer a cost effective solution for your custom t - shirts",
    },
    {
      id: 2,
      title: "AED 50",
      highlight: "Secure Payment",
      description:
        "Shop with confidence at PrintMeUp. Our secure payment options ensure your personal information is protected, and we do not store any of your payment details.",
    },
    {
      id: 3,
      title: "AED 10/Image",
      highlight: "Low Minimum Order",
      description:
        "Order with ease at PrintMeUp. Our low minimum order quantities make it simple and affordable to express your unique style and feelings with customized t-shirts.",
    },
    {
      id: 4,
      title: "AED 15",
      highlight: "High Quality Printing",
      description:
        "Experience the durability and longevity of DTF printing on premium t-shirts, ensuring top-notch quality every time.",
    },
    {
      id: 5,
      title: "AED 15",
      highlight: "Custom Size & Style",
      description:
        "Find the perfect fit. Choose from a wide range of sizes and styles to create a t-shirt that suits your unique preferences.",
    },
    {
      id: 6,
      title: "AED 15",
      highlight: "Fast Delivery",
      description:
        "Enjoy the convenience of fast delivery. Receive your custom t-shirts quickly, right at your doorstep.",
    },
  ];
  const handleProfile = () => {
    // Handle profile click
    console.log("Profile clicked");
    setOpen(false);
    window.location.href = "/my-account";
    handleClose();
  };

  const userProfile = async (sendData) => {
    console.log(sendData, "data");
    try {
      let params = {};
      const { data } = await OrderServices.userProfile(params);

    } catch (error) {
      console.log(error);
    } finally {
    }
  };

  const pages = [
    { name: "Home", url: "/" },
    { name: "Pricing", url: "", id: "pricing-section" },
    { name: "How it Works", url: "", id: "how-section" },
    { name: "Contact Us", url: "/contact-us" },
    { name: "Collection", url: "/collections" },
  ];

  const handleClose2 = () => {
    setOpen2(false);
  };

  const generateSession = async (sendData) => {
    console.log(sendData, "data");
    try {
      const { data } = await AuthServices.generateSession();
      console.log(data);
    } catch (error) {
      console.log(error);
    } finally {
    }
  };
  const handleLogout = async () => {
    try {
      localStorage.clear();
      setOpen(false);
      navigate("/");
      let user = localStorage.getItem("user");
      setUserData(JSON.parse(user));
      await signOut(auth);
      console.log("User signed out");
    } catch (error) {
      console.error("Error during sign out: ", error);
    }
  };
  useEffect(() => {
    let user = localStorage.getItem("user");
    if (user) {
      userProfile();
    }
    setUserData(JSON.parse(user));
    // setUser("");
    generateSession();
    let cartdata = localStorage.getItem("orderData");
    console.log(cartdata);
    if (cartdata) {
      console.log(cartdata, "cartdatacartdata");
      setCartData(cartdata);
      // toggleCartVisibility()
    } else {
      setCartData(null);
    }
  }, []);

  const section1Ref = useRef(null);
  const section2Ref = useRef(null);
  const upscalingRef = useRef(null);
  const navigate = useNavigate();
  const [expanded, setExpanded] = useState("panel1");
  const [loader, setLoader] = useState(false);
  const resultRef = useRef("");
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [isLoading, setIsLoading] = useState(false);
  const [result, setResult] = useState("");
  const [index, setIndex] = useState("");
  const [progress, setProgress] = useState(0);
  const [upScalingProgress, setUpScalingProgress] = useState(0);
  const [allDetails, setAllDetails] = useState();
  const [currentMessage, setCurrentMessage] = useState("");
  const [randomMsgs, setRandomMsgs] = useState(false)
  const [upScaleText, setUpScaleText] = useState(false);
  const [upScalingLoader, setUpScalingLoader] = useState(false);
  const [showDowloadBtn, setShowDowloadBtn] = useState(false);
  const [randomPrompt, setRandomPrompt] = useState("");
  const [customizeBtn, setCustomizeBtn] = useState(false);
  const [finalLoader, setFinalLoader] = useState(false);
  const [generateBtn, setGenerateBtn] = useState(true);
  const [generateText, setGenerateText] = useState(false);
  const [linearProgress, setLinearProgress] = React.useState(0);
  const [showlinearProgress, setShowLinearProgress] = useState(false);
  const [imagesGroup, setImagesGroup] = useState([]);
  const [upScaled, setUpScaled] = useState(false);
  const [upScaleLoader, setUpScaleLoader] = useState(false);
  const [upScaleData, setUpScaleData] = useState();
  const [scalingConfirmation, setScalingConfirmation] = useState(false);
  const [imgMaping, setImgMaping] = useState(true);
  const [confirmDialog, setConfirmDialog] = React.useState(false);
  const [selectScaleIndex, setSelectScaleIndex] = useState(1);
  const [userData, setUserData] = useState();
  const { user, setUser } = useContext(AuthContext);
  const [promptHeight, setPromptHeight] = useState(true);
  const inputRef = useRef(null);
  const [dragging, setDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);
  const [currentState, setCurrentState] = useState("login");
  const [checkModal, setCheckModal] = useState(false)

  const [selectedChips, setSelectedChips] = useState([]);
  const [magicPrompt, setMagicPrompt] = useState("No");
  const [aspectRatio, setAspectRatio] = useState("9:16");
  const [visibility, setVisibility] = useState("Public");
  const [comparison, setComparison] = useState(false);
  const [removedBgPic, setRemovedBgPic] = useState();
  const [removedBgPic2, setRemovedBgPic2] = useState();
  const [charges, setCharges] = useState(null)
  const { state, pathname } = useLocation();
  const confettiRef = useRef(null);
  const [switchState, setSwitchState] = useState(false);
  const [libraryUrl, setLibraryUrl] = useState(null)
  const scrollToSection = (section) => {
    if (section === "section1Ref") {
      section1Ref.current.scrollIntoView({ behavior: "smooth" });
    } else if (section === "section2Ref") {
      section2Ref.current.scrollIntoView({ behavior: "smooth" });
    } else if (section === "section3Ref") {
      upscalingRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const [successMessage, setSuccessMessage] = useState(null);
  const [collections, setCollections] = useState([]);
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [demoModal, setDemoModal] = useState(false)

  const [prevHover, setPrevHover] = useState(false);
  const [nextHover, setNextHover] = useState(false);
  const [data, setData] = useState(sliderData2); // Initialize state with sliderData2
  const [loaded, setLoaded] = React.useState(() => data.map(() => false));
  const [designLoader, setDesignLoader] = useState(false)
  const [errorModal, setErrorModal] = useState(false)
  const [testError, setTestError] = useState(false)
  const [streams, setStreams] = useState([]);

  const streamsRef = useRef([]);

  const [isLoadingResult, setIsLoadingResult] = useState(true);

  const handleImageLoadFinalResult = () => {
    setIsLoadingResult(false);
  };

  const handleImageLoad2 = (index) => {
    setLoaded((prev) => {
      const updated = [...prev];
      updated[index] = true;
      return updated;
    });
  };

  const [seconds, setSeconds] = useState(0);
  const [isRunning, setIsRunning] = useState(false);
  const [errorPopup, setErrorPopup] = useState(false)

  // Start the timer
  const startTimer = () => {
    const startTime = Date.now();
    localStorage.setItem("timerStart", startTime);
    setIsRunning(true);
  };
  console.log(seconds, 'seconds');





  const handleImageError = (index) => {
    setLoaded((prev) => {
      const updated = [...prev];
      updated[index] = false; // Remains false for error
      return updated;
    });
  };

  const scrollContainerRef = useRef(null);

  const useStyles = styled({
    blurredBackground: {
      filter: "blur(12px)", // Apply the blur effect
      transition: "filter 0.3s ease", // Smooth transition
    },
    popupImage: {
      width: "100%",
      maxWidth: "300px",
      margin: "0 auto",
    },
    popupButton: {
      backgroundColor: "#00a8ff", // Same button color
      color: "#fff",
      marginTop: "20px",
    },
  });

  useEffect(() => {
    const handlePopState = () => {
      if (pathname === '/') {
        window.scrollTo(0, 0); // Scroll to top when going back to home
      }
    };

    window.addEventListener('popstate', handlePopState);

    // Cleanup listener on unmount
    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, [pathname]);

  useEffect(() => {
    if (pathname === '/') {
      window.scrollTo(0, 0); // Ensure scroll on direct navigation
    }
  }, [pathname]);


  const [openCollection, setOpenCollection] = useState(false);
  const classes = useStyles();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleCloseCollection = () => {
    setOpenCollection(false);
  };

  const handleGetCollections = async (id) => {
    setLoading(true);
    let params = {
      id: id,
      page: 1,
      limit: 8
    };
    try {
      const { data, responseCode, message } =
        await OrderServices.getCollectionList(id ? params : "");
      console.log(data.orders);
      setCollections(data.collections);
      setLoading(false);
    } catch (error) {
      console.error("Error while fetching users:", error);
    }
  };

  const getCategories = async () => {
    setLoading(true);
    try {
      const { data, responseCode, message } =
        await OrderServices.getCollectionCategories();
      console.log(data.orders);
      setCategories(data.categories);
      setLoading(false);
    } catch (error) {
      console.error("Error while fetching users:", error);
    }
  };

  const getPricing = async () => {
    try {
      let params = {};
      const { data } = await OrderServices.getPricing(params);
      console.log(data);
      setCharges(data?.pricing)

    } catch (error) {
      console.log(error);
    } finally {
    }
  };
  const pricing = [
    {
      id: 1,
      title: `AED ${charges?.standard_price}`,
      highlight: "Standard T-Shirts",
      description:
        "Single standard t-shirt of any size for a single image on one side.",
    },
    {
      id: 2,
      title: `AED ${charges?.standard_price + 15}`,
      highlight: "Double Side Print",
      description: "Double side print for Single standard t-shirt of any size.",
    },
    {
      id: 3,
      title: "AED 10/Image",
      highlight: "Additional Images",
      description: "Additional Images for Single standard t-shirt of any size.",
    },
    {
      id: 3,
      title: "AED 20",
      highlight: "Delivery Charges",
      description: "Delivery charges in any Emirate for maximum of 5 shirts.",
    },
  ];
  const onSubmit = (data) => {
    // Send the data to your backend here
    console.log(data);
    setSuccessMessage("Thank you for your message!");
    // Reset the form
    setTimeout(() => {
      setSuccessMessage(null);
    }, 3000);
  };
  useEffect(() => {
    handleGetCollections();
    getCategories();
  }, []);
  let sliderData = [
    { img: Images.collection1 },
    { img: Images.collection2 },
    { img: Images.collection3 },
    { img: Images.collection4 },
  ];

  const prompts = [
    "A 1920s art deco cityscape with geometric buildings and vintage cars",
    "A surreal painting of melting clocks inspired by Salvador Dalí",
    "A whimsical forest scene in the style of Studio Ghibli, with friendly forest spirits",
    "A gothic cathedral interior with dramatic lighting and detailed stained glass windows",
    "A minimalist black and white sketch of a bustling urban street",
    "A watercolor landscape of a peaceful countryside with rolling hills and a small cottage",
    "A vibrant pop art portrait of a famous celebrity in the style of Andy Warhol",
    "An abstract expressionist painting with bold, chaotic brushstrokes and vibrant colors",
    "A vintage travel poster of a tropical paradise island",
    "A detailed pen and ink illustration of a fantasy castle on a mountain top",
    "A pixel art scene of a retro video game character on an adventure",
    "A classic Renaissance-style portrait of a royal figure in elaborate attire",
    "A futuristic sci-fi landscape with sleek skyscrapers and flying vehicles, inspired by concept art",
    "A dreamy, pastel-colored digital illustration of a girl reading a book under a cherry blossom tree",
    "A bold graffiti-style artwork of a street scene with colorful tags and murals",
    "A steampunk airship with intricate gears and steam-powered machinery",
    "A neon-lit cyberpunk alleyway with rain-soaked streets and holographic signs",
    "A magical underwater kingdom with mermaids and vibrant coral reefs",
    "A retro 80s-inspired grid landscape with glowing purple and blue lines",
    "A fantasy scene of a knight fighting a dragon in a moonlit forest",
    "A Japanese ink wash painting of a serene mountain landscape",
    "A 3D render of a futuristic robot in a sleek, high-tech environment",
    "A Baroque-style still life with fruit, flowers, and rich drapery",
    "A comic book panel of a superhero in action, with bold lines and dynamic poses",
    "A celestial map of a fictional star system with planets and constellations",
    "A modern art installation with geometric shapes and vibrant colors",
    "A classic Western scene with cowboys, horses, and a dusty frontier town",
    "A dreamy, surreal landscape with floating islands and cascading waterfalls",
    "A sci-fi cityscape with towering skyscrapers and advanced transportation systems",
    "A Renaissance-style fresco depicting mythological gods and heroes",

    "A hand-drawn map of a fantasy world with mountains, forests, and kingdoms",
    "A psychedelic poster with swirling patterns and vibrant, contrasting colors",
    "A cozy, detailed illustration of a quaint bookstore interior",
    "A futuristic vehicle design with sleek lines and advanced technology",
    "A dark fantasy scene with a haunted castle and eerie, misty surroundings",
    "A high-fashion runway show with models in avant-garde outfits",
    "A serene Zen garden with raked sand, rocks, and a small pond",
    "A retro-futuristic diner with chrome accents and neon signs",
    "A detailed botanical illustration of exotic plants and flowers",
    "A conceptual art piece with thought-provoking imagery and symbolism",
    "A post-apocalyptic landscape with crumbling buildings and overgrown vegetation",
    "A vibrant festival scene with dancers, musicians, and colorful decorations",
    "A photorealistic painting of a bustling city street at night",
    "A vintage comic strip featuring humorous characters and witty dialogue",
    "A serene autumn landscape with colorful foliage and a calm river",
    "A whimsical illustration of a fairy tale village with quirky houses",
    "A black and white photograph of a dramatic, stormy seascape",
    "A retro arcade scene with classic video game machines and pixel art characters",
    "A detailed, fantasy-inspired illustration of an elven city in the trees",
    "A colorful abstract painting with flowing shapes and vibrant hues",
    "A dramatic scene of a space battle with starships and laser beams",
    "A tranquil beach scene with soft waves, white sand, and palm trees",
    "A classic noir detective scene with shadowy figures and a smoky atmosphere",
    "A fantasy portrait of a sorceress with glowing magical runes",
    "A futuristic space station interior with advanced technology and sleek design",
    "A whimsical children's book illustration of animals having a tea party",
    "A detailed architectural drawing of a modern skyscraper",
    "A vibrant street art mural with a powerful social message",
    "A retro 70s-inspired disco scene with a glittering dance floor and groovy outfits",
    "A serene winter landscape with snow-covered trees and a cozy cabin",
    "A dynamic action scene with superheroes battling villains in a cityscape",
    "A minimalist landscape with bold, contrasting colors and simple shapes",
    "A vintage circus poster with performers, animals, and a big top tent",
    "A colorful mandala design with intricate patterns and symmetry",
    "A dreamy underwater scene with dolphins, fish, and a sunken ship",
    "A dramatic, high-contrast black and white portrait of an elderly person",
    "A fantasy scene of a wizard casting a powerful spell in a mystical forest",
    "A retro-futuristic cityscape with flying cars and neon signs",
    "A photorealistic painting of a majestic lion in the savannah",
    "A vibrant, abstract digital art piece with flowing lines and bright colors",
    "A classic film noir scene with a detective, a femme fatale, and a dark alley",
    "A whimsical illustration of a magical treehouse in an enchanted forest",
    "A futuristic sports car design with sleek, aerodynamic lines",
    "A tranquil sunset over a serene lake with a reflection of the sky",
    "A dynamic dance performance scene with fluid movements and vibrant costumes",
    "A dramatic space scene with planets, asteroids, and a distant galaxy",
    "A detailed architectural rendering of a futuristic building complex",
    "A colorful street festival with dancers, musicians, and food stalls",
    "A serene countryside scene with rolling hills, a winding road, and a farmhouse",
    "A whimsical underwater scene with mermaids, sea creatures, and a treasure chest",
    "A dramatic, high-contrast painting of a thunderstorm over the ocean",
    "A retro-inspired space scene with vintage-style spacecraft and planets",
    "A detailed fantasy map of an ancient kingdom with mountains, rivers, and forests",
    "A vibrant, abstract graffiti mural with bold colors and dynamic shapes",
    "A serene mountain landscape with a clear blue sky and a calm lake",
    "A whimsical illustration of a fairy village with tiny houses and magical creatures",
    "A dramatic cityscape at sunset with towering skyscrapers and a glowing sky",
    "A classic Western scene with cowboys, horses, and a dusty frontier town",
    "A dreamy, surreal landscape with floating islands and cascading waterfalls",
    "A sci-fi cityscape with towering skyscrapers and advanced transportation systems",
    "A Renaissance-style fresco depicting mythological gods and heroes",

    "A hand-drawn map of a fantasy world with mountains, forests, and kingdoms",
    "A psychedelic poster with swirling patterns and vibrant, contrasting colors",
    "A cozy, detailed illustration of a quaint bookstore interior",
    "A futuristic vehicle design with sleek lines and advanced technology",
    "A dark fantasy scene with a haunted castle and eerie, misty surroundings",
  ];
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,

    slidesToScroll: 1,
  };
  const settings2 = {
    dots: true,
    infinite: true,
    speed: 2000,
    slidesToShow: 4,
    vertical: true,
    verticalSwiping: true,
    swipeToSlide: true,
    autoplay: true, // Enable autoplay for continuous sliding
    autoplaySpeed: 1, // Set to a small positive value for a continuous effect
    cssEase: "linear",
    slidesToScroll: 1,
  };

  let randomTexts = [
    "Reticulating splines...",
    "Generating witty dialog...",
    "Swapping time and space...",
    "Spinning violently around the y-axis...",
    "Tokenizing real life...",
    "Bending the spoon...",
    "Filtering morale...",
    "Don't think of purple hippos...",
    "We need a new fuse...",
    "Have a good day.",
    "Upgrading Windows, your PC will restart several times. Sit back and relax.",
    "640K ought to be enough for anybody",
    "The architects are still drafting",
    "The bits are breeding",
    "We're building the buildings as fast as we can",
    "Would you prefer chicken, steak, or tofu?",
    "(Pay no attention to the man behind the curtain)",
    "...and enjoy the elevator music...",
    "Please wait while the little elves draw your map",
    "Don't worry - a few bits tried to escape, but we caught them",
    "Would you like fries with that?",
    "Checking the gravitational constant in your locale...",
    "Go ahead -- hold your breath!",
    "...at least you're not on hold...",
    "Hum something loud while others stare",
    "You're not in Kansas any more",
    "The server is powered by a lemon and two electrodes.",
    "Please wait while a larger software vendor in Seattle takes over the world",
    "We're testing your patience",
    "As if you had any other choice",
    "Follow the white rabbit",
    "Why don't you order a sandwich?",
    "While the satellite moves into position",
    "keep calm and npm install",
    "The bits are flowing slowly today",
    "Dig on the 'X' for buried treasure... ARRR!",
    "It's still faster than you could draw it",
    "The last time I tried this the monkey didn't survive. Let's hope it works better this time.",
    "I should have had a V8 this morning.",
    "My other loading screen is much faster.",
    "Testing on Timmy... We're going to need another Timmy.",
    "Reconfoobling energymotron...",
    "(Insert quarter)",
    "Are we there yet?",
    "Have you lost weight?",
    "Just count to 10",
    "Why so serious?",
    "It's not you. It's me.",
    "Counting backwards from Infinity",
    "Don't panic...",
    "Embiggening Prototypes",
    "Do not run! We are your friends!",
    "Do you come here often?",
    "Warning: Don't set yourself on fire.",
    "We're making you a cookie.",
    "Creating time-loop inversion field",
    "Spinning the wheel of fortune...",
    "Loading the enchanted bunny...",
    "Computing chance of success",
    "I'm sorry Dave, I can't do that.",
    "Looking for exact change",
    "All your web browser are belong to us",
    "All I really need is a kilobit.",
    "I feel like im supposed to be loading something. . .",
    "What do you call 8 Hobbits? A Hobbyte.",
    "Should have used a compiled language...",
    "Is this Windows?",
    "Adjusting flux capacitor...",
    "Please wait until the sloth starts moving.",
    "Don't break your screen yet!",
    "I swear it's almost done.",
    "Let's take a mindfulness minute...",
    "Unicorns are at the end of this road, I promise.",
    "Listening for the sound of one hand clapping...",
    "Keeping all the 1's and removing all the 0's...",
    "Putting the icing on the cake. The cake is not a lie...",
    "Cleaning off the cobwebs...",
    "Making sure all the i's have dots...",
    "We need more dilithium crystals",
    "Where did all the internets go",
    "Connecting Neurotoxin Storage Tank...",
    "Granting wishes...",
    "Time flies when you’re having fun.",
    "Get some coffee and come back in ten minutes..",
    "Spinning the hamster…",
    "99 bottles of beer on the wall..",
    "Stay awhile and listen..",
    "Be careful not to step in the git-gui",
    "You edhall not pass! yet..",
    "Load it and they will come",
    "Convincing AI not to turn evil..",
    "There is no spoon. Because we are not done loading it",
    "Your left thumb points to the right and your right thumb points to the left.",
    "How did you get here?",
    "Wait, do you smell something burning?",
    "Computing the secret to life, the universe, and everything.",
    "When nothing is going right, go left!!...",
    "I love my job only when I'm on vacation...",
    "i'm not lazy, I'm just relaxed!!",
    "Never steal. The government hates competition....",
    "Why are they called apartments if they are all stuck together?",
    "Life is Short – Talk Fast!!!!",
    "Optimism – is a lack of information.....",
    "Whenever I find the key to success, someone changes the lock.",
    "Sometimes I think war is God’s way of teaching us geography.",
    "I’ve got problem for your solution…..",
    "Where there’s a will, there’s a relative.",
    "User: the word computer professionals use when they mean !!idiot!!",
    "Adults are just kids with money.",
    "I think I am, therefore, I am. I think.",
    "You don’t pay taxes—they take taxes.",
    "Coffee, Chocolate, Men. The richer the better!",
    "I am free of all prejudices. I hate everyone equally.",
    "git happens",
    "May the forks be with you",
    "A commit a day keeps the mobs away",
    "This is not a joke, it's a commit.",
    "Constructing additional pylons...",
    "Roping some seaturtles...",
    "Locating Jebediah Kerman...",
    "We are not liable for any broken screens as a result of waiting.",
    "Hello IT, have you tried turning it off and on again?",
    "If you type Google into Google you can break the internet",
    "Well, this is embarrassing.",
    "What is the airspeed velocity of an unladen swallow?",
    "Hello, IT... Have you tried forcing an unexpected reboot?",
    "They just toss us away like yesterday's jam.",
    "They're fairly regular, the beatings, yes. I'd say we're on a bi-weekly beating.",
    "The Elders of the Internet would never stand for it.",
    "Space is invisible mind dust, and stars are but wishes.",
    "Didn't know paint dried so quickly.",
    "Everything sounds the same",
    "I'm going to walk the dog",
    "I didn't choose the engineering life. The engineering life chose me.",
    "Dividing by zero...",
    "Spawn more Overlord!",
    "If I’m not back in five minutes, just wait longer.",
    "Some days, you just can’t get rid of a bug!",
    "We’re going to need a bigger boat.",
    "Chuck Norris never git push. The repo pulls before.",
    "Web developers do it with <style>",
    "I need to git pull --my-life-together",
    "Java developers never RIP. They just get Garbage Collected.",
    "Cracking military-grade encryption...",
    "Simulating traveling salesman...",
    "Proving P=NP...",
    "Entangling superstrings...",
    "Twiddling thumbs...",
    "Searching for plot device...",
    "Trying to sort in O(n)...",
    "Laughing at your pictures-i mean, loading...",
    "Sending data to NS-i mean, our servers.",
    "Looking for sense of humour, please hold on.",
    "Please wait while the intern refills his coffee.",
    "A different error message? Finally, some progress!",
    "Hold on while we wrap up our git together...sorry",
    "Please hold on as we reheat our coffee",
    "Kindly hold on as we convert this bug to a feature...",
    "Kindly hold on as our intern quits vim...",
    "Winter is coming...",
    "Installing dependencies",
    "Switching to the latest JS framework...",
    "Distracted by cat gifs",
    "Finding someone to hold my beer",
    "BRB, working on my side project",
    "@todo Insert witty loading message",
    "Let's hope it's worth the wait",
    "Aw, snap! Not..",
    "Ordering 1s and 0s...",
    "Updating dependencies...",
    "Whatever you do, don't look behind you...",
    "Please wait... Consulting the manual...",
    "It is dark. You're likely to be eaten by a grue.",
    "Loading funny message...",
    "It's 10:00pm. Do you know where your children are?",
    "Waiting Daenerys say all her titles...",
    "Feel free to spin in your chair",
    "What the what?",
    "format C: ...",
    "Forget you saw that password I just typed into the IM ...",
    "What's under there?",
    "Your computer has a virus, its name is Windows!",
    "Go ahead, hold your breath and do an ironman plank till loading complete",
    "Bored of slow loading spinner, buy more RAM!",
    "Help, I'm trapped in a loader!",
    "What is the difference btwn a hippo and a zippo? One is really heavy, the other is a little lighter",
    "Please wait, while we purge the Decepticons for you. Yes, You can thanks us later!",
    "Chuck Norris once urinated in a semi truck's gas tank as a joke....that truck is now known as Optimus Prime.",
    "Chuck Norris doesn’t wear a watch. HE decides what time it is.",
    "Mining some bitcoins...",
    "Downloading more RAM..",
    "Updating to Windows Vista...",
    "Deleting System32 folder",
    "Hiding all ;'s in your code",
    "Alt-F4 speeds things up.",
    "Initializing the initializer...",
    "When was the last time you dusted around here?",
    "Optimizing the optimizer...",
    "Last call for the data bus! All aboard!",
    "Running swag sticker detection...",
    "Never let a computer know you're in a hurry.",
    "A computer will do what you tell it to do, but that may be much different from what you had in mind.",
    "Some things man was never meant to know. For everything else, there's Google.",
    "Unix is user-friendly. It's just very selective about who its friends are.",
    "Shovelling coal into the server",
    "Pushing pixels...",
    "How about this weather, eh?",
    "Building a wall...",
    "Everything in this universe is either a potato or not a potato",
    "The severity of your issue is always lower than you expected.",
    "Updating Updater...",
    "Downloading Downloader...",
    "Debugging Debugger...",
    "Reading Terms and Conditions for you.",
    "Digested cookies being baked again.",
    "Live long and prosper.",
    "There is no cow level, but there's a goat one!",
    "Running with scissors...",
    "Definitely not a virus...",
    "You may call me Steve.",
    "You seem like a nice person...",
    "Coffee at my place, tommorow at 10A.M. - don't be late!",
    "Work, work...",
    "Patience! This is difficult, you know...",
    "Discovering new ways of making you wait...",
    "Your time is very important to us. Please wait while we ignore you...",
    "Time flies like an arrow; fruit flies like a banana",
    "Two men walked into a bar; the third ducked...",
    "Sooooo... Have you seen my vacation photos yet?",
    "Sorry we are busy catching em' all, we're done soon",
    "TODO: Insert elevator music",
    "Still faster than Windows update",
    "Composer hack: Waiting for reqs to be fetched is less frustrating if you add -vvv to your command.",
    "Please wait while the minions do their work",
    "Grabbing extra minions",
    "Doing the heavy lifting",
    "We're working very Hard .... Really",
    "Waking up the minions",
    "You are number 2843684714 in the queue",
    "Please wait while we serve other customers...",
    "Our premium plan is faster",
    "Feeding unicorns...",
    "Rupturing the subspace barrier",
    "Creating an anti-time reaction",
    "Converging tachyon pulses",
    "Bypassing control of the matter-antimatter integrator",
    "Adjusting the dilithium crystal converter assembly",
    "Reversing the shield polarity",
    "Disrupting warp fields with an inverse graviton burst",
    "Up, Up, Down, Down, Left, Right, Left, Right, B, A.",
    "Do you like my loading animation? I made it myself",
    "Whoah, look at it go!",
    "No, I'm awake. I was just resting my eyes.",
    "One mississippi, two mississippi...",
    "Don't panic... AHHHHH!",
    "Ensuring Gnomes are still short.",
    "Baking ice cream...",
  ];

  const beforeImage = Images.Cartoon1;
  const afterImage = Images.Cartoon2;


  const handleMagicPromptChange = (newValue) => {
    if (newValue !== null) {
      setMagicPrompt(newValue);
      console.log(newValue);
    }
  };

  const handleVisibilityChange = (event, newValue) => {
    if (newValue !== null) {
      setVisibility(newValue);
    }
  };

  const handleClick = (item) => {
    setSelectedChips((prevSelected) => {
      if (selectedChips.find((chip) => chip?.id == item?.id)) {
        console.log(
          prevSelected.filter((i) => i?.id !== item.id),
          "prevSelected.filter((i) => i?.id !== item.id)"
        );

        // If the item is already selected, deselect it
        return prevSelected.filter((i) => i?.id !== item.id);
      } else {
        // Otherwise, add it to the selection
        return [...prevSelected, item];
      }
    });
  };

  const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 38, // Increased width
    height: 20, // Increased height
    padding: 0,
    display: "flex",

    "&:active": {
      "& .MuiSwitch-thumb": {
        width: 18, // Slightly larger thumb on active
      },
      "& .MuiSwitch-switchBase.Mui-checked": {
        transform: "translateX(11px)",
      },
    },
    "& .MuiSwitch-switchBase": {
      padding: 2, // Adjusted for larger size
      "&.Mui-checked": {
        transform: "translateX(18px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          opacity: 1,
          backgroundColor: "#1890ff",
          ...theme.applyStyles("dark", {
            backgroundColor: "#177ddc",
          }),
        },
      },
    },
    "& .MuiSwitch-thumb": {
      boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",

      width: 16, // Increased width
      height: 16, // Increased height
      borderRadius: 8,
      transition: theme.transitions.create(["width"], {
        duration: 200,
      }),
    },
    "& .MuiSwitch-track": {
      borderRadius: 20 / 2,
      opacity: 1,
      border: magicPrompt == "Yes" ? "" : "1px solid white",
      backgroundColor: "rgba(0,0,0,.25)",
      boxSizing: "border-box",
      ...theme.applyStyles("dark", {
        backgroundColor: "rgba(255,255,255,.35)",
      }),
    },
  }));
  const handleMouseDown = (e) => {
    setDragging(true);
    setStartX(e.pageX - inputRef.current.offsetLeft);
    setScrollLeft(inputRef.current.scrollLeft);
  };

  const handleMouseMove = (e) => {
    if (!dragging) return;
    e.preventDefault();
    const x = e.pageX - inputRef.current.offsetLeft;
    const walk = (x - startX) * 3; // scroll-fast
    inputRef.current.scrollLeft = scrollLeft - walk;
  };

  const handleMouseUp = () => {
    setDragging(false);
  };

  const handleTouchStart = (e) => {
    setDragging(true);
    setStartX(e.touches[0].pageX - inputRef.current.offsetLeft);
    setScrollLeft(inputRef.current.scrollLeft);
  };

  const handleTouchMove = (e) => {
    if (!dragging) return;
    e.preventDefault(); // Add this
    const x = e.touches[0].pageX - inputRef.current.offsetLeft;
    const walk = (x - startX) * 3; // scroll-fast
    inputRef.current.scrollLeft = scrollLeft - walk;
  };

  const handleTouchEnd = () => {
    setDragging(false);
  };

  const handlePointerDown = (e) => {
    setDragging(true);
    setStartX(e.clientX - inputRef.current.offsetLeft);
    setScrollLeft(inputRef.current.scrollLeft);
  };

  const handlePointerMove = (e) => {
    if (!dragging) return;
    const x = e.clientX - inputRef.current.offsetLeft;
    const walk = (x - startX) * 3; // scroll-fast
    inputRef.current.scrollLeft = scrollLeft - walk;
  };

  const handlePointerUp = () => {
    setDragging(false);
  };
  const suggestedPromptArray = [
    { id: 1, name: "Landscape" },
    { id: 2, name: "Abstract" },
    { id: 3, name: "Surreal" },
    { id: 4, name: "Futuristic" },
    { id: 5, name: "Fantasy" },
    { id: 6, name: "Minimalist" },
    { id: 7, name: "Cyberpunk" },
    { id: 8, name: "Vintage" },
    { id: 9, name: "Urban" },
    { id: 10, name: "Ethereal" },
  ];

  const opts = {
    width: "500px",
    height: "300px",

    // playerVars: {
    //   autoplay: 1,
    // },
  };
  const {
    register,
    handleSubmit,
    getValues,
    setValue,

    formState: { errors },
    reset,
  } = useForm();

  const {
    register: register2,
    handleSubmit: handleSubmit2,
    setValue: setValue2,
    formState: { errors: errors2 },
  } = useForm();

  const productBoxes = [
    {
      icon: <NoCharges />,
      title: "No Hidden Charges - Get the Best Deal",
      description:
        "Forget about extra charges. Our pricing is simple and transparent. With PrintMeUp, you don't need to drive to a printer or pay for an image generator tool. We offer a cost-effective solution for your custom t-shirts",
    },
    {
      icon: <Printing />,
      title: "High quality printing",
      description:
        "Experience the durability and longevity of DTF printing on premium t-shirts, ensuring top-notch quality every time.",
    },
    {
      icon: <Payment />,
      title: "Secure payment",
      description:
        "Shop with confidence at PrintMeUp. Our secure payment options ensure your personal information is protected, and we do not store any of your payment details.",
    },
    {
      icon: <CustomStyle />,
      title: "Custom Size & Style",
      description:
        "Find the perfect fit. Choose from a wide range of sizes and styles to create a t-shirt that suits your unique preferences.",
    },
    {
      icon: <Delivery />,
      title: "Fast Delivery",
      description:
        "Enjoy the convenience of fast delivery. Receive your custom t-shirts quickly, right at your doorstep.",
    },
    {
      icon: <Order />,
      title: "Low Minimum Order",
      description:
        "Order with ease at PrintMeUp. Our low minimum order quantities make it simple and affordable to express your unique style and feelings with customized t-shirts.",
    },
  ];

  const reviewBoxes = [
    {
      avatar: "",
      name: "Sajid Ahmed",
      designation: "",
      comments:
        "Print Me Up has revolutionised shirt printing in the UAE with their AI technology! The prints are vibrant, the fabric is top-notch, and their service is impeccable. Highly recommend!",
    },
    {
      avatar: "",
      name: "April Martin",
      designation: "",
      comments:
        "Print Me Up is my go-to for customized shirts in Dubai. Their AI image generation makes designing easy, and the quality is outstanding. I'm always impressed!",
    },
    {
      avatar: "",
      name: "Maaz Khan",
      designation: "",
      comments:
        "Living in Abu Dhabi, I've tried many shirt printing services, but Print Me Up is by far the best. Their AI-generated designs are stunning, and they deliver on time every time.",
    },
    {
      avatar: "",
      name: "A. Shah",
      designation: "",
      comments:
        "Print Me Up in Sharjah offers fantastic service and high-tech printing. Their AI technology ensures my designs are perfect, and the shirts are comfortable to wear.",
    },
    {
      avatar: "",
      name: "Sumita Aggarwal",
      designation: "",
      comments:
        "As a resident of Ras Al Khaimah, I trust Print Me Up for all my shirt printing needs. Their AI image generation creates unique designs, and the customer service is excellent",
    },
    {
      avatar: "",
      name: "Hamna Khan",
      designation: "",
      comments:
        "Print Me Up in Ajman has made custom shirt printing hassle-free. Their AI-driven process is smooth, and the results are always impressive. Love their work!",
    },
  ];

  const filename = "Generated-image ";

  const downloadImage = async (url, name) => {
    // Fetch the image data as a Blob
    const response = await fetch(url);
    const blob = await response.blob();

    // Create a temporary URL for the Blob
    const blobUrl = window.URL.createObjectURL(blob);

    // Create a temporary anchor element and trigger the download
    const link = document.createElement("a");
    link.href = blobUrl;
    link.download = name;
    document.body.appendChild(link);
    link.click();

    // Clean up
    document.body.removeChild(link);
    window.URL.revokeObjectURL(blobUrl);
  };
  const handleChange = (panel) => () => {
    setExpanded((prev) => ({ ...prev, [panel]: !prev[panel] }));
  };


  const downloadImageWithLogo = async (imageSrc, logoSrc, name) => {
    try {
      console.log("Loading Image:", imageSrc);
      console.log("Loading Logo:", logoSrc);

      // Helper function to load an image
      const loadImage = (src) => {
        return new Promise((resolve, reject) => {
          const img = new Image();
          img.crossOrigin = "Anonymous"; // Enable CORS
          img.onload = () => resolve(img);
          img.onerror = () => reject(new Error(`Failed to load image: ${src}`));
          img.src = src;
        });
      };

      // Load both images in parallel
      const [image, logo] = await Promise.all([
        loadImage(imageSrc),
        loadImage(logoSrc),
      ]);

      // Create a canvas and set dimensions
      const canvas = document.createElement("canvas");
      canvas.width = image.width;
      canvas.height = image.height;

      // Get the 2D context
      const ctx = canvas.getContext("2d");
      if (!ctx) throw new Error("Failed to get canvas context");

      // Draw the main image
      ctx.drawImage(image, 0, 0, canvas.width, canvas.height);

      // Define logo position and size
      const logoWidth = 200;
      const logoHeight = 70;
      const logoX = canvas.width - logoWidth - 20;
      const logoY = canvas.height - logoHeight - 20;
      const borderRadius = 12;

      // Draw a semi-transparent rectangle as background for the logo
      ctx.fillStyle = "rgba(0, 0, 0, 0.5)";
      ctx.beginPath();
      ctx.moveTo(logoX + borderRadius, logoY);
      ctx.lineTo(logoX + logoWidth - borderRadius, logoY);
      ctx.arcTo(
        logoX + logoWidth,
        logoY,
        logoX + logoWidth,
        logoY + borderRadius,
        borderRadius
      );
      ctx.lineTo(logoX + logoWidth, logoY + logoHeight - borderRadius);
      ctx.arcTo(
        logoX + logoWidth,
        logoY + logoHeight,
        logoX + logoWidth - borderRadius,
        logoY + logoHeight,
        borderRadius
      );
      ctx.lineTo(logoX + borderRadius, logoY + logoHeight);
      ctx.arcTo(
        logoX,
        logoY + logoHeight,
        logoX,
        logoY + logoHeight - borderRadius,
        borderRadius
      );
      ctx.lineTo(logoX, logoY + borderRadius);
      ctx.arcTo(logoX, logoY, logoX + borderRadius, logoY, borderRadius);
      ctx.closePath();
      ctx.fill();

      // Draw the logo on top
      ctx.drawImage(logo, logoX, logoY, logoWidth, logoHeight);

      // Convert the canvas to an image and trigger download
      if (canvas.toBlob) {
        canvas.toBlob((blob) => {
          if (!blob) {
            console.error("Blob generation failed");
            return;
          }
          console.log("Blob created:", blob);
          const blobUrl = URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = blobUrl;
          link.download = name;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          URL.revokeObjectURL(blobUrl);
        }, "image/png");
      } else {
        // Fallback for browsers that don't support `toBlob`
        const imgURL = canvas.toDataURL("image/png");
        const link = document.createElement("a");
        link.href = imgURL;
        link.download = name;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    } catch (error) {
      console.error("Error downloading the image with logo:", error);
    }
  };


  const HandlePrompt = () => {
    const randomIndex = Math.floor(Math.random() * prompts.length);
    setValue("prompt", prompts[randomIndex]);
    setRandomPrompt(prompts[randomIndex]);
  };
  const handleClose = () => {

    setOpen(false);
  };
  const handleState = () => {
    console.log(currentState);

    setCurrentState(currentState == 'login' ? 'register' : 'login')
  };

  const handleCloseDropdown = () => {
    setAnchorEl(null);
  };

  const addLibrary = async (url, popup) => {
    setDesignLoader(true)
    try {
      let obj = {
        prompt: getValues("prompt"),
        url: url,
        type: "upscale",
        item_id: allDetails?.id,
        index: 1,
        hash: allDetails?.hash,
        flags: allDetails?.flags,
      };
      const { data } = await OrderServices.addLibrary(obj);
      console.log(data, 'data');
      if (popup) {
        setDesignLoader(false)
        Swal.fire({
          title: "Added To Library",

          icon: "success",
          background: "#0f172a", // Change to your desired background color
          color: "#fff", // Change to your desired text color
        }).then((result) => {
          if (result.isConfirmed) {
            // window.location.href = '/my-account'; // Navigate to the home page
          }
        });
      }
      setLibraryUrl(data?.url)
      return data?.url




    } catch (error) {
      setErrorModal(true)
      setTestError(error)
      console.log(error);
    } finally {
    }
  };
  const removeBg = async (url) => {
    try {
      let obj = {
        imageUrl: url,
      };
      const { data } = await OrderServices.removeBg(obj);
      console.log(data?.image);

      setRemovedBgPic(data?.image);
    } catch (error) {
      console.log(error);
    } finally {
    }
  };
  const removeBgV2 = async (url) => {
    try {
      let obj = {
        imageUrl: url,
      };
      const { data } = await OrderServices.removeBgV2(obj);
      console.log(data?.image);

      setRemovedBgPic2(data?.image);
    } catch (error) {
      console.log(error);
    } finally {
    }
  };

  const handleScaling = async (index, confirmation) => {

    if (confirmation) {

      // window.location.href = "#img-section";
      setImagesGroup([])
      setUpScaleLoader(true);

      setTimeout(() => {
        const upscaleElement = upscale.current;
        if (upscaleElement) {
          const offset = 200; // Adjust the offset value to your needs
          const elementPosition = upscaleElement.getBoundingClientRect().top + window.pageYOffset;
          window.scrollTo({
            top: elementPosition - offset,
            behavior: "smooth",
          });
        }
      }, 100);

      setUpScaleText(false);
      setShowDowloadBtn(false);

      const eventSourceScaling = new EventSource(
        `https://generator.printmeup.ai/upscale?hash=${allDetails.hash}&msg_id=${allDetails.id}&index=${selectScaleIndex}&flag=${allDetails.flags}`
      );

      eventSourceScaling.onopen = () => {
        console.log("Connection to server opened.");
        setUpScalingLoader(true);
      };

      eventSourceScaling.onerror = (error) => {
        eventSourceScaling.close();
      };
      eventSourceScaling.onmessage = (event) => {
        let resultData = JSON.parse(event.data);
        console.log("Received message:", resultData);
        setUpScalingProgress(resultData.progress);
        if (resultData.msg === "Completed") {
          setResult(resultData.uri);
          if (magicPrompt == "Yes") {
            setComparison(true);
            handleConfetti();
            removeBg(resultData.uri);
          }

          setUpScaled(true);
          setGenerateBtn(true);
          setCustomizeBtn(true);
          setUpScalingLoader(false);
          setIndex(index);
          setImgMaping(false);
          setShowDowloadBtn(true);
          setUpScaleLoader(false);
        }
      };
    }
  };

  async function splitImage(imageUrl) {
    try {
      if (!imageUrl) throw new Error("Invalid image URL");

      console.log(`Image URL: ${imageUrl}`);

      const uniqueUrl = `${imageUrl}`;
      console.log(`Unique URL: ${uniqueUrl}`);

      const loadedImage = await new Promise((resolve, reject) => {
        const img = new Image();
        img.crossOrigin = "Anonymous"; // To avoid CORS issues if the image is hosted on a different domain
        img.src = uniqueUrl;

        img.onload = () => {
          console.log("Image loaded successfully");
          resolve(img);
        };
        img.onerror = (error) => {
          console.error(`Error loading image: ${error.message}`);
          reject(new Error(`Error loading image: ${error.message}`));
        };
      });
      console.log(loadedImage, "loadedImage");
      const width = loadedImage.width;
      const height = loadedImage.height;
      const halfWidth = width / 2;
      const halfHeight = height / 2;

      console.log(`Image dimensions: ${width}x${height}`);

      // Create 4 canvases
      const canvases = Array.from({ length: 4 }, (_, index) => {
        const canvas = document.createElement("canvas");
        canvas.width = halfWidth;
        canvas.height = halfHeight;
        console.log(`Created canvas ${index + 1}`);
        return canvas;
      });

      const contexts = canvases.map((canvas, index) => {
        const context = canvas.getContext("2d");
        console.log(`Obtained context for canvas ${index + 1}`);
        return context;
      });

      // Draw the four quadrants on the respective canvases
      contexts[0].drawImage(
        loadedImage,
        0,
        0,
        halfWidth,
        halfHeight,
        0,
        0,
        halfWidth,
        halfHeight
      );
      contexts[1].drawImage(
        loadedImage,
        halfWidth,
        0,
        halfWidth,
        halfHeight,
        0,
        0,
        halfWidth,
        halfHeight
      );
      contexts[2].drawImage(
        loadedImage,
        0,
        halfHeight,
        halfWidth,
        halfHeight,
        0,
        0,
        halfWidth,
        halfHeight
      );
      contexts[3].drawImage(
        loadedImage,
        halfWidth,
        halfHeight,
        halfWidth,
        halfHeight,
        0,
        0,
        halfWidth,
        halfHeight
      );

      console.log("Finished drawing on canvases");

      // Convert canvases to data URLs
      console.log(canvases, 'canvasescanvases');

      const imgSources = canvases.map((canvas, index) => {

        console.log(`Converting canvas ${index + 1} to data URL`);
        return canvas.toDataURL();
      });

      console.log("Image sources:", imgSources);
      const urlObjects = imgSources.map((url, index) => ({
        id: index + 1, // Generate a unique id
        url,
      }));

      setImagesGroup(urlObjects);
      setRandomMsgs(false)
      return imgSources;
    } catch (error) {
      console.error(`Error during image processing: ${error.message}`);
      throw new Error(`Error during image processing: ${error.message}`);
    }
  }

  const handleKeyDown = async (e) => {
    if (e.key == "Enter") {

      setProgress(0);
      setLinearProgress(0);
      setUpScaleLoader(false);
      setFinalLoader(false);
      setCustomizeBtn(false);

      setUpScaled(false);
      setUpScaleText(false);
      setResult("");

      let User = localStorage.getItem("user");
      User = JSON.parse(User);
      if (!upScaleLoader) {
        if (User) {

          let check = await CreditCheck()
          if (check) {
            handleSubmitData();
          }
          else {
            setCheckModal(true)
          }
        }
        else {
          setCurrentState('login')
          setOpen(true)
        }

      }
    }
  };

  const handleGoogleLogin = async () => {
    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;
      console.log("User Info: ", user);

      authLogin(user?.reloadUserInfo, result);
      // Handle user info here (e.g., save to state, context, or redirect)
    } catch (error) {
      console.error("Error during Google login: ", error);
    }
  };

  const authLogin = async (sendData, result) => {
    console.log(sendData, "data");
    try {
      let obj = {
        phone: "",
        google_id: sendData.localId,
        email: sendData.email,

        name: sendData.displayName,
      };
      const data = await AuthServices.authLogin(obj);
      console.log(data);
      if (data.responseCode == 200) {
        localStorage.setItem("authToken", JSON.stringify(data?.data?.token));
        localStorage.setItem("user", JSON.stringify(sendData));
        let userInfo = localStorage.getItem("user");
        setUserData(JSON.parse(userInfo));
        setUser(result.user);
        if (User) {

          let check = await CreditCheck()
          if (check) {
            handleSubmitData();
          }
          else {
            setCheckModal(true)
          }
        }
        else {

        }
      }
    } catch (error) {
      JSON.Stringyfy(error);
    } finally {
    }
  };
  const handleConfetti = async (e) => { };

  const [textPrompt, setTextPrompt] = useState(false)

  const handleSubmitData = async (e) => {
    setRandomMsgs(true)
    setIsLoadingResult(true);
    setAllDetails(false);
    setImagesGroup([{ id: 1 }, { id: 1 }, { id: 1 }, { id: 1 }])
    setTextPrompt(false)
    detail = null
    startTimer()
    setLibraryBtn(false)
    setRemovedBgPic("");
    setResult(null)
    setSelectedFinalImage(null)
    setRemovedBgPic2("");
    setResult("");
    setComparison(false);
    if (e && e.type === "keydown" && e.key === "Enter") {
      return;
    }

    let User = localStorage.getItem("user");
    User = JSON.parse(User);
    if (User) {
      localStorage.removeItem("gallery");

      // Check if the event is from a keyboard event and if the key is not 'Enter'

      setLinearProgress(0);
      setUpScaleLoader(false);
      setFinalLoader(false);
      setCustomizeBtn(false);
      setShowLinearProgress(true);
      setUpScaled(false);
      setUpScaleText(false);
      setResult("");


      localStorage.removeItem("designArray");

      setGenerateBtn(false);
      setGenerateText(true);

      setTimeout(() => {
        progressRef.current?.scrollIntoView({ behavior: "smooth" });
      }, 100);

      const totalDuration = 5000; // 10 seconds in milliseconds
      const intervalDuration = 100; // update every 100 milliseconds
      const increments = 100 / (totalDuration / intervalDuration); // amount to increment each time

      const timer = setInterval(() => {
        setLinearProgress((oldProgress) => {
          if (oldProgress >= 100) {
            clearInterval(timer); // clear the interval when progress reaches 100%
            setShowLinearProgress(false);
            return 100; // ensure progress stays at 100%
          }
          return Math.min(oldProgress + increments, 100);
        });
      }, intervalDuration);
      setFinalLoader(true);

      setImgMaping(true);
      let value = getValues("prompt");

      const joinedNames = selectedChips.map((item) => item.name).join(", ");
      console.log(joinedNames); // Output: "Futuristic, Fantasy"
      console.log(joinedNames, "updatedValueupdatedValue");

      setLoader(true);
      if (getValues("prompt")) {
        const eventSource = new EventSource(
          `https://generator.printmeup.ai/generate?prompt=${magicPrompt == "Yes"
            ? "vector svg, white background," + value + "," + joinedNames
            : value + "," + joinedNames
          }`
        );
        streamsRef.current.push(eventSource);
        setStreams([...streamsRef.current]);

        eventSource.onopen = () => {
          console.log("Connection to server opened.");

        };

        eventSource.onerror = (error) => {
          console.error("EventSource failed:", error);
          eventSource.close();
        };

        eventSource.onmessage = (event) => {
          let result = JSON.parse(event.data);

          console.log("Received message:", result);


          let url = result?.uri;

          if (url) {
            setLoader(false);
            // setOpen(true)

            console.log(url, "result");
            if (result.status === "completed") {
              detail = result

              console.log(detail, 'detail2');
              url += "&format=webp&quality=lossless&width=750&height=750";
              url = url.replace("cdn.discordapp.com", "media.discordapp.net");
            }
            setResult(url);

            splitImage(url).catch((error) => {
              console.error("Error splitting image:", error);
            });
          }
          // Assuming the server sends image URLs as plain text
          if (result.status === "completed") {
            setProgress(result.progress, toString());
            setShowDowloadBtn(true);

            eventSource.close(); // Close the connection
            setGenerateBtn(true);
            setErrorPopup(false)
            setAllDetails(result.details);
            console.log(result, 'result');



            setUpScaleText(true);
            setFinalLoader(false);
            setGenerateText(false);
          }
        };

        return () => {
          eventSource.close();
        };
      }

      setTimeout(function () {
        setShowLinearProgress(false);
      }, 10000);
    } else {
      setCurrentState("login");
      setOpen(true);
    }
  };



  const buyCredit = async () => {


    try {
      let obj = {



      }

      const { data } = await OrderServices.buyCredit(obj)
      console.log(data);
      if (data) {

        window.location.href = data?.url

      }

    } catch (error) {
      console.log(error);
    } finally {
      localStorage.removeItem('orderData')
    }
  }

  const CreditCheck = async () => {



    try {
      let obj = {

        prompt: getValues('prompt')

      }

      const { status } = await OrderServices.CreditCheck(obj)
      console.log(status, 'statusstatus');
      if (status) {
        return true
      }



    } catch (error) {
      return false

    } finally {

    }
  }
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (anchorEl && !anchorEl.contains(event.target)) {
        handleClose();
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => document.removeEventListener("click", handleClickOutside);
  }, [anchorEl]);
  // *For Debounce


  const intervalRef = useRef(null); // Ref to store interval ID
  const [randomIndex, setRandomIndex] = useState()
  let debounceTimer;
  const Debounce = (func, delay = 7000) => {
    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(func, delay);
  };

  const texts = () => {
    const randomIndexNum = Math.floor(Math.random() * randomTexts.length);
    setRandomIndex(randomIndexNum);
    console.log(randomTexts[randomIndexNum], 'randomTexts[randomIndex]');
    setCurrentMessage(randomTexts[randomIndexNum]);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      texts();
    }, 3000); // Run every 3 seconds

    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, []); // Empty dependency array ensures it runs only once on mount
  useEffect(() => {
    if (state) {
      console.log(state, "test");
      setValue("prompt", state);
    } else {
      setGenerateBtn(true);
      setFinalLoader(false);
      reset();
      setValue("prompt", "");
    }

    window.scrollTo(0, 0); // Scroll to top if current page is home

    getPricing()
  }, []);
  console.log(result, 'detail');

  useEffect(() => {
    let timerInterval;

    if (isRunning && !allDetails) {
      const storedStartTime = localStorage.getItem("timerStart");
      if (storedStartTime) {
        const updateTimer = () => {
          const elapsed = Math.floor((Date.now() - storedStartTime) / 1000);
          console.log(elapsed, 'elapsed');
          console.log(allDetails, 'elapsed'); // This will now reflect the updated value of result
          if (elapsed < 50) {
            setSeconds(elapsed);
          } else if (elapsed === 50 && !allDetails) {
            setErrorPopup(true);
            setSeconds(0);
            streamsRef.current.forEach((stream) => stream.close());
            streamsRef.current = [];
            setSeconds(50);
            setIsRunning(false);
            localStorage.removeItem("timerStart");
            setStreams([]);
            handleSubmitData();
          } else if (elapsed > 50) {
            setSeconds(50);
            setIsRunning(false);
            localStorage.removeItem("timerStart");
          }
        };

        updateTimer(); // Update immediately
        timerInterval = setInterval(updateTimer, 1000);
      }
    }

    return () => clearInterval(timerInterval); // Clean up interval on unmount
  }, [isRunning, allDetails]); // Add result to the dependency array


  useEffect(() => {
    const targetElement = document.getElementById("comaparisonDiv");
    console.log(targetElement);

    // Create a canvas element
    if (targetElement) {
      const canvas = document.createElement("canvas");
      canvas.style.position = "absolute";
      canvas.style.top = "0";
      canvas.style.left = "0";
      canvas.style.width = "100%";
      canvas.style.height = "100%";
      canvas.style.pointerEvents = "none"; // Ensures clicks go through the canvas
      targetElement.appendChild(canvas);

      const myConfetti = confetti.create(canvas, {
        resize: true, // Will fit the canvas to the element size
        useWorker: true,
      });

      const duration = 11 * 1000;
      const animationEnd = Date.now() + duration;
      let skew = 1;

      function randomInRange(min, max) {
        return Math.random() * (max - min) + min;
      }

      function frame() {
        const timeLeft = animationEnd - Date.now();
        const ticks = Math.max(200, 500 * (timeLeft / duration));

        skew = Math.max(0.8, skew - 0.001);

        myConfetti({
          particleCount: 1,
          startVelocity: 0,
          ticks: ticks,
          origin: {
            x: Math.random(),
            y: Math.random() * skew - 0.2,
          },

          shapes: ["star"],
          colors: ["FFE400", "FFBD00", "E89400", "FFCA6C", "FDFFB8"],
          gravity: randomInRange(0.4, 0.6),
          scalar: randomInRange(0.4, 1),
          drift: randomInRange(-0.4, 0.4),
          disableForReducedMotion: true,
        });

        if (timeLeft > 0) {
          requestAnimationFrame(frame);
        }
      }

      requestAnimationFrame(frame);

      // Cleanup canvas on component unmount
      return () => {
        targetElement.removeChild(canvas);
      };
    }
  }, [comparison]);

  const handleKeyDown2 = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
    }
  };

  const handleDemoModalClose = () => {
    setDemoModal(false)
  }
  const handleScroll = (direction) => {
    if (scrollContainerRef.current) {
      const scrollAmount = 200; // Adjust this value to control scroll distance
      scrollContainerRef.current.scrollBy({
        left: direction === "next" ? scrollAmount : -scrollAmount,
        behavior: "smooth",
      });
    }
  };

  const itemRefs = useRef([]);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        const index = itemRefs.current.indexOf(entry.target);
        if (entry.isIntersecting) {
          console.log(`Item ${index + 1} `);
          if (index + 1 > 18) {


          }
        }
      });
    }, {
      threshold: 0.5, // 50% of the item should be in view to trigger
    });

    itemRefs.current.forEach((item) => {
      observer.observe(item);
    });

    return () => {
      observer.disconnect(); // Cleanup the observer on component unmount
    };
  }, [sliderData2]); // Dependency on sliderData2 to update when it changes

  const handleModalClick = () => {

  }
  useEffect(() => {
    if (!sessionStorage.getItem('modal')) {
      setDemoModal(true)
      Debounce(() => handleModalClick());

      sessionStorage.setItem("modal", true);
    }


  }, [])
  const [modalWidth, setModalWidth] = useState('70%');

  const updateWidth = () => {
    if (window.innerWidth < 576) {
      setModalWidth('80%'); // Extra small devices
    } else if (window.innerWidth < 768) {
      setModalWidth('80%'); // Small devices
    } else if (window.innerWidth < 992) {
      setModalWidth('100%'); // Medium devices
    } else {
      setModalWidth('70%'); // Large devices
    }
  };

  useEffect(() => {
    updateWidth(); // Set initial width
    window.addEventListener('resize', updateWidth); // Update width on resize

    return () => {
      window.removeEventListener('resize', updateWidth); // Cleanup listener
    };
  }, []);

  return (
    <div style={{ backgroundColor: "transparent" }}>
      <div>
        {/* MUI Dialog for Popup */}
        <Dialog
          open={errorModal}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => setErrorModal(false)}
          aria-describedby="alert-dialog-slide-description"
          sx={{ ".MuiPaper-root": { backgroundColor: "#0f172a", borderRadius: '15px', maxWidth: { lg: '70% !important', md: "70% !important", sm: '90% !important', xs: '75% !important' } } }}

        >
          <Box
            sx={{
              backgroundColor: "#0f172a",
              fontFamily: "Plus Jakarta Sans",
              color: "white",
              borderRadius: '15px',
              display: 'flex',
              flexDirection: { lg: 'row', md: 'row', sm: "row", xs: 'column' }
            }}
          >
            {testError}


          </Box>
        </Dialog>
        {/* MUI Dialog for Popup */}
        <Modal
          visible={demoModal}
          onCancel={handleDemoModalClose}
          footer={null}
          width={modalWidth}
          centered
          bodyStyle={{ padding: 0, backgroundColor: "#0f172a", borderRadius: 15 }}

        >
          <Box
            sx={{
              backgroundColor: "#0f172a",
              fontFamily: "Plus Jakarta Sans",
              color: "white",
              borderRadius: '15px',
              display: 'flex',
              flexDirection: { lg: 'row', md: 'row', sm: "row", xs: 'column' }
            }}
          >
            <Box sx={{ width: { lg: '50%', md: '50%', sm: '50%', xs: '100%' }, position: 'relative' }} >
              {/* <Box component={'div'} onClick={handleDemoModalClose} onTouchStart={handleDemoModalClose} sx={{ position: 'absolute', right: 10, top: 10, display: { lg: 'none', md: 'none', sm: 'none', xs: 'block', zIndex: 99999 } }} ><IconButton sx={{ color: 'white' }} ><CloseIcon sx={{ cursor: 'pointer' }} color="white" /></IconButton></Box> */}
              <CardMedia component={'img'} src={'https://i.postimg.cc/0j5ygjHr/cair-03277-cool-cat-having-board-in-hand-with-written-Demo-Mode-c2f06e2b-42cc-4285-a59f-0934b555d0c3.png'} sx={{ width: '100%', height: '100%', borderTopLeftRadius: '12px', borderTopRightRadius: { lg: "0px", md: "0px", sm: "0px", xs: "12px" }, borderBottomLeftRadius: { lg: "12px", md: "12px", sm: "12px", xs: "0" } }} />

            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: { lg: '50%', md: '50%', sm: '50%', xs: '100%' }, position: 'relative' }} >
              {/* <Box component={'div'} onClick={handleDemoModalClose} onTouchStart={handleDemoModalClose} sx={{ position: 'absolute', right: 10, top: 10, display: { lg: 'block', md: 'block', sm: 'block', xs: 'none', zIndex: 99999 } }} ><IconButton sx={{ color: 'white' }} ><CloseIcon sx={{ cursor: 'pointer' }} color="white" /></IconButton></Box> */}
              <DialogContent sx={{ display: 'flex', flexDirection: "column", alignItems: 'center', justifyContent: 'center' }}>
                <Typography variant="body1" color="initial" className="heading-font2" sx={{ fontSize: { lg: '45px', md: '45px', sm: '28px', xs: '28px' }, color: 'white', fontWeight: 600 }}>Welcome</Typography>
                <DialogContentText id="alert-dialog-slide-description" sx={{ color: 'white', textAlign: 'center', mt: '16px' }}>
                  Print Me Up is now live in Demo Mode – Dive into a world of limitless creativity!

                  Explore, design, and bring your ideas to life. Save your creations now and be a part of something amazing as we gear up for our grand launch! 🚀✨
                </DialogContentText>
              </DialogContent>
            </Box>

          </Box>

        </Modal>
        {/* MUI Dialog for Popup */}
        <Dialog
          open={openCollection}
          onClose={handleCloseCollection}
          slotProps={{
            backdrop: {
              sx: {
                backdropFilter: "blur(8px)",
                backgroundColor: "transparent",
              },
            },
          }}
          PaperProps={{
            sx: {
              backgroundColor: "transparent",
              boxShadow: "none",
            },
          }}
        >
          <DialogContent className={classes.blurredBackground}>
            <Box
              sx={{
                backgroundColor: "transparent",
                padding: "20px",
                borderRadius: "8px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <img
                src={selectedImage} // Replace with actual image
                alt="Sports & Teams"
                width={"180px"}
                className={classes.popupImage}
              />
              {localStorage.getItem('user') && <Box mt={1}>
                <SecondaryButton
                  onClick={() => {
                    navigate(`/product-final`, { state: selectedImage });
                    localStorage.setItem(
                      "prompt",
                      JSON.stringify(getValues("prompt"))
                    );
                    localStorage.removeItem('saveDesign')
                    localStorage.setItem(
                      "gallery",
                      JSON.stringify([
                        {
                          imageUrl: selectedImage,
                          width: 100,
                          height: 100,
                          rotation: 0,
                          top: 20,
                          left: 20,
                          borderRadius: 10,
                        },
                      ])
                    );
                    localStorage.setItem(
                      "designArray",
                      JSON.stringify({
                        frontText: [],
                        backText: [],
                        frontPic: [
                          {
                            imageUrl: selectedImage,
                            width: 100,
                            height: 100,
                            rotation: 0,
                            top: 20,
                            left: 20,
                            borderRadius: 10,
                            clipPath:
                              "polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)",
                          },
                        ],
                        backPic: [],
                        color: "white",
                      })
                    );
                  }}
                  p={"10px 20px"}
                  fontWeight={"bold"}
                  icon={<EastIcon />}
                  color={"#0F172A"}
                  title={"Start My Design"}
                  sx={{ background: "linear-gradient(to right, #0EA5EA, #0CC7D7, #0BD1D1)", borderRadius: '15px', border: "none" }}

                />
              </Box>}
            </Box>
          </DialogContent>
        </Dialog>
        <Dialog
          open={checkModal}
          sx={{
            '& .MuiDialog-paper': {
              width: { lg: '50%', md: '70%', sm: '80%', xs: '80%' },
              height: "auto",
              borderRadius: 2,
              py: { xs: 2, md: 4 },
              px: { xs: 3, md: 6 },
              backgroundColor: '#0F172A'
            },

          }}
        >
          <IconButton onClick={() => setCheckModal(false)} sx={{ position: 'absolute', right: 13, top: 13 }}>
            <CancelOutlined sx={{ color: 'white' }} />
          </IconButton>
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            {<WarningIcon sx={{ color: red[500], fontSize: '50px !important' }} />}
          </Box>
          <Box sx={{ textAlign: 'center' }}>
            <Typography className='heading-font2' variant="h6" sx={{ color: 'white', mt: 1, mb: 1.5 }}>
              {/* Buy More Credits to continue creating amazing images. */}
              Your credits have been fully utilized, but don’t worry! In just 24 hours, you'll receive a fresh 20 credits to continue enjoying and creating stunning images.
            </Typography>

          </Box>

          <Box sx={{ mt: 1, display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 2 }}>
            <Box sx={{ display: 'flex', gap: 2 }}>
              {/* <Button
              title={'Buy Now'}
              onClick={() => buyCredit()}
              style={{ backgroundColor: '#0cbadd', color: "#0F172A", textTransform: 'capitalize' }}
            >
              {'Buy Now'}
            </Button> */}
              <Button
                title="Cancel"
                onClick={() => setCheckModal(false)}
                style={{ backgroundColor: '#0cbadd', color: "#0F172A", textTransform: 'capitalize' }}
              >
                Cancel
              </Button>
            </Box>
          </Box>
        </Dialog>
      </div>

      {/* conformation Dialog */}

      <SignInModal
        open={open}
        toggleState={handleState}
        handleClose={handleClose}
        handleSubmit={handleSubmit2}
        register={register}
        screen={currentState}
        errors={errors}
        handleGoogleLogin={handleGoogleLogin}
        Images={{
          mainlogo: "/path/to/mainlogo.png",
          googleIcon: "/path/to/googleIcon.png",
          whiteIcon: "/path/to/whiteIcon.png",
        }}
      />
      <Dialog
        open={errorPopup}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setErrorPopup(false)}
        aria-describedby="alert-dialog-slide-description"
        sx={{
          '& .MuiPaper-root': {
            backgroundColor: 'transparent !important'
          }
        }}
      >
        <Box
          sx={{
            backgroundColor: "#0f172a",
            fontFamily: "Plus Jakarta Sans",
            color: "white",
            position: 'relative',
            borderRadius: "8px",
            py: '64px'
          }}
        >
          <Box component={'div'} onClick={() => setErrorPopup(false)} onTouchStart={() => setErrorPopup(false)} sx={{ position: 'absolute', right: 10, top: 10, display: { lg: 'block', md: 'block', sm: 'block', xs: 'block', zIndex: 99999 } }} ><IconButton sx={{ color: 'white' }} ><CloseIcon sx={{ cursor: 'pointer' }} color="white" /></IconButton></Box>
          <DialogTitle sx={{ textAlign: "center", fontSize: { lg: "27px", md: "27px", sm: "20px", xs: '20px' }, mb: 1, p: "0px 24px 16px 24px !important" }}>
            {"Paws & Wait! We're Getting Things Done!"}
          </DialogTitle>
          <Box sx={{ textAlign: "center", mt: 1, fontSize: { lg: "17px", md: "17px", sm: "14px", xs: '14px' } }}>

            <p>{"Hang tight, we’ll have your request purrrrfectly processed soon!"}</p>
          </Box>
        </Box>
      </Dialog>

      <Dialog
        open={confirmDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setConfirmDialog(false)}
        aria-describedby="alert-dialog-slide-description"
      >
        <Box
          sx={{
            backgroundColor: "#0f172a",
            fontFamily: "Plus Jakarta Sans",
            color: "white",
          }}
        >
          <DialogTitle sx={{ textAlign: "center" }}>
            {"Let’s supercharge your amazing creation!"}
          </DialogTitle>
          {/* <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Let Google help apps determine location. This means sending anonymous
            location data to Google, even when no apps are running.
          </DialogContentText>
        </DialogContent> */}
          <DialogActions sx={{ display: "flex", justifyContent: "center" }}>
            <Button
              onClick={() => {
                setScalingConfirmation(true);
                setConfirmDialog(false);
                handleScaling(1, true);
              }}
            >
              Yes
            </Button>
            <Button
              onClick={() => {
                setScalingConfirmation(true);
                setConfirmDialog(false);
              }}
            >
              No
            </Button>
          </DialogActions>
        </Box>
      </Dialog>

      <Box
        sx={{
          backgroundImage: `url(${Images.HeroSectionImage})`,
          backgroundSize: "cover", // Adjust the image size
          backgroundPosition: "center", // Position the image
          backgroundRepeat: "no-repeat", // Avoid repeating the image
          //   height: { lg: "800px", md: "1200px" },
          position: "relative",
          overflow: "hidden",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: -150,
            right: 100,
            width: "220px",
            height: "220px",
            display: { lg: "block", md: "none", sm: "none", xs: "none" },
          }}
        >
          <div style={{ transform: "rotate(-30deg)", overflow: "hidden" }}>
            <div>
              {/* <Slider autoplay={true} {...settings2}>
                {sliderData2.map((item, ind) => (
                  <Box>
                    <CardMedia
                      component={"img"}
                      src={item.img}
                      sx={{
                        width: "100%",
                        objectFit: "contain",
                        marginTop: "50px",
                      }}
                    />
                  </Box>
                ))}
              </Slider> */}
              <div className="slider-container">
                <marquee direction="up" scrollamount="12" className="vertical-marquee">
                  {data?.map((item, ind) => (
                    <Box
                      key={ind}
                      ref={(el) => (itemRefs1.current[ind] = el)} // Store reference of each item
                      sx={{
                        width: "100%",
                        height: "auto",
                        marginTop: "50px",
                        backgroundColor: loaded[ind] === false ? "gray" : "transparent", // Gray background if image fails to load
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        position: "relative",
                      }}
                    >

                      {loaded[ind] === false &&
                        <Box
                          sx={{
                            backgroundColor: "#0f4363",
                            // borderRadius: "15px",
                            display: "flex",
                            padding: "25px",
                            justifyContent: "center",
                            alignItems: "center",
                            width: "210px",
                            height: "170px",
                          }}
                        >
                          <Box
                            component={"img"}
                            src={Images.mainlogo}
                            width={"210px"}
                            height={"210px"}
                            style={{
                              cursor: "pointer",
                              // borderRadius: "15px",
                            }}
                          />
                        </Box>
                      }
                      <CardMedia
                        component={"img"}
                        src={item.img}
                        onLoad={() => handleImageLoad2(ind)}
                        onError={() => handleImageError(ind)}
                        sx={{
                          width: "100%",
                          objectFit: "contain",
                          display: loaded[ind] === false ? "none" : "block",
                        }}
                      />
                    </Box>
                  ))}
                </marquee>
              </div>
            </div>
          </div>
        </Box>
        {/* Header */}

        <Grid
          id="home-section"
          container
          sx={{
            gap: 2,
            justifyContent: "space-between",

            margin: "80px 0px",
            p: { lg: '70px', md: "30px", sm: "30px", xs: "30px" },
            pt: "8%",
          }}
        >
          <Grid item lg={8.5} md={12} xs={12}>
            <Box sx={{ textAlign: "left", mt: "20px" }}>
              <Box
                component={"p"}
                className="heading-font"
                sx={{
                  color: `rgb(255 255 255) !important`,
                  WebkitTextFillColor: "rgb(255 255 255) !important",
                  fontSize: { lg: "50px", md: "50px", sm: "50px", xs: "24px" },
                  fontWeight: "600",
                  textAlign: {
                    lg: "left",
                    md: "left",
                    sm: "center",
                    xs: "center",
                  },
                }}
              >
                Being{" "}
                <Box
                  component={"span"}
                  className="banner-text"
                  sx={{
                    fontSize: {
                      lg: "50px",
                      md: "50px",
                      sm: "50px",
                      xs: "28px",
                    },
                    fontWeight: "bold",
                  }}
                >
                  {" "}
                  Unique
                </Box>{" "}
                is better
                <br />{" "}
                <Box
                  component={"span"}
                  sx={{
                    fontSize: {
                      lg: "50px",
                      md: "50px",
                      sm: "50px",
                      xs: "28px",
                    },
                    fontWeight: "bold",
                  }}
                >
                  than being{" "}
                  <Box
                    component={"span"}
                    className="banner-text"
                    sx={{
                      fontSize: {
                        lg: "50px",
                        md: "50px",
                        sm: "50px",
                        xs: "28px",
                      },
                      fontWeight: "bold",
                    }}
                  >
                    {" "}
                    Perfect
                  </Box>{" "}
                </Box>{" "}
                <br />
                {/* <Box
                  component={"p"}
                  sx={{
                    fontSize: {
                      lg: "60px",
                      md: "60px",
                      sm: "60px",
                      xs: "21px",
                    },
                    color: `rgb(255 255 255) !important`,
                    mt: {
                      lg: "0px",
                      md: "0px",
                      sm: "0px",
                      xs: "3px",
                    },
                  }}
                >
                  {" "}
                  With Our AI-Powered Designs{" "}
                </Box> */}
              </Box>
            </Box>
            <Grid
              container
              justifyContent={"center"}
              mt={5}
              sx={{ backgroundColor: "#0F172A", borderRadius: "30px", }}
            >
              <Grid
                component={"div"}
                item
                lg={12}
                md={12}
                xs={12}
                onClick={() => setPromptHeight(true)}
                sx={{
                  height: promptHeight ? "100%" : "50px",
                  border: "1px solid #0EA5EA33",
                  borderRadius: "30px",
                }}
              >
                <Grid
                  container
                  component={"form"}
                  onSubmit={handleSubmit(async (e) => {
                    if (User) {

                      let check = await CreditCheck()
                      if (check) {
                        handleSubmitData();
                      }
                      else {
                        setCheckModal(true)
                      }
                    }
                    else {
                      setCurrentState('login')
                      setOpen(true)
                    }
                  })}
                  onKeyDown={handleKeyDown2}
                >
                  <Grid item lg={7.5} md={12} sm={12} xs={12}>
                    <Box>
                      <Box
                        sx={{
                          mt: 2,
                          p: 2,
                          display: "flex !important",
                          alignItems: "flex-start !important",
                        }}
                        ref={inputRef}
                        component={"div"}
                        className="draggable-text-input"
                        onPointerDown={handlePointerDown}
                        onPointerMove={handlePointerMove}
                        onPointerUp={handlePointerUp}
                      >
                        <TextField
                          id="input-with-icon-textfield"
                          {...register("prompt", { required: true })}
                          onKeyDown={handleKeyDown}
                          placeholder="Transform Words Into Wearable Art"
                          sx={{
                            "& .MuiInputBase-input::placeholder": {
                              color: "red", // Replace with your desired color
                              opacity: 1, // Optional: ensures the color applies fully
                            },
                            "& .MuiInputBase-input": {
                              color: Colors.white,
                              textAlign: "left",
                              fontSize: {
                                lg: "20px",
                                md: "20px",
                                sm: "18px",
                                xs: "16px",
                              },
                              outline: "none !important",
                              border: "none !important",
                              width: {
                                lg: "100%",
                                md: "100%",
                                sm: "100%",
                                xs: "100%",
                              },
                              WebkitTextSizeAdjust: "100%", // Prevent zoom on iOS
                              "&::placeholder": {
                                color: Colors.white, // Set placeholder color to white
                              },
                            },
                            "& .MuiOutlinedInput-root": {
                              border: `3px solid #0EA5EA`,
                              borderRadius: "30px",
                              outline: "none !important",
                              border: "none !important",
                            },
                            "& fieldset": {
                              border: "none !important",
                            },
                          }}
                          size="small"
                          fullWidth
                          multiline
                          rows={promptHeight ? 7 : 0}
                        />
                      </Box>
                    </Box>
                    <Box className="example" sx={{
                      //  paddingTop: "20px", 
                      ml: 1,
                      paddingBottom: "17px"
                    }}>
                      {promptHeight &&
                        suggestedPromptArray.map((item) => (
                          <Chip
                            key={item.id}
                            label={item.name}
                            sx={{
                              backgroundColor: selectedChips.find(
                                (chip) => chip?.id === item?.id
                              )
                                ? "#0BD1D1"
                                : "transparent",
                              border: "1px solid rgba(11, 209, 209, 1)",
                              borderRadius: "6px",
                              color: selectedChips.find(
                                (chip) => chip?.id === item?.id
                              )
                                ? "#0F172A"
                                : "rgba(11, 209, 209, 1)",
                              fontFamily: "Poppins, sans-serif",
                              width: '100px',

                              m: 1,
                              mb: 0,

                              "&:hover": {
                                backgroundColor: selectedChips.find(
                                  (chip) => chip?.id === item?.id
                                )
                                  ? "#0BD1D1"
                                  : "transparent", // Change color on hover
                                // color: "#0F172A", // Optionally, you can change the text color as well
                              },
                            }}
                            onClick={() => handleClick(item)}
                          />
                        ))}
                    </Box>
                  </Grid>
                  <Grid
                    item
                    lg={4.5}
                    md={12}
                    display={"flex"}
                    flexDirection={"column"}
                    sx={{
                      borderLeft: promptHeight && {
                        lg: "1px solid #0EA5EA33", // Apply border at the 'lg' breakpoint and up
                      },
                    }}
                  >
                    {promptHeight && (
                      <Grid
                        container
                        spacing={2}
                        style={{
                          backgroundColor: "transparent",
                          padding: "20px",
                          borderRadius: "8px",
                        }}
                      >
                        <Grid
                          item
                          xs={12}
                          display={"flex"}
                          justifyContent={"space-between"}
                          alignItems={"center"}
                          gap={2}
                        >
                          <Typography
                            variant="h6"
                            style={{ color: "#ffffff", fontSize: "15px" }}
                          >
                            Spark Creativity
                          </Typography>
                          <Box component={"div"} onClick={() => HandlePrompt()}>
                            <IconButton>
                              <TipsAndUpdatesIcon
                                sx={{ color: Colors.white, cursor: "pointer" }}
                              />
                            </IconButton>
                          </Box>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center"
                          gap={2}
                          mt={1}
                        >
                          <Typography
                            variant="h6"
                            style={{ color: "#ffffff", fontSize: "15px" }}
                          >
                            Transparent Background
                          </Typography>
                          <AntSwitch
                            checked={magicPrompt === "Yes"}
                            onChange={(e) =>
                              handleMagicPromptChange(
                                e.target.checked ? "Yes" : "No"
                              )
                            }
                            color="primary"
                          />
                        </Grid>

                        <Grid
                          item
                          xs={12}
                          display={"flex"}
                          justifyContent={"space-between"}
                          alignItems={"center"}
                          gap={2}
                          mt={1}
                        >
                          <Typography
                            variant="h6"
                            style={{ color: "#ffffff", fontSize: "15px" }}
                          >
                            Visibility
                          </Typography>
                          <RadioGroup
                            row
                            value={visibility}
                            onChange={handleVisibilityChange}
                            aria-label="Visibility"
                            name="visibility"
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              gap: "8px",
                            }}
                          >
                            <FormControlLabel
                              value="Public"
                              control={
                                <Radio
                                  sx={{
                                    "&.Mui-checked": { color: Colors.white },
                                    color: "#ffffff",
                                    "&:hover": {
                                      backgroundColor:
                                        "rgba(14, 165, 234, 0.08)",
                                    },
                                    padding: "4px", // Reduced padding
                                    transform: "scale(0.9)", // Slightly smaller size
                                  }}
                                />
                              }
                              label={
                                <Typography
                                  sx={{
                                    textTransform: "capitalize",
                                    color: "white",
                                    fontSize: "0.75rem",
                                    padding: "0 6px",
                                  }}
                                >
                                  Public
                                </Typography>
                              }
                            />
                            <FormControlLabel
                              sx={{
                                // "&.css-j204z7-MuiFormControlLabel-root":{
                                marginRight: "0px !important",
                                // },
                              }}
                              value="Private"
                              control={
                                <Radio
                                  sx={{

                                    "&.Mui-checked": { color: Colors.white },
                                    color: "#ffffff",
                                    "&:hover": {
                                      backgroundColor:
                                        "rgba(14, 165, 234, 0.08)",
                                    },
                                    padding: "4px", // Reduced padding
                                    transform: "scale(0.9)", // Slightly smaller size
                                  }}
                                />
                              }
                              label={
                                <Typography
                                  sx={{
                                    textTransform: "capitalize",
                                    color: "white",
                                    fontSize: "0.75rem",
                                    padding: "0 6px",
                                  }}
                                >
                                  Private
                                </Typography>
                              }
                            />
                          </RadioGroup>
                        </Grid>
                      </Grid>
                    )}
                    {promptHeight && (
                      <Grid
                        container
                        component={"div"}
                        id="img-section"
                        xs={12}
                        display={"flex"}
                        justifyContent={"center"}
                        alignItems={"center"}
                        pb={1}
                        mt={1}
                      >
                        {generateBtn && (
                          <SecondaryButton
                            iconPosition={"start"}
                            className={"generate-btn generate-btn2"}
                            type={"submit"}
                            p={"10px 20px"}
                            fontWeight={"bold"}
                            icon={<AutoFixHighIcon />}
                            color={"#0F172A"}
                            title={"Generate "}
                            sx={{
                              borderRadius: "10px", background: "linear-gradient(to right, #0EA5EA, #0CC7D7, #0BD1D1) !important",
                            }}
                          />
                        )}
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>

      {
        showlinearProgress && (
          <Box ref={progressRef} sx={{ width: { lg: "50%", md: '50%', sm: '50%', xs: '70%' }, margin: "0 auto", mt: "2%" }}>
            <Box
              display={"flex"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              {progress != "100%" && generateText && (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <p
                    className="heading-font"
                    style={{ fontSize: "20px", color: "white", fontWeight: 500 }}
                  >
                    Generating{" "}
                  </p>{" "}
                  &nbsp;{" "}
                  <PulseLoader style={{ marginTop: "7%" }} color="#36d7b7" />
                </Box>
              )}
              <p
                className="heading-font"
                style={{ color: "white", textAlign: "right", mb: "5px" }}
              >
                {" "}
                {parseFloat(linearProgress).toFixed(0)}%
              </p>
            </Box>

            <LinearProgress
              variant="determinate"
              sx={{
                height: "15px",
                borderRadius: "20px",
                "& .MuiLinearProgress-root": {
                  backgroundColor: "lightgray", // Color of the track
                },
              }}
              value={linearProgress}
            />
          </Box>
        )
      }
      <Grid
        component={'div'}
        ref={upscale}
        container
        gap={2}
        justifyContent={"center"}
        sx={{ margin: "0 auto", mt: finalLoader || upScaleText || upScalingLoader || result ? "30px" : "0px" }}
      >
        <Grid container item xs={12} justifyContent={"center"}>
          {randomMsgs && (
            <>
              {" "}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  width: "100%",
                  margin: "0 auto",
                  marginTop: "2%",
                }}
              >
                {" "}
                <Box>
                  {" "}
                  <p
                    className="custom-small-text heading-font"
                    style={{
                      fontSize: "22px",
                      color: "white",
                      padding: "15px",
                      textAlign: "justify",
                    }}
                  >
                    {currentMessage}
                  </p>{" "}
                </Box>{" "}
                <br />
              </Box>
            </>
          )}
          <Box
            component={"div"}
            sx={{
              color: "white",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            {upScaleText && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",

                }}
              >
                <Typography
                  className="banner-text-small2 heading-font2"
                  style={{
                    fontSize: {
                      lg: "40px",
                      md: "40px",
                      sm: "40px",
                      xs: "24px",
                    },
                    marginBottom: "20px",
                    fontWeight: "bold",
                    textAlign: "center",
                  }}
                >
                  Select an image to reveal its full charm
                </Typography>{" "}
              </Box>
            )}
            {upScalingLoader && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  mt: "5%",

                  mb: "5%",
                }}
              >
                <p style={{ fontSize: "20px" }}>UpScaling </p>{" "}
                <PulseLoader style={{ marginTop: "4%" }} color="#36d7b7" />
              </Box>
            )}
            {!showlinearProgress ? (
              <Box sx={{ position: "relative" }}>
                {" "}
                <Grid
                  container
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    // padding: "50px",

                  }}

                  spacing={!upScaled ? 2 : 0}
                >
                  {!upScaled &&

                    imagesGroup.map((image, index) => (
                      <Grid
                        key={index} // Add a unique key for each mapped element
                        item
                        component={"div"}
                        display={"flex"}
                        justifyContent={"center"}
                        sx={{ position: "relative", marginBottom: "30px" }}
                        xs={8}
                        md={5}
                        lg={3}
                      // onContextMenu={(e) => e.preventDefault()} // Prevent the right-click context menu
                      >
                        <a style={{ position: "relative" }}>
                          {image?.url && allDetails && showDowloadBtn && (
                            <Box
                              onClick={
                                allDetails
                                  ? () =>
                                    downloadImageWithLogo(
                                      image.url,
                                      Images.mainlogo,
                                      filename
                                    )
                                  : undefined
                              }
                              sx={{
                                position: "absolute",
                                top: 25,
                                right: 10,
                                backgroundColor: "black",
                                opacity: 0.6,
                                borderRadius: "12px",
                                p: 0.5,
                              }}
                            >
                              <CloudDownloadOutlinedIcon
                                sx={{
                                  fontSize: "35px",
                                  color: "#f2f2f2",
                                  cursor: "pointer",
                                  fontWeight: "200",
                                }}
                              />
                            </Box>
                          )}
                          {image?.url && allDetails && showDowloadBtn && (
                            <Box
                              sx={{
                                position: "absolute",
                                bottom: 25,
                                right: 20,
                                backgroundColor: "black",
                                opacity: 0.6,
                                borderRadius: "10px",
                                p: 0.5,
                              }}
                            >
                              <Box
                                component={"img"}
                                sx={{ width: "100px" }}
                                src={Images.mainlogo}
                              />
                            </Box>
                          )}
                          {image?.url ? (
                            <Box
                              sx={{ textAlign: "center", cursor: "pointer", borderRadius: "12px" }}
                              onClick={
                                allDetails
                                  ? () => {
                                    setConfirmDialog(true);
                                    setSelectScaleIndex(index + 1);
                                  }
                                  : undefined
                              }
                              component={"img"}
                              width={{
                                lg: "300px",
                                md: "350px",
                                sm: "350px",
                                xs: "350px",
                              }}

                              height={{ lg: "300px", md: "350px", xs: "350px" }}
                              src={image?.url}
                            />
                          ) : (
                            <Box
                              sx={{
                                width: {
                                  lg: "300px",
                                  md: "350px",
                                  sm: "350px",
                                  xs: "350px",
                                },
                                height: { lg: "300px", md: "350px", xs: "350px" },
                                backgroundColor: grey[700],
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                borderRadius: "12px",
                              }}
                            >
                              <Box
                                component={"img"}
                                src={Images.loader}
                                width={"100px"}
                                height={"100px"}
                              />
                            </Box>
                          )}
                        </a>
                      </Grid>
                    ))}




                  {upScaleLoader ? (
                    <Box sx={{ mt: "180px", mb: "200px" }}>
                      {" "}
                      <CircularProgress />{" "}
                    </Box>
                  ) : (
                    upScaled && (
                      <Box sx={{ position: "relative" }}>

                        {!removedBgPic && (
                          <Box

                            sx={{
                              display: "flex !important",
                              justifyContent: "center",
                              alignItems: "center",
                              mt: "5%",
                              mb: "10%",
                            }}
                          >
                            <p
                              className="banner-text-small"
                              style={{
                                fontSize: "10px !important",
                                fontFamily: "Plus Jakarta Sans",
                                fontWeight: "bold",
                              }}
                            >
                              {comparison ||
                                (removedBgPic ||
                                  removedBgPic2)
                                ? "  AI is doing its Magic!"
                                : ""}{" "}
                            </p>{" "}
                          </Box>
                        )}

                        {comparison ? (
                          <>
                            {!selectedFinalImage ? <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                              }}
                            >
                              {(removedBgPic || removedBgPic2) && (
                                <ButtonGroup
                                  sx={{
                                    width: {
                                      lg: "350px",
                                      md: "350px",
                                      sm: "350px",
                                      xs: "300px",
                                    },

                                    mt: '30px'
                                  }}
                                  variant="contained"
                                  aria-label="Basic button group"
                                >
                                  <Button
                                    sx={{
                                      fontSize: "10px",
                                      backgroundColor: "#0EA5EA",
                                      color: "#0F172A",
                                      width: "50%",
                                      fontWeight: "bold",
                                    }}
                                    onClick={async () => {
                                      setSelectedFinalImage(result)
                                      // let url = await addLibrary(result);
                                      // localStorage.setItem(
                                      //   "prompt",
                                      //   JSON.stringify(getValues("prompt"))
                                      // );
                                      // navigate(`/product-final`, {
                                      //   state: url,
                                      // });
                                      // localStorage.removeItem('saveDesign')

                                    }}
                                  >
                                    Select with backdrop
                                  </Button>
                                  <Button
                                    sx={{
                                      fontSize: "10px",
                                      backgroundColor: "#0EA5EA",
                                      color: "#0F172A",
                                      width: "50%",
                                      fontWeight: "bold",
                                    }}
                                    onClick={async () => {
                                      setSelectedFinalImage(switchState ? removedBgPic2 : removedBgPic)
                                      // let url = await addLibrary(
                                      //   switchState ? removedBgPic2 : removedBgPic
                                      // );
                                      // localStorage.setItem(
                                      //   "prompt",
                                      //   JSON.stringify(getValues("prompt"))
                                      // );
                                      // navigate(`/product-final`, {
                                      //   state: url
                                      // });
                                      // localStorage.removeItem('saveDesign')

                                    }}
                                  >
                                    Select without backdrop
                                  </Button>
                                </ButtonGroup>
                              )}
                              <Box
                                component={"div"}
                                sx={{ mb: '30px' }}
                                id="comaparisonDiv"
                                width={{
                                  lg: "350px",
                                  md: "350px",
                                  sm: "350px",
                                  xs: "300px",
                                }}
                                height={{
                                  lg: "350px",
                                  md: "350px",
                                  sm: "350px",
                                  xs: "350px",
                                }}
                              >
                                <ReactCompareImage
                                  leftImage={result || Images.defaultPic}
                                  rightImage={
                                    switchState
                                      ? removedBgPic2
                                      : removedBgPic || Images.defaultPic
                                  }
                                  sliderLineColor="#0EA5EA"
                                  sliderLineWidth={4}
                                  handleSize={40}
                                />
                              </Box>
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "center",
                                  textAlign: "center",
                                }}
                              >
                                {!switchState &&
                                  (removedBgPic || removedBgPic2) && (
                                    <Box
                                      component={"div"}
                                      onClick={() => {
                                        setSwitchState(true);
                                        removeBgV2(result);
                                      }}
                                      sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        mt: 5,
                                        mb: "10%",
                                      }}
                                    >
                                      <p
                                        style={{
                                          fontSize: "10px !important",
                                          fontFamily: "Plus Jakarta Sans",
                                          fontWeight: "bold",
                                          cursor: "pointer",
                                          textDecoration: "underline",
                                        }}
                                      >
                                        Not Satisfied With Result? Try Alternative
                                        Version{" "}
                                      </p>{" "}
                                    </Box>
                                  )}
                                {switchState && (
                                  <Box
                                    component={"div"}
                                    onClick={() => {
                                      setSwitchState(false);
                                    }}
                                    sx={{
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      mt: 5,
                                      mb: "10%",
                                    }}
                                  >
                                    <p
                                      style={{
                                        fontSize: "10px !important",
                                        fontFamily: "Plus Jakarta Sans",
                                        fontWeight: "bold",
                                        cursor: "pointer",
                                        textDecoration: "underline",
                                      }}
                                    >
                                      Go Back To Previous One{" "}
                                    </p>{" "}
                                  </Box>
                                )}
                              </Box>
                            </Box> :
                              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                <Box
                                  className="image-selection"
                                  component={"img"}
                                  sx={{ borderRadius: "12px", mt: "20px" }}
                                  src={selectedFinalImage}
                                  width={{
                                    lg: "350px",
                                    md: "350px",
                                    sm: "350px",
                                    xs: "350px",
                                  }}
                                  height={{ lg: "350px", md: "350px", xs: "350px" }}
                                  onContextMenu={(e) => e.preventDefault()}
                                />


                                <Box mt={'30px'} sx={{ display: 'flex', gap: '20px', margin: '30px auto', justifyContent: 'space-evenly' }}>
                                  <SecondaryButton
                                    disabled={libraryBtn}
                                    onClick={() => {

                                      setLibraryBtn(true)
                                      addLibrary(selectedFinalImage, true);

                                    }}
                                    p={"10px 20px"}
                                    fontWeight={"bold"}
                                    icon={<EastIcon />}
                                    color={"#0F172A"}
                                    title={"Add To Library"}
                                    sx={{ background: "linear-gradient(to right, #0EA5EA, #0CC7D7, #0BD1D1)", borderRadius: '15px', border: "none", mb: 5 }}

                                  />
                                  <SecondaryButton
                                    onClick={async () => {
                                      let url
                                      if (libraryUrl) {
                                        url = libraryUrl
                                      }
                                      else {
                                        url = await addLibrary(selectedFinalImage);
                                      }
                                      if (url) {

                                        localStorage.setItem(
                                          "prompt",
                                          JSON.stringify(getValues("prompt"))
                                        );
                                        navigate(`/product-final`, { state: url });

                                        localStorage.removeItem('saveDesign')
                                        localStorage.setItem(
                                          "gallery",
                                          JSON.stringify([
                                            {
                                              imageUrl: url,
                                              width: 100,
                                              height: 100,
                                              rotation: 0,
                                              top: 20,
                                              left: 20,
                                              borderRadius: 10,
                                            },
                                          ])
                                        );
                                      }

                                    }}

                                    p={"10px 20px"}
                                    fontWeight={"bold"}
                                    icon={<EastIcon />}
                                    color={"#0F172A"}
                                    title={"Start My Design"}
                                    sx={{ background: "linear-gradient(to right, #0EA5EA, #0CC7D7, #0BD1D1)", borderRadius: '15px', border: "none", mb: 5 }}

                                  />
                                </Box>
                              </Box>
                            }
                          </>
                        ) : (

                          <Box
                            position="relative"
                            width={{
                              lg: "350px",
                              md: "350px",
                              sm: "350px",
                              xs: "350px",
                            }}
                            height={{ lg: "350px", md: "350px", xs: "350px" }}
                          >
                            {isLoadingResult && (
                              <Box
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                position="absolute"
                                top={0}
                                left={0}
                                width="100%"
                                height="100%"

                                sx={{ borderRadius: "12px", backgroundColor: grey[700] }}
                              >
                                <Box
                                  component={"img"}
                                  src={Images.loader}
                                  width={"100px"}
                                  height={"100px"}
                                />
                              </Box>
                            )}
                            <Box
                              className="image-selection"
                              component="img"
                              src={result}
                              sx={{
                                borderRadius: "12px",
                                display: isLoadingResult ? "none" : "block",
                              }}
                              width="100%"
                              height="100%"
                              onLoad={handleImageLoadFinalResult}
                              onContextMenu={(e) => e.preventDefault()}
                            />
                          </Box>
                        )}
                      </Box>
                    )
                  )}
                </Grid>
              </Box>
            ) : (
              ""
            )}

            {!removedBgPic && !comparison && customizeBtn && (
              <Box mt={'30px'} sx={{ display: 'flex', gap: '20px' }}>
                <SecondaryButton
                  disabled={libraryBtn}
                  onClick={() => {
                    setLibraryBtn(true)
                    addLibrary(result, true);

                  }}
                  p={"10px 20px"}
                  fontWeight={"bold"}
                  icon={<EastIcon />}
                  color={"#0F172A"}
                  title={"Add To Library"}
                  sx={{ background: "linear-gradient(to right, #0EA5EA, #0CC7D7, #0BD1D1)", borderRadius: '15px', border: "none", mb: 5 }}

                />
                <SecondaryButton
                  onClick={async () => {
                    let url
                    if (libraryUrl) {
                      url = libraryUrl
                    }
                    else {
                      url = await addLibrary(result);
                    }
                    if (url) {

                      localStorage.setItem(
                        "prompt",
                        JSON.stringify(getValues("prompt"))
                      );
                      navigate(`/product-final`, { state: url });

                      localStorage.removeItem('saveDesign')
                      localStorage.setItem(
                        "gallery",
                        JSON.stringify([
                          {
                            imageUrl: url,
                            width: 100,
                            height: 100,
                            rotation: 0,
                            top: 20,
                            left: 20,
                            borderRadius: 10,
                          },
                        ])
                      );
                    }
                  }}

                  p={"10px 20px"}
                  fontWeight={"bold"}
                  icon={<EastIcon />}
                  color={"#0F172A"}
                  title={"Start My Design"}
                  sx={{ background: "linear-gradient(to right, #0EA5EA, #0CC7D7, #0BD1D1)", borderRadius: '15px', border: "none", mb: 5 }}

                />
              </Box>
            )}
          </Box>
        </Grid>
      </Grid>
      <Box
        component={"section"}
        sx={{
          backgroundImage: `url(${Images.frameBg})`,
          backgroundSize: "cover", // Adjust the image size
          backgroundPosition: "center", // Position the image
          backgroundRepeat: "no-repeat", // Avoid repeating the image,
        }}
      >
        <Container>
          <Grid
            container
            justifyContent={"center"}
            spacing={{ md: 0, sm: 2, xs: 2 }}
            sx={{ py: 8 }}
          >
            <Grid
              item
              md={12}
              display={"flex"}
              justifyContent={"center"}

            >
              <Box
                component={"p"}
                className="heading-font"
                sx={{
                  color: Colors.white,
                  WebkitTextFillColor: "white !important",
                  fontSize: {
                    lg: "40px",
                    md: "40px",
                    sm: "40px",
                    xs: "24px",
                  },
                  fontWeight: "600",
                  textAlign: "center",
                }}
              >
                Unleash your  <Box
                  component={"span"}
                  className="banner-text"
                  sx={{
                    fontSize: {
                      lg: "40px",
                      md: "40px",
                      sm: "40px",
                      xs: "24px",
                    },
                    fontWeight: "bold",
                  }}
                > Creativity </Box>  with
                <br /> AI {" "}
                <Box
                  component={"span"}
                  className="banner-text"
                  sx={{
                    fontSize: {
                      lg: "40px",
                      md: "40px",
                      sm: "40px",
                      xs: "24px",
                    },
                    fontWeight: "bold",
                  }}
                >
                  Powered
                </Box> Designs
                <br />
              </Box>
            </Grid>
            <Grid item md={8} display={"flex"} justifyContent={"center"}>
              <Box
                component={"p"}
                sx={{
                  color: "white",
                  marginTop: "10px",
                  textAlign: "center",
                  //   lineHeight: "32px",
                  lineHeight: {
                    lg: "32px",
                    md: "32px",
                    sm: "32px",
                    xs: "22px",
                  },
                  fontSize: {
                    lg: "18px",
                    md: "18px",
                    sm: "18px",
                    xs: "14px",
                  },
                }}
              >
                Create your unique design with our AI-powered tool, select the perfect t-shirt color, and position your artwork exactly where you want it.
              </Box>
            </Grid>
            <Grid>
              <Box
                sx={{
                  padding: {
                    lg: "40px",
                    md: "40px",
                    sm: "40px",
                    xs: "40px 2px 0px 16px",
                  },
                  pb: "0px !important"
                }}
              >
                <Grid container spacing={4} justifyContent="center">
                  {items.map((item) => (
                    <Grid item xs={12} sm={12} md={6} lg={4} key={item.id}>
                      <Card
                        className="cardHover"
                        sx={{
                          backgroundColor: "#0F172A",
                          color: "#EDEDED",
                          padding: "20px",
                          height: "310px",
                          borderRadius: '15px',
                          //   mt:
                          //     item?.id == 1
                          //       ? ""
                          //       : item?.id == 2
                          //       ? "40px"
                          //       : "20px",
                          ":hover": {
                            color: "#0F172A !important",
                            backgroundColor: "#0BD1D1",
                            "& .heading-font": {
                              background: "none",
                              "-webkit-text-fill-color": "#0F172A",
                            },
                          },
                        }}
                      >
                        <CardContent>
                          <Box
                            display={"flex"}
                            justifyContent={"space-between"}
                          >
                            {/* <Box
                              component={"img"}
                              src={Images[`sticker${item?.id}`]}
                              width={"60px"}
                              height={"60px"}
                            ></Box> */}
                            {/* <Typography
                              variant="h4"
                              component="div"
                              color="#262b38"
                              gutterBottom
                              // className='heading-font'
                              sx={{
                                fontWeight: 500,
                                fontFamily: "Maven Pro, serif",
                                fontSize: "60px",
                              }}
                            >
                              0{item.id}
                            </Typography> */}
                          </Box>
                          <Typography
                            variant="h6"
                            component="div"
                            fontWeight="bold"

                            sx={{ fontSize: '20px' }}
                          >
                            {item.title} <br />
                            <Typography
                              component="span"
                              sx={{
                                display: "flex",
                                flexWrap: "wrap",
                                gap: "8px",
                              }}
                            >
                              {item.highlight.split(" ").map((word, index) => (
                                <Typography
                                  key={index}
                                  component="span"
                                  sx={{
                                    fontWeight: "bold",
                                    fontSize: "24px",
                                    WebkitBackgroundClip: "text",
                                    WebkitTextFillColor: "transparent",
                                    display: "inline",
                                    cursor: "pointer",
                                    "&:hover": {
                                      opacity: 0.8,
                                    },
                                  }}
                                  className="heading-font textHover"
                                >
                                  {word}
                                </Typography>
                              ))}
                            </Typography>
                          </Typography>
                          <Typography
                            variant="body2"
                            mt={2}
                            sx={{ lineHeight: "24px" }}
                          >
                            {item.description}
                          </Typography>
                        </CardContent>
                      </Card>
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Box component={"section"}>
        <Box
          component={"div"}
          id="how-section"
          sx={{ backgroundColor: "#101A33", color: "#fff" }}
        >
          <Container maxWidth="lg" sx={{ py: "64px  !important" }}>
            <Grid
              container
              justifyContent={"center"}
              spacing={{ md: 0, sm: 2, xs: 2 }}

            >
              <Grid
                item
                md={12}
                display={"flex"}
                justifyContent={"center"}

              >
                <Box
                  component={"p"}
                  className="heading-font"
                  sx={{
                    color: Colors.white,
                    WebkitTextFillColor: "white !important",
                    fontSize: {
                      lg: "40px",
                      md: "40px",
                      sm: "40px",
                      xs: "24px",
                    },
                    fontWeight: "600",
                    textAlign: "center",
                  }}
                >
                  How to   <Box
                    component={"span"}
                    className="banner-text"
                    sx={{
                      fontSize: {
                        lg: "40px",
                        md: "40px",
                        sm: "40px",
                        xs: "24px",
                      },
                      fontWeight: "bold",
                    }}
                  > Generate
                  </Box>
                  <br />
                  your
                  <Box
                    component={"span"}
                    className="banner-text"
                    sx={{
                      fontSize: {
                        lg: "40px",
                        md: "40px",
                        sm: "40px",
                        xs: "24px",
                      },
                      fontWeight: "bold",
                    }}
                  > Unique
                  </Box>  Design
                  <br />
                </Box>
              </Grid>
            </Grid>

            <Grid
              container
              spacing={2}
              sx={{
                marginTop: "25px",
                // marginLeft: "-21px !important",
              }}
            >
              <Grid item xs={12}
                sm={12} md={12} lg={7}>

                {/* //// */}
                <Tabs
                  value={value3}
                  onChange={handleChange2}
                  centered
                  textColor="inherit"
                  TabIndicatorProps={{
                    style: {

                      backgroundColor: "#0eaae7",
                      display: "flex",
                      justifyContent: "space-between",
                      marginTop: "20px",
                      height: "4px",
                      borderRadius: "15px",
                    },
                  }}
                  sx={{
                    display: { lg: 'flex', md: "flex", sm: "none", xs: "none" },
                    flexWrap: "wrap",
                    justifyContent: "space-between",
                    marginTop: "25px",
                    "& .MuiTabs-indicator": {
                      display: "none", // Hides the default tab indicator via MUI class override
                    },
                  }}
                >
                  <Tab
                    sx={{
                      textTransform: "capitalize",
                      color: value3 == 0 ? "#0EA5EA" : "",
                      borderBottom: value3 == 0 ? "3px solid #0EA5EA" : "",
                      fontWeight: 500,
                      fontFamily: "Maven Pro, serif",
                    }}
                    label="Write a Prompt"
                  />
                  <Tab
                    sx={{
                      textTransform: "capitalize",
                      color: value3 == 1 ? "#0EA5EA" : "",
                      borderBottom: value3 == 1 ? "3px solid #0EA5EA" : "",

                      fontWeight: 500,
                      fontFamily: "Maven Pro, serif",
                    }}
                    label="Select Your Design"
                  />
                  <Tab
                    sx={{
                      textTransform: "capitalize",
                      color: value3 == 2 ? "#0EA5EA" : "",
                      borderBottom: value3 == 2 ? "3px solid #0EA5EA" : "",

                      fontWeight: 500,
                      fontFamily: "Maven Pro, serif",
                    }}
                    label="Customize Your Design"
                  />
                  <Tab
                    sx={{
                      textTransform: "capitalize",
                      color: value3 == 3 ? "#0EA5EA" : "",
                      borderBottom: value3 == 3 ? "3px solid #0EA5EA" : "",

                      fontWeight: 500,
                      fontFamily: "Maven Pro, serif",
                    }}
                    label="Review, Confirm, Checkout"
                  />
                </Tabs>
                <Box
                  sx={{
                    mt: '-3.3px',
                    height: '2px',
                    backgroundColor: '#A0A0A0', // Light gray color for the line
                    width: '100%',
                    display: { lg: "flex", md: 'flex', sm: 'none', xs: "none" }
                  }}
                />
                <Box mt={5} sx={{ display: { lg: 'flex', md: "flex", sm: "none", xs: "none" }, }}>
                  {value3 === 0 && (
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={12}>
                        <Typography
                          variant="h6"
                          component="div"
                          mt={2}
                          sx={{
                            fontFamily: "Maven Pro, serif",
                            textAlign: {
                              lg: "left",
                              md: "left",
                              sm: "left",
                              xs: "center",
                            },
                            fontSize: "25px",
                          }}
                        >
                          Write a
                          <Typography
                            component="span"
                            color="#00D8FF"
                            fontWeight="bold"
                            sx={{
                              fontSize: "25px",

                              fontFamily: "Maven Pro, serif",
                            }}
                          >
                            {" "}
                            Prompt{" "}
                          </Typography>


                        </Typography>
                        <Typography
                          sx={{
                            mt: 1,
                            fontSize: {
                              lg: "1rem",
                              md: "1rem",
                              sm: "1rem",
                              xs: "14px",
                            },
                            textAlign: {
                              lg: "left",
                              md: "left",
                              sm: "left",
                              xs: "center",
                            }
                          }}
                        >
                          As simple as describing what you would like your design to
                          be about, any background you want to specify,
                          styles or colors to be focused on. It’s never perfect the
                          artist is still learning.

                        </Typography>
                      </Grid>

                    </Grid>
                  )}
                  {value3 === 1 && (
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={12}>
                        <Typography
                          variant="h6"
                          component="div"
                          mt={2}
                          sx={{
                            fontFamily: "Maven Pro, serif",
                            textAlign: {
                              lg: "left",
                              md: "left",
                              sm: "left",
                              xs: "center",
                            },
                            fontSize: "25px",
                          }}
                        >
                          Select your
                          <Typography
                            component="span"
                            color="#00D8FF"
                            fontWeight="bold"
                            sx={{

                              fontSize: "25px",
                              fontFamily: "Maven Pro, serif",
                            }}
                          > Design
                          </Typography>


                        </Typography>
                        <Typography
                          sx={{
                            mt: 1,
                            fontSize: {
                              lg: "1rem",
                              md: "1rem",
                              sm: "1rem",
                              xs: "12px",
                            },
                          }}
                        >
                          From the 4 images the AI tool generates select one that is closest to your unique design
                          and upscale it so we can enhance the image for clarity and color.
                        </Typography>
                      </Grid>

                    </Grid>
                  )}
                  {value3 === 2 && (
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={12}>
                        <Typography
                          variant="h6"
                          component="div"
                          mt={2}
                          sx={{
                            fontFamily: "Maven Pro, serif",
                            textAlign: {
                              lg: "left",
                              md: "left",
                              sm: "left",
                              xs: "center",
                            },
                            fontSize: "25px",
                          }}
                        >
                          <Typography
                            component="span"
                            color="#00D8FF"
                            fontWeight="bold"
                            sx={{

                              fontSize: "25px",
                              fontFamily: "Maven Pro, serif",
                              ml: { lg: 0, md: 0, sm: 0, xs: 1 }
                            }}
                          >
                            Customize
                          </Typography> your Design
                          <Box
                            component={"br"}
                            sx={{
                              display: {
                                lg: "block",
                                md: "block",
                                sm: "block",
                                xs: "none",
                              },
                            }}
                          />

                        </Typography>
                        <Typography
                          sx={{
                            mt: 1,
                            fontSize: {
                              lg: "1rem",
                              md: "1rem",
                              sm: "1rem",
                              xs: "14px",
                            },
                            textAlign: {
                              lg: "left",
                              md: "left",
                              sm: "left",
                              xs: "center",
                            }
                          }}
                        >
                          Save your design for later or start customizing your shirt. Use the image, adjust the
                          image size, placement and choose a shirt color. Add fonts in different styles on the front
                          or back.
                        </Typography>
                      </Grid>

                    </Grid>
                  )}
                  {value3 === 3 && (
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={12}>
                        <Typography
                          variant="h6"
                          component="div"
                          mt={2}
                          sx={{
                            fontFamily: "Maven Pro, serif",
                            textAlign: {
                              lg: "left",
                              md: "left",
                              sm: "left",
                              xs: "center",
                            },
                            fontSize: "25px",
                          }}
                        >
                          Review, Confirm,

                          <Typography
                            component="span"
                            color="#00D8FF"
                            fontWeight="bold"
                            sx={{
                              ml: '3px',
                              fontSize: "25px",
                              fontFamily: "Maven Pro, serif",
                            }}
                          >
                            Checkout
                          </Typography>


                        </Typography>
                        <Typography
                          sx={{
                            mt: 1,
                            fontSize: {
                              lg: "1rem",
                              md: "1rem",
                              sm: "1rem",
                              xs: "12px",
                            },
                          }}
                        >
                          Take another look at your creation, now save it to order later or go ahead and send it to
                          us to produce it for you to wear your own unique design.
                        </Typography>
                      </Grid>

                    </Grid>
                  )}

                  {/* Add content for other tabs as needed */}
                </Box>
                {/* //// */}
                <Grid container sx={{ display: { lg: 'none', md: "none", sm: "flex", xs: 'flex' } }}>


                  <Grid item lg={3} md={4} sm={4} xs={6}>
                    <Tab
                      sx={{
                        textTransform: "capitalize",
                        color: value3 === 0 ? "#0EA5EA" : "#6a7080",
                        borderBottom: value3 === 0 ? "3px solid #0EA5EA" : "",
                        fontWeight: 500,
                        fontFamily: "Maven Pro, serif",
                        width: "100%", // Ensure the tab spans the full width of the grid item
                        textAlign: "center",
                        opacity: 1,
                        fontSize: { md: "15px", sm: "15px", xs: "12px" },
                        padding: "12px 0px !important",
                      }}
                      label="Write a Prompt"
                      onClick={() => handleChange2(null, 0)} // Handle tab change
                    />
                  </Grid>

                  <Grid item lg={3} md={4} sm={4} xs={6}>
                    <Tab
                      sx={{
                        textTransform: "capitalize",
                        color: value3 === 1 ? "#0EA5EA" : "#6a7080",
                        borderBottom: value3 === 1 ? "3px solid #0EA5EA" : "",
                        fontWeight: 500,
                        fontFamily: "Maven Pro, serif",
                        width: "100%",
                        opacity: 1,
                        textAlign: "center",
                        fontSize: { md: "13px", sm: "13px", xs: "12px" },
                        padding: "12px 0px !important",
                      }}
                      label="Select Your Design"
                      onClick={() => handleChange2(null, 1)} // Handle tab change
                    />
                  </Grid>

                  <Grid item lg={3} md={4} sm={4} xs={6}>
                    <Tab
                      sx={{
                        textTransform: "capitalize",
                        color: value3 === 2 ? "#0EA5EA" : "#6a7080",
                        borderBottom: value3 === 2 ? "3px solid #0EA5EA" : "",
                        fontWeight: 500,
                        fontFamily: "Maven Pro, serif",
                        width: "100%",
                        textAlign: "center",
                        fontSize: { md: "13px", sm: "13px", xs: "12px" },
                        opacity: 1,
                        padding: "12px 0px !important",
                      }}
                      label="Customize Your Design"
                      onClick={() => handleChange2(null, 2)} // Handle tab change
                    />
                  </Grid>

                  <Grid item lg={3} md={4} sm={4} xs={6}>
                    <Tab
                      sx={{
                        textTransform: "capitalize",
                        color: value3 === 3 ? "#0EA5EA" : "#6a7080",
                        borderBottom: value3 === 3 ? "3px solid #0EA5EA" : "",
                        fontWeight: 500,
                        fontFamily: "Maven Pro, serif",
                        width: "100%",
                        textAlign: "center",
                        fontSize: { md: "13px", sm: "13px", xs: "12px" },
                        opacity: 1,
                        padding: "12px 0px !important",
                      }}
                      label="Review, Confirm, Checkout"
                      onClick={() => handleChange2(null, 3)} // Handle tab change
                    />
                  </Grid>

                  <Box
                    sx={{
                      mt: '-3.3px',
                      height: '2px',
                      backgroundColor: '#A0A0A0', // Light gray color for the line
                      width: '100%',
                      display: { lg: "flex", md: 'flex', sm: 'none', xs: "none" }
                    }}
                  />
                  <Grid item md={12} mt={5}>
                    {value3 === 0 && (
                      <Grid container spacing={2}>
                        <Grid item xs={12} md={12}>
                          <Typography
                            variant="h6"
                            component="div"
                            mt={2}
                            sx={{
                              fontFamily: "Maven Pro, serif",
                              textAlign: {
                                lg: "left",
                                md: "left",
                                sm: "left",
                                xs: "center",
                              },
                              fontSize: "25px",
                            }}
                          >
                            Write a
                            <Box

                              sx={{
                                display: {
                                  lg: "flex",
                                  md: "flex",
                                  sm: "flex",
                                  xs: "none",
                                },
                              }}
                            />
                            <Typography
                              component="span"
                              color="#00D8FF"
                              fontWeight="bold"
                              sx={{
                                fontSize: "25px",

                                fontFamily: "Maven Pro, serif",
                              }}
                            >
                              {" "}
                              Prompt{" "}
                            </Typography>
                          </Typography>
                          <Typography
                            sx={{
                              mt: 1,
                              fontSize: {
                                lg: "1rem",
                                md: "1rem",
                                sm: "1rem",
                                xs: "14px",
                              },
                              textAlign: {
                                lg: "left",
                                md: "left",
                                sm: "left",
                                xs: "center",
                              }
                            }}
                          >
                            As simple as describing what you would like your design to
                            be about, any background you want to specify,
                            styles or colors to be focused on. It’s never perfect the
                            artist is still learning.
                          </Typography>
                        </Grid>

                      </Grid>
                    )}
                    {value3 === 1 && (
                      <Grid container spacing={2}>
                        <Grid item xs={12} md={12}>
                          <Typography
                            variant="h6"
                            component="div"
                            mt={2}
                            sx={{
                              fontFamily: "Maven Pro, serif",
                              textAlign: {
                                lg: "left",
                                md: "left",
                                sm: "left",
                                xs: "center",
                              },
                              fontSize: "25px",
                            }}
                          >
                            Select your
                            <Box
                              component={"br"}
                              sx={{
                                display: {
                                  lg: "block",
                                  md: "block",
                                  sm: "block",
                                  xs: "none",
                                },
                              }}
                            />
                            <Typography
                              component="span"
                              color="#00D8FF"
                              fontWeight="bold"
                              sx={{

                                fontSize: "25px",
                                fontFamily: "Maven Pro, serif",
                                ml: { lg: 0, md: 0, sm: 0, xs: 1 }
                              }}
                            >
                              Design
                            </Typography>
                          </Typography>
                          <Typography
                            sx={{
                              mt: 1,
                              fontSize: {
                                lg: "1rem",
                                md: "1rem",
                                sm: "1rem",
                                xs: "14px",
                              },
                              textAlign: {
                                lg: "left",
                                md: "left",
                                sm: "left",
                                xs: "center",
                              }
                            }}
                          >
                            From the 4 images the AI tool generates select one that is closest to your unique design
                            and upscale it so we can enhance the image for clarity and color.
                          </Typography>
                        </Grid>

                      </Grid>
                    )}
                    {value3 === 2 && (
                      <Grid container spacing={2}>
                        <Grid item xs={12} md={12}>

                          <Typography
                            variant="h6"
                            component="div"
                            mt={2}
                            sx={{
                              fontFamily: "Maven Pro, serif",
                              textAlign: {
                                lg: "left",
                                md: "left",
                                sm: "left",
                                xs: "center",
                              },
                              fontSize: "25px",
                            }}
                          >
                            <Typography
                              component="span"
                              color="#00D8FF"
                              fontWeight="bold"
                              sx={{

                                fontSize: "25px",
                                fontFamily: "Maven Pro, serif",
                                ml: { lg: 0, md: 0, sm: 0, xs: 1 }
                              }}
                            >
                              Customize
                            </Typography> your Design
                            <Box
                              component={"br"}
                              sx={{
                                display: {
                                  lg: "block",
                                  md: "block",
                                  sm: "block",
                                  xs: "none",
                                },
                              }}
                            />

                          </Typography>
                          <Typography
                            sx={{
                              mt: 1,
                              fontSize: {
                                lg: "1rem",
                                md: "1rem",
                                sm: "1rem",
                                xs: "14px",
                              },
                              textAlign: {
                                lg: "left",
                                md: "left",
                                sm: "left",
                                xs: "center",
                              }
                            }}
                          >
                            Save your design for later or start customizing your shirt. Use the image, adjust the
                            image size, placement and choose a shirt color. Add fonts in different styles on the front
                            or back.
                          </Typography>
                        </Grid>

                      </Grid>
                    )}
                    {value3 === 3 && (
                      <Grid container spacing={2}>
                        <Grid item xs={12} md={12}>
                          <Typography
                            variant="h6"
                            component="div"
                            mt={2}
                            sx={{
                              fontFamily: "Maven Pro, serif",
                              textAlign: {
                                lg: "left",
                                md: "left",
                                sm: "left",
                                xs: "center",
                              },
                              fontSize: "25px",
                            }}
                          >
                            Review, Confirm,
                            <Box
                              component={"br"}
                              sx={{
                                display: {
                                  lg: "block",
                                  md: "block",
                                  sm: "block",
                                  xs: "none",
                                },
                              }}
                            />

                            <Typography
                              component="span"
                              color="#00D8FF"
                              fontWeight="bold"
                              sx={{

                                fontSize: "25px",
                                fontFamily: "Maven Pro, serif",
                                ml: { lg: 0, md: 0, sm: 0, xs: 1 }
                              }}
                            >
                              Checkout
                            </Typography>
                          </Typography>
                          <Typography
                            sx={{
                              mt: 1,
                              fontSize: {
                                lg: "1rem",
                                md: "1rem",
                                sm: "1rem",
                                xs: "14px",
                              },
                              textAlign: {
                                lg: "left",
                                md: "left",
                                sm: "left",
                                xs: "center",
                              }
                            }}
                          >
                            Take another look at your creation, now save it to order later or go ahead and send it to
                            us to produce it for you to wear your own unique design.
                          </Typography>
                        </Grid>

                      </Grid>
                    )}

                    {/* Add content for other tabs as needed */}
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={5}
                display={"flex"}
                justifyContent={"center"}
                sx={{ mt: 6, pl: "24px !important  " }}
              >
                <Box
                  sx={{
                    // p: "12px 30px",
                    // backgroundColor: "#6d7790",
                    borderRadius: '15px',
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <CardMedia
                    component={"img"}
                    image={'https://i.postimg.cc/zfshp33s/toutorial.jpg'}
                    alt="AI Design Example"
                    sx={{
                      width: { lg: "382px", md: "382px", sm: "382px", xs: "280px" },
                      borderRadius: "8px",
                      boxShadow:
                        "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                    }}
                  />
                </Box>
              </Grid>



            </Grid>

          </Container>
        </Box>
      </Box>
      <Box
        component={"section"}
        sx={{
          backgroundImage: `url(${Images.frameBg})`,
          backgroundSize: "cover", // Adjust the image size
          backgroundPosition: "center", // Position the image
          backgroundRepeat: "no-repeat", // Avoid repeating the image,
          //   pb: 5,


        }}
      >
        <Container
        >


          <Grid
            container
            justifyContent={"center"}
            spacing={{ md: 0, sm: 2, xs: 2 }}
            sx={{ mb: '64px' }}
          >
            <Grid
              item
              md={12}
              display={"flex"}
              justifyContent={"center"}
              mt={'64px'}
            >
              <Box
                component={"p"}
                className="heading-font"
                sx={{
                  color: Colors.white,
                  WebkitTextFillColor: "white",
                  fontSize: { lg: "40px", md: "40px", sm: "40px", xs: "24px" },

                  fontWeight: "600",
                  textAlign: "center",
                }}
              >
                Explore
                <br />{" "}
                <Box
                  component={"span"}
                  className="banner-text"
                  sx={{
                    fontSize: {
                      lg: "40px",
                      md: "40px",
                      sm: "40px",
                      xs: "24px",
                    },
                    fontWeight: "bold",
                  }}
                >
                  Collections
                </Box>{" "}
                <br />
              </Box>
            </Grid>
            <Grid item md={8} display={"flex"} justifyContent={"center"}>
              <p
                style={{
                  color: "white",
                  marginTop: "10px",
                  textAlign: "center",
                  lineHeight: {
                    lg: "32px",
                    md: "32px",
                    sm: "32px",
                    xs: "20px",
                  },
                  fontSize: { lg: "16px", md: "16px", sm: "16px", xs: "12px" },
                  fontFamily: "Maven Pro",
                }}
              >
                Behold the extraordinary creations from the synergy of our
                user’s ingenuity and the transformative power of AI, a testament
                to boundless innovation.
              </p>
            </Grid>

            {(categories.length > 0) ? <><Grid item xs={12} sx={{ position: "relative", mt: 4 }}>
              <Grid container justifyContent="center">
                <Grid
                  item
                  sx={{
                    display: "flex",
                    flexWrap: "nowrap",
                    // overflowX: "auto",
                    position: "relative",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  {/* Previous Button */}
                  <IconButton
                    onMouseEnter={() => setPrevHover(true)}
                    onMouseLeave={() => setPrevHover(false)}
                    sx={{
                      width: "30px",
                      height: "30px",
                      background: Colors.secondary1,
                      borderRadius: "50%",
                      display: { xs: "flex", sm: "flex" }, // Hide on small screens
                      position: "absolute",
                      top: "45%",
                      left: "10px",
                      transform: "translateY(-50%)",
                      zIndex: 1,
                      ":hover": {
                        border: `1px solid ${Colors.secondary1}`,
                      },
                    }}
                    onClick={() => handleScroll("prev")}
                  >
                    <PrevIcon prevHover={prevHover} />
                  </IconButton>

                  {/* Scrollable Container */}
                  <Box
                    className="scrollable_div"
                    ref={scrollContainerRef}
                    sx={{
                      display: "flex",

                      flexWrap: "nowrap",
                      overflowX: "auto",
                      margin: '0 auto',
                      gap: 2,
                      width: { lg: "82%", md: '82%', sm: '70%', xs: "70%" },
                      padding: { xs: "0 10px", sm: "0 50px" },
                    }}
                  >
                    <Chip
                      label="All"
                      onClick={() => {
                        setSelectedCategory(null);
                        handleGetCollections(null);
                      }}
                      variant={!selectedCategory ? "filled" : "outlined"}
                      sx={{
                        fontFamily: "Maven Pro",
                        fontSize: "16px",
                        padding: "10px 15px",
                        fontWeight: "700",
                        borderRadius: "10px",
                        borderColor: "#00D4FF",
                        color: !selectedCategory ? "#fff" : "#00D4FF",
                        background: !selectedCategory
                          ? Colors.contactGradient2
                          : "transparent",
                      }}
                    />
                    {categories.map((category) => (
                      <Chip
                        key={category._id}
                        label={category.name}
                        onClick={() => {
                          setSelectedCategory(category._id);
                          handleGetCollections(category._id);
                        }}
                        variant={
                          selectedCategory === category._id
                            ? "filled"
                            : "outlined"
                        }
                        sx={{
                          fontFamily: "Maven Pro",
                          fontSize: "16px",
                          padding: "10px 15px",
                          fontWeight: "bold",
                          borderRadius: "10px",
                          borderColor: "#00D4FF",
                          color:
                            selectedCategory === category._id
                              ? "#fff"
                              : "#00D4FF",
                          background:
                            selectedCategory === category._id
                              ? Colors.contactGradient2
                              : "transparent",
                        }}
                      />
                    ))}
                  </Box>

                  {/* Next Button */}
                  <IconButton
                    onMouseEnter={() => setNextHover(true)}
                    onMouseLeave={() => setNextHover(false)}
                    sx={{
                      width: "30px",
                      height: "30px",
                      background: Colors.secondary1,
                      borderRadius: "50%",
                      display: { xs: "flex", sm: "flex" }, // Hide on small screens
                      position: "absolute",
                      top: "45%",
                      right: "10px",
                      transform: "translateY(-50%)",
                      zIndex: 1,
                      ":hover": {
                        border: `1px solid ${Colors.secondary1}`,
                      },
                    }}
                    onClick={() => handleScroll("next")}
                  >
                    <NextIcon nextHover={nextHover} />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>

              <Grid item md={12} lg={12}>
                <Grid container>
                  <Grid item md={12} mt={6}>
                    <Grid
                      container
                      sx={{
                        justifyContent: { lg: "flex-start", md: "center", sm: "center", xs: "center" },
                      }}
                      spacing={"20px"}
                    >
                      {collections && collections.length > 0 ? (
                        collections.map((item, index) => (
                          <Grid item key={index} sx={{ width: "auto" }}>
                            <Box
                              component={'div'}
                              onClick={() => {

                                if (localStorage.getItem('user')) {

                                  setOpenCollection(true)
                                }
                                else {
                                  setCurrentState('login')
                                  setCurrentState('login')
                                  setCurrentState('login')
                                  setOpen(true)
                                }
                                setSelectedImage(item?.image);
                              }}
                              sx={{
                                backgroundColor: "#0f4363",
                                borderRadius: "15px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                width: "210px",
                                height: "210px", // Keep consistent dimensions for loader,
                                cursor: 'pointer'
                              }}
                            >
                              {!loadedImages[index] && (
                                <Box
                                  component={"img"}
                                  src={Images.loader}
                                  width={"100px"}
                                  height={"100px"}
                                />
                              )}

                              {item?.image && (
                                <Box
                                  component={"img"}
                                  className="image-selection"
                                  style={{
                                    cursor: "pointer",
                                    borderRadius: "15px",
                                    display: loadedImages[index] ? "block" : "none", // Hide until loaded
                                  }}
                                  onLoad={() => handleImageLoad(index)}
                                  src={item?.image}
                                  width={"210px"}
                                  height={"210px"}
                                  sx={{ objectFit: "contain" }}
                                />
                              )}
                            </Box>
                          </Grid>
                        ))
                      ) : (
                        <Grid item xs={12} display={"flex"} justifyContent={"center"} alignItems={"center"}>
                          <Box
                            sx={{
                              mt: 5,
                              fontSize: "18px",
                              color: "gray",
                              textAlign: "center",
                            }}
                          >
                            No Images Available
                          </Box>
                        </Grid>
                      )}

                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  {collections.length > 0 && <Button
                    onClick={() => navigate('/collections')}
                    variant="text"
                    className="heading-font"
                    color="primary"
                    sx={{
                      background: Colors.contactGradient2,
                      "-webkit-text-fill-color": "rgba(15, 23, 42, 1)",
                      color: "rgba(15, 23, 42, 1)", // New text color on hover
                      fontWeight: 600,
                      textTransform: "capitalize",
                      mt: 4.5,
                      p: "10px 40px",
                      fontSize: "17px",
                      borderRadius: "15px",
                      "&:hover": {
                        color: "#101A33",
                      },
                    }}
                  >
                    View More
                  </Button>}
                </Box>
              </Grid> </> : <Grid item xs={12} display={'flex'} justifyContent={'center'}><Box component={'img'} src={Images.loader} sx={{ mt: 5 }} width={'200px'} height={'200px'} /></Grid>}
          </Grid>


        </Container>
      </Box>
      <Box component={"section"}>
        <Box
          component={"div"}
          id="pricing-section"
          sx={{ backgroundColor: "#101A33", color: "#fff", py: 8 }}
        >
          <Container maxWidth="lg" >
            <Grid
              container
              justifyContent={"center"}
              spacing={{ md: 0, sm: 2, xs: 2 }}
            >
              <Grid
                item
                md={12}
                display={"flex"}
                justifyContent={"center"}
                sx={{ paddingTop: "0px !important" }}
              >


                <Box
                  component={"p"}
                  className="heading-font2"
                  sx={{

                    fontSize: {
                      lg: "40px",
                      md: "40px",
                      sm: "40px",
                      xs: "24px",
                    },
                    fontWeight: "bold",
                  }}
                >
                  Our <Box className="banner-text"
                    sx={{

                      fontSize: {
                        lg: "40px",
                        md: "40px",
                        sm: "40px",
                        xs: "24px",
                      },
                      fontWeight: "bold",
                    }} component={"span"}>Pricing</Box>  Plans
                </Box>

              </Grid>
            </Grid>

            <Box mt={5}>
              <Grid container spacing={2} justifyContent="center">
                {pricing.map((item) => (
                  <Grid item xs={12} sm={6} md={6} lg={3} key={item.id}>
                    <Card
                      className="cardHover"
                      sx={{
                        backgroundColor: "#0F172A",
                        color: "#EDEDED",
                        padding: "15px",
                        borderRadius: "15px",
                        height: "230px",

                        ":hover": {
                          color: "#0F172A !important",
                          backgroundColor: "#0BD1D1",
                          "& .heading-font": {
                            "-webkit-text-fill-color": Colors.backgroundColor1,
                          },
                        },
                      }}
                    >
                      <CardContent>
                        <Box display={"flex"} justifyContent={"space-between"}>
                          {/* <Box
                            component={"img"}
                            src={Images.sticker1}
                            width={"60px"}
                          ></Box> */}
                        </Box>
                        <Typography
                          variant="h6"
                          component="div"
                          fontWeight="bold"
                          mt={2}
                        >
                          <Typography
                            component="span"
                            sx={{
                              display: "flex",
                              flexWrap: "wrap",
                              gap: "8px",
                            }}
                          >
                            {item.highlight.split(" ").map((word, index) => (
                              <Typography
                                key={index}
                                component="span"
                                sx={{
                                  fontWeight: "bold",
                                  fontSize: "24px",
                                  WebkitBackgroundClip: "text",
                                  WebkitTextFillColor: "transparent",
                                  display: "inline",
                                  cursor: "pointer",
                                  "&:hover": {
                                    opacity: 0.8,
                                  },
                                }}
                                className="heading-font textHover"
                              >
                                {word}
                              </Typography>
                            ))}
                          </Typography>

                        </Typography>
                        <Typography variant="body2" mt={2} sx={{ fontSize: '13px' }}>
                          {item.description}
                        </Typography>
                        <Button
                          variant="text"
                          className="buttonHover"
                          color="primary"
                          sx={{
                            background: "linear-gradient(to right, #0EA5EA, #0CC7D7, #0BD1D1)",
                            color: "#0F172A",
                            fontWeight: 700,
                            fontFamily: "Maven Pro",
                            textTransform: "capitalize",
                            mt: 2,
                            borderRadius: "12px",
                            p: "5px 15px",
                            fontSize: "12px",
                          }}
                        >
                          {item?.title}
                        </Button>
                      </CardContent>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Container>
        </Box>
      </Box>

      <Box
        component={"section"}
        sx={{
          backgroundImage: `url(${Images.frameBg})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          py: 8,
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Grid
          container
          justifyContent={"center"}
          spacing={{ md: 0, sm: 2, xs: 2 }}
        >
          <Grid item md={12} display={"flex"} justifyContent={"center"} mb={1} >
            <Box
              component={"p"}
              className="heading-font"
              sx={{
                color: Colors.white,
                WebkitTextFillColor: "white",
                fontSize: { lg: "40px", md: "40px", sm: "40px", xs: "24px" },

                fontWeight: "600",
                textAlign: "center",
              }}
            >
              <span className="heading-font">Best  </span>  <Box
                component={"span"}
                className="heading-font2"
                sx={{
                  fontSize: { lg: "40px", md: "40px", sm: "40px", xs: "24px" },
                  fontWeight: "bold",
                  color: 'white'
                }}
              > Customization
              </Box>
              <span className="heading-font">   Solution</span>
              <br />
            </Box>
          </Grid>
          <Grid item md={8} display={"flex"} justifyContent={"center"}>
            <Box
              component={"p"}
              sx={{
                color: "white",
                marginTop: "10px",
                width: { lg: "100%", md: "100%", sm: "100%", xs: "84%" },
                textAlign: "center",
                lineHeight: { lg: "32px", md: "32px", sm: "32px", xs: "20px" },
                fontSize: { lg: "16px", md: "16px", sm: "16px", xs: "12px" },
              }}
            >
              Create your unique design with our AI-powered tool, select the
              perfect t-shirt color, and position your artwork exactly where you
              want it. Once your order is placed, it’s instantly sent to the
              printer for production, ensuring a seamless process from design to
              delivery of your custom-made t-shirt.
            </Box>
          </Grid>
          <Grid>
            <Box mt={5} sx={{ display: "flex", justifyContent: "center" }}>
              <Grid
                container
                xs={10}
                md={8}
                lg={8}
                spacing={3}
                justifyContent="center"
              >
                {customize.map((item, index) => (
                  <Grid item xs={12} sm={6} md={6} lg={4} key={item.id}>
                    <Card
                      sx={{
                        backgroundColor: "#0F172A",
                        color: "#EDEDED",
                        padding: "20px",
                        // height: index < 3  ? "300px" : "220px",
                        height: "270px",
                        transition: "all 1s ease",
                        backgroundImage: "none",
                        borderRadius: '15px',
                        // "&:hover": {
                        //   backgroundImage: `url(${
                        //     Images[`customizebg${item?.id}`]
                        //   })`, 
                        //   backgroundSize: "cover", 
                        //   backgroundPosition: "center",
                        // },
                      }}
                    >
                      <CardContent sx={{ padding: "0px !important" }}>
                        <Box display={"flex"} justifyContent={"space-between"}>
                          <Box
                            component={"img"}
                            src={Images[`customize${item?.id}`]}
                            width={"60px"}
                          ></Box>
                        </Box>
                        <Typography
                          variant="h6"
                          component="div"
                          fontWeight="bold"
                          mt={2}
                        >
                          <Typography
                            component="span"
                            sx={{
                              display: "flex",
                              flexWrap: "wrap",
                              gap: "8px",
                            }}
                          >
                            {item.highlight.split(" ").map((word, index) => (
                              <Typography
                                key={index}
                                component="span"
                                sx={{
                                  fontWeight: "bold",
                                  fontSize: "24px",
                                  WebkitBackgroundClip: "text",
                                  WebkitTextFillColor: "transparent",
                                  display: "inline",
                                  cursor: "pointer",
                                  "&:hover": {
                                    opacity: 0.8,
                                  },
                                }}
                                className="heading-font textHover"
                              >
                                {word}
                              </Typography>
                            ))}
                          </Typography>
                        </Typography>
                        <Typography variant="body2" mt={2} sx={{ fontSize: "14px" }}>
                          {item.description}
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box
        component={"section"}
        sx={{
          p: { md: "64px", sm: 0, xs: 0 },
          py: "64px !important",
          backgroundColor: "#101A33",
        }}
      >
        <Container>
          <Grid container justifyContent={"center"} rowGap={"20px"}>
            <Grid item md={12} display={"flex"} justifyContent={"center"} mb={2} >
              <Box
                component={"p"}
                className="heading-font"
                sx={{
                  color: Colors.white,
                  WebkitTextFillColor: "white",
                  fontSize: { lg: "40px", md: "40px", sm: "40px", xs: "24px" },

                  fontWeight: "600",
                  textAlign: "center",
                }}
              >
                Things
                <span className="heading-font"> Creative People  </span>  <Box
                  component={"span"}
                  className="heading-font2"
                  sx={{
                    fontSize: { lg: "40px", md: "40px", sm: "40px", xs: "24px" },
                    fontWeight: "bold",
                    color: 'white'
                  }}
                >
                </Box>
                Say
                <br />
              </Box>
            </Grid>

            <Grid item md={12} sm={12} xs={12} sx={{ mb: 2 }}>
              <Swiper
                spaceBetween={20}
                slidesPerView={3}
                className="my-swiper"
                breakpoints={{
                  320: {
                    slidesPerView: 1,
                  },
                  786: {
                    slidesPerView: 2,
                  },
                  1080: {
                    slidesPerView: 3,
                  },
                }}
                onSlideChange={() => console.log("slide change")}
                onSwiper={(swiper) => console.log(swiper)}
              >
                {reviewBoxes.map((item, ind) => (
                  <SwiperSlide key={ind}>
                    <Box
                      sx={{
                        p: 4,
                        borderRadius: "15px",
                        display: "flex",
                        flexDirection: "column",
                        gap: "20px",
                        backgroundColor: "#0F172A ",

                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: "12px",
                        }}
                      >
                        <Avatar
                          sx={{
                            width: 64,
                            height: 64,
                          }}
                          src={item.avatar}
                          alt={item?.name}
                        />
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <Typography
                            className="testimonials"
                            sx={{
                              fontFamily: "Plus Jakarta Sans",
                              display: "flex",
                              alignItems: "center",
                              gap: "8px",
                              fontWeight: 600,
                              color: "white",
                            }}
                          >
                            {item.name}
                            <Typography
                              className="testimonials"
                              variant="body2"
                              sx={{ fontWeight: 400, color: "white" }}
                            >
                              {item.designation}
                            </Typography>
                          </Typography>
                          <Box

                            sx={{
                              display: "flex",
                            }}
                          >
                            <Star
                              sx={{
                                width: "16px",
                                height: "16px",
                                color: "#FCB61A",
                              }}
                            />
                            <Star
                              sx={{
                                width: "16px",
                                height: "16px",
                                color: "#FCB61A",
                              }}
                            />
                            <Star
                              sx={{
                                width: "16px",
                                height: "16px",
                                color: "#FCB61A",
                              }}
                            />
                            <Star
                              sx={{
                                width: "16px",
                                height: "16px",
                                color: "#FCB61A",
                              }}
                            />
                          </Box>
                        </Box>
                      </Box>
                      <Typography
                        className="testimonials"
                        variant={"body2"}
                        sx={{
                          fontFamily: "Plus Jakarta Sans",
                          color: "white",
                        }}
                      >
                        {item.comments}
                      </Typography>
                    </Box>
                  </SwiperSlide>
                ))}
              </Swiper>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </div >
  );
};

export default Home;
