import {
  Avatar,
  Backdrop,
  Box,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Fade,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  Modal,
  Radio,
  RadioGroup,
  Select,
  Switch,
  ThemeProvider,
  colors,
  createTheme,
  styled,
  useMediaQuery,
} from "@mui/material";
import React, {
  Fragment,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import PersonIcon from "@mui/icons-material/Person";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import SettingsIcon from "@mui/icons-material/Settings";
import LogoutIcon from "@mui/icons-material/Logout";
import background from "../../../assests/images/mayaccountbg.png";
import EditIcon from "@mui/icons-material/Edit";
import { Images } from "../../../assests";
import {
  CssBaseline,
  Container,
  Paper,
  Typography,
  Stepper,
  Step,
  StepLabel,
  Button,
  TextField,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Moveable from "react-moveable";
import { ContextMenuTrigger } from "react-contextmenu";
import { PDFExport } from "@progress/kendo-react-pdf";
import domtoimage from "dom-to-image";
import { FaTshirt } from "react-icons/fa";
import CloseIcon from "@mui/icons-material/Close";
import { CheckCircle, Circle } from "@mui/icons-material";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
} from "@mui/material";
import Input from "@mui/material/Input";
import { Add, Remove } from "@mui/icons-material";
import { PrimaryButton, SecondaryButton } from "../../../components/Buttons";
import PropTypes from "prop-types";
import InputPhone from "../../../components/PhoneInput";
import { useForm } from "react-hook-form";
import { IMaskInput } from "react-imask";
import axios from "axios";
import { drawDOM, exportPDF } from "@progress/kendo-drawing";
import moment from "moment/moment";
import OrderServices from "../../../services/OrderServices";
import Swal from "sweetalert2";
import AuthServices from "../../../services/AuthServices";
import {
  auth,
  provider,
  signInWithPopup,
} from "../../../config/firebase.config"; // Adjust the import path according to your file structure
import { getAuth, GoogleAuthProvider, signOut } from "firebase/auth";
import html2canvas from "html2canvas";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { AuthContext } from "../../../Context/AuthContext";
import Colors from "../../../assests/style";
import { jsx } from "react/jsx-runtime";
import { CartContext } from "../../../Context/CartContext";
import { toPng } from "html-to-image";
import { domToPng } from "modern-screenshot";

const steps = ["Shipping address", "Payment details", "Review your order"];

const TextMaskCustom = React.forwardRef(function TextMaskCustom(props, ref) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask="0000-0000-0000-0000"
      definitions={{
        "#": /[1-9]/,
      }}
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
      overwrite
    />
  );
});

TextMaskCustom.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: "#65C466",
        opacity: 1,
        border: 0,
        ...theme.applyStyles("dark", {
          backgroundColor: "#2ECA45",
        }),
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color: theme.palette.grey[100],
      ...theme.applyStyles("dark", {
        color: theme.palette.grey[600],
      }),
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: 0.7,
      ...theme.applyStyles("dark", {
        opacity: 0.3,
      }),
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: "#E9E9EA",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
    ...theme.applyStyles("dark", {
      backgroundColor: "#39393D",
    }),
  },
}));

export default function Order() {
  const theme = createTheme({
    typography: {
      fontFamily: "Poppins, sans-serif", // You can change this to your preferred font
    },
    components: {
      MuiStepLabel: {
        styleOverrides: {
          label: {
            color: "rgba(255, 255, 255, 0.7)",
            fontFamily: "Poppins, sans-serif",
            "&.Mui-active": {
              color: "#ffffff",
            },
            "&.Mui-completed": {
              color: "#ffffff",
            },
          },
        },
      },
      MuiTextField: {
        styleOverrides: {
          root: {
            "& .MuiInputBase-root": {
              color: "white", // Text color,
              fontFamily: "Poppins, sans-serif",
            },
            "& .MuiInputLabel-root": {
              color: "white", // Label color
            },
            "& .MuiInput-underline:before": {
              borderBottomColor: "white", // Underline color when unfocused
            },
            "& .MuiInput-underline:after": {
              borderBottomColor: "white", // Underline color when focused
            },
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "white", // Border color when unfocused
              },
              "&:hover fieldset": {
                borderColor: "white", // Border color on hover
              },
              "&.Mui-focused fieldset": {
                borderColor: "white", // Border color when focused
              },
            },
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          input: {
            color: "white", // Text color for input
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          notchedOutline: {
            borderColor: "white", // Border color for input
          },
          root: {
            "&:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: "white",
            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: "white",
            },
          },
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            color: "white", // Text color for label
            borderColor: "white",
            "&.Mui-focused": {
              color: "white",
            },
          },
        },
      },
      MuiMenuItem: {
        styleOverrides: {
          root: {
            color: "black", // Text color for menu items
            backgroundColor: "white", // Background color for menu items
            "&.Mui-selected": {
              backgroundColor: "rgba(255, 255, 255, 0.2)", // Background color for selected item
            },
            "&:hover": {
              backgroundColor: "rgba(255, 255, 255, 0.1)", // Background color for hover
            },
          },
        },
      },
      MuiSvgIcon: {
        styleOverrides: {
          root: {
            color: "white", // Color for dropdown arrow
          },
        },
      },
    },
    palette: {
      primary: {
        main: "#1976d2",
      },
      background: {
        default: "#333333",
      },
    },
  });
  const isXs = useMediaQuery(theme.breakpoints.down("xs")); // Check for extra small screens
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm")); // Switch to fullscreen on small devices
  const {
    register: register1,
    handleSubmit: handleSubmit1,
    formState: { errors: errors1 },
    control: control1,
    watch,
  } = useForm();
  const {
    register: register2,
    handleSubmit: handleSubmit2,
    formState: { errors: errors2 },
    control: control2,
    reset,
    setValue,
    getValues,
  } = useForm({
    defaultValues: {
      ageGroup: "",
      size: "",
    },
  });

  const sizeData = [
    { size: 'S', width: 20.5, length: 26, sleeve: 7 },
    { size: 'M', width: 21.5, length: 27, sleeve: 7.5 },
    { size: 'L', width: 22, length: 28, sleeve: 8 },
    { size: 'XL', width: 24, length: 29, sleeve: 8.5 },
  ];

  const auth = getAuth();
  const { cartVisible, toggleCartVisibility } = useContext(CartContext);
  let User = localStorage.getItem("user");
  User = JSON.parse(User);
  const [selectedTab, setSelectedTab] = useState(0);
  const Tabs = [
    { id: 0, name: "My Account", icon: <PersonIcon /> },
    { id: 1, name: "My Library", icon: <SaveAltIcon /> },
    { id: 2, name: "Addresses", icon: <LocationOnOutlinedIcon /> },
    { id: 3, name: "Account Settings", icon: <SettingsIcon /> },
    { id: 4, name: "Log out", icon: <LogoutIcon /> },
  ];
  const [isLessThan1000px, setIsLessThan1000px] = useState(
    window.innerWidth < 1000
  );
  const { state } = useLocation();
  const { id } = useParams();
  console.log(id, 'id');

  const navigate = useNavigate();
  const contentRef = useRef(null);
  const parentRef = useRef(null);
  const [allData, setAllData] = useState(
    JSON.parse(localStorage.getItem("designArray"))
  );
  const { user, setUser } = useContext(AuthContext);
  const [screenshotUrl, setScreenshotUrl] = useState();
  const pdfExportComponentFront = useRef(null);
  const pdfExportComponentBack = useRef(null);
  const elementFront = useRef(null);
  const elementBack = useRef(null);
  const [frontSSUrl, setFrontSSUrl] = useState();
  const [backSSUrl, setbackSSUrl] = useState();
  console.log(state);
  const [isLessThan1200, setIsLessThan1200] = useState(
    window.innerWidth < 1000
  );
  const [redirectionState, setRedirectionState] = useState(false);

  const [activeStep, setActiveStep] = useState(0);
  const [rotateDirection, setRotateDirection] = useState(false);
  const [totalQuantity, setTotalQuantity] = useState(0);
  const [checkOut, setCheckOut] = useState(false);
  const [frontUrl, setFrontUrl] = useState();
  const [backUrl, setBackUrl] = useState();
  const [tshirtPrice, setTshirtPrice] = useState(0);
  const [maxOrder, setMaxOrder] = useState(0);
  const [deliveryCharges, setDeliveryCharges] = useState(0);
  // *For Stepper Forms Data
  const [step1FormData, setStep1FormData] = useState();
  const [step2FormData, setStep2FormData] = useState();
  const [orderSummary, setOrderSummary] = useState([]);
  const [userData, setUserData] = useState();
  const [emirates, setEmirates] = useState([]);
  const [screenShotLoader, setScreenShotLoader] = useState(false);
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [frontSnap, setFrontSnap] = useState(false);
  const [backSnap, setBackSnap] = useState(false);
  const [selectedBusinessRegion, setSelectedBusinessRegion] =
    useState("standard");
  const [shirtQuality, setShirtQuality] = useState({
    id: "standard",
    name: "Standard",
    price: 100,
  });
  const [printType, setPrintType] = useState(null);
  const [pricingDetails, setPricingDetails] = useState(null);

  const [checked, setChecked] = React.useState(false);

  const handleChangeTone = (event) => {
    console.log(event);
    if (frontSnap && backSnap) {
      setChecked(event);
    }
  };

  const link = document.getElementsByTagName("link");
  link.crossOrigin = "anonymous";

  const [selectedOption, setSelectedOption] = useState("Everyday Standard");

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const divRef = useRef(null);
  const divRefBack = useRef(null);

  const ImageScreenShot = async () => {
    const div = elementFront.current;

    if (div) {
      const rect = div.getBoundingClientRect();
      const width = rect.width;
      const height = rect.height;
      const scale = 4;

      // Set the div's styles to match its actual size
      div.style.width = `${500}px`;
      div.style.height = `${500}px`;
      div.style.padding = "0";
      div.style.border = "none";
      div.style.overflow = "hidden";
      div.style.backgroundColor = null; // or undefined

      try {
        // Capture the screenshot
        const dataUrl = await domToPng(div, {
          bgcolor: "transparent", // Set background to transparent
          width: 500,
          height: 600,
          x: 0, // Set x and y to 0 because we're already translating with transform
          y: 0,
        });

        console.log(dataUrl); // Log the data URL to the console

        const link = document.createElement("a");
        link.download = "yImage.png";
        link.href = dataUrl;
        // link.click()

        sendBlobPreview(
          dataUrl.split(";base64,")[1],
          moment().unix() + "_PreviewFront.png"
        );
      } catch (error) {
        console.error("Error capturing screenshot:", error);
      } finally {
        // Reset the div's styles
        div.style.position = "";
        div.style.top = "";
        div.style.left = "";
        div.style.transform = "";
      }
    }
  };

  const ImageScreenShotBack = async () => {
    const div = elementBack.current;

    if (div) {
      const rect = div.getBoundingClientRect();
      const width = rect.width;
      const height = rect.height;
      const scale = 4;

      // Set the div's styles to match its actual size
      div.style.width = `${500}px`;
      div.style.height = `${500}px`;
      div.style.padding = "0";
      div.style.border = "none";
      div.style.overflow = "hidden";
      div.style.backgroundColor = null; // or undefined

      try {
        // Capture the screenshot
        const dataUrl = await domToPng(div, {
          bgcolor: "red", // Set background to transparent
          width: 500,
          height: 600,
          x: 0, // Set x and y to 0 because we're already translating with transform
          y: 0,
        });

        console.log(dataUrl); // Log the data URL to the console

        const link = document.createElement("a");
        link.download = "yImageback.png";
        link.href = dataUrl;
        // link.click()

        sendBlobPreviewBack(
          dataUrl.split(";base64,")[1],
          moment().unix() + "_PreviewBack.png"
        );
      } catch (error) {
        console.error("Error capturing screenshot:", error);
      } finally {
        // Reset the div's styles
        div.style.position = "";
        div.style.top = "";
        div.style.left = "";
        div.style.transform = "";
      }
    }
  };

  const capture = async () => {
    const div = divRef.current;

    if (divRef.current) {
      await waitForImagesToLoad(div); // Ensure images are loaded
      const a4Width = 220; // mm
      const a4Height = 317; // mm
      const scale = 10; // adjust this value to change the image resolution

      // Set the div's styles to match the A4 size
      div.style.width = `${a4Width}mm`;
      div.style.height = `${a4Height}mm`;
      div.style.padding = "0";
      div.style.border = "none";
      div.style.overflow = "hidden";

      // Capture the screenshot
      domToPng(div, {
        bgcolor: "transparent",
        width: a4Width * scale,
        useCORS: true,
        height: a4Height * scale,
        style: {
          transform: `scale(${scale})`,
          transformOrigin: "top left",
        },
      }).then((dataUrl) => {
        const link = document.createElement("a");
        link.download = "front";
        link.href = dataUrl;
        // link.click()

        setFrontSSUrl({
          document: dataUrl.split(";base64,")[1],
          name: moment().unix() + "_Front.png",
        });
        setFrontSnap(true);
      });
    }
  };

  const captureBack = async () => {
    const div = divRefBack.current;

    if (divRefBack.current) {
      await waitForImagesToLoad(div); // Ensure images are loaded
      const a4Width = 220; // mm
      const a4Height = 317; // mm
      const scale = 10; // adjust this value to change the image resolution

      // Set the div's styles to match the A4 size
      div.style.width = `${a4Width}mm`;
      div.style.height = `${a4Height}mm`;
      div.style.padding = "0";
      div.style.border = "none";
      div.style.overflow = "hidden";

      // Capture the screenshot
      domToPng(div, {
        bgcolor: "transparent",
        width: a4Width * scale,
        height: a4Height * scale,
        useCORS: true,
        style: {
          transform: `scale(${scale})`,
          transformOrigin: "top left",
        },
      }).then((dataUrl) => {
        const link = document.createElement("a");
        link.download = "back.png";
        link.href = dataUrl;
        // link.click()
        setbackSSUrl({
          document: dataUrl.split(";base64,")[1],
          name: moment().unix() + "_Back.png",
        });
        setBackSnap(true);
      });
    }
  };

  const waitForImagesToLoad = () => {
    return new Promise((resolve) => {
      const imgElements = document.querySelectorAll("img"); // Update this selector as per your needs

      let loadedCount = 0;

      imgElements.forEach((img) => {
        if (img.complete) {
          loadedCount++;
        } else {
          img.onload = () => {
            loadedCount++;
            if (loadedCount === imgElements.length) {
              resolve();
            }
          };
        }
      });

      if (loadedCount === imgElements.length) {
        resolve();
      }
    });
  };

  const captureScreenshot = async () => {
    // Use in your component

    toggleCartVisibility();

    await waitForImagesToLoad();
    await capture();
    await captureBack();
    await ImageScreenShot();
    await ImageScreenShotBack();
  };

  const handleGoogleLogin = async () => {
    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;
      console.log("User Info: ", user);

      authLogin(user?.reloadUserInfo, result);
      // Handle user info here (e.g., save to state, context, or redirect)
    } catch (error) {
      console.error("Error during Google login: ", error);
    }
  };

  const [ageGroup, setAgeGroup] = React.useState("");
  const [size, setSize] = React.useState("");
  const [quantity, setQuantity] = React.useState("");

  const handleAgeGroupChange = (event) => {
    setAgeGroup(event.target.value);
  };

  const handleSizeChange = (event) => {
    setSize(event.target.value);
  };

  const handleQuantityChange2 = (event) => {
    setQuantity(event.target.value);
  };

  const authLogin = async (sendData, result) => {
    console.log(sendData, "data");
    try {
      let obj = {
        phone: "",
        google_id: sendData.localId,
        email: sendData.email,

        name: sendData.displayName,
      };
      const data = await AuthServices.authLogin(obj);
      console.log(data);
      if (data.responseCode == 200) {
        localStorage.setItem("authToken", JSON.stringify(data?.data?.token));
        localStorage.setItem("user", JSON.stringify(sendData));
        let userInfo = localStorage.getItem("user");
        setUserData(JSON.parse(userInfo));
        setUser(result.user);
      }
    } catch (error) {
      JSON.Stringyfy(error);
    } finally {
    }
  };
  const handleChange = (event) => {
    setSelectedOption(event.target.value);
    console.log(totalQuantity, "totalQuantity");
    console.log(maxOrder, "maxOrder");
    const deliveryChargesresult = calculateDeliveryCharges(
      totalQuantity,
      event.target.value
    );
    setDeliveryCharges(deliveryChargesresult);
    console.log(
      `The delivery charges for a quantity of ${totalQuantity} is ${deliveryCharges}`
    );
  };

  const names = [
    "Abu Dhabi",
    "Dubai",
    "Sharjah",
    "Ajman",
    "Umm Al Quwain",
    "Ras Al Khaimah",
    "Fujairah",
  ];
  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };
  const sizes = ["S", "M", "L", "XL"];
  const [quantities, setQuantities] = useState({
    S: 0,
    M: 0,
    L: 0,
    XL: 0,
  });

  const placeOrder = async () => {
    console.log(step1FormData, "data");
    console.log(quantities, "data");
    const designs = {
      S: "dotted",
      M: "striped",
      L: "plain",
      XL: "checked",
    };

    const details = Object.entries(quantities).map(([size, qty]) => ({
      size,
      qty,
      design_front: frontUrl,
      design_back: backUrl,
    }));
    console.log(details);
    try {
      let obj = {
        ...step1FormData,
        details: details,
        color: allData.color ? allData.color : "white",
        payment_amount: totalQuantity * tshirtPrice,
        screenshot_front: screenshotUrl,
        screenshot_back: screenshotUrl,
      };

      const { message } = await OrderServices.placeOrder(obj);
      console.log(message);
      if (message) {
        Swal.fire({
          title: message,

          icon: "success",
          background: "#0f172a", // Change to your desired background color
          color: "#fff", // Change to your desired text color
        }).then((result) => {
          if (result.isConfirmed) {
            // window.location.href = '/my-account'; // Navigate to the home page
          }
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
    }
  };
  const isDualSidedPrint = (data) => {
    const hasFrontContent = data.frontText.length > 0 || data.frontPic.length > 0;
    const hasBackContent = data.backText.length > 0 || data.backPic.length > 0;
    return hasFrontContent && hasBackContent;
  };

  const AddToCart = async (formData) => {
    let bothEnd = isDualSidedPrint(allData)

    console.log(allData, "formData");
    const finalPrice = (shirtQuality?.id === 'standard'
      ? pricingDetails?.standard_price
      : pricingDetails?.premium_price) + (bothEnd ? 15 : 0);

    try {
      let obj = {
        design_id: id,
        details: {
          size: formData?.size,
          size_type: formData?.ageGroup,
          shirt_type: printType?.id,
          shirt_color: allData.color,
          print_intensity: shirtQuality?.id,
          qty: formData?.quantity,
          price: finalPrice,
        },
      };

      const { message } = await OrderServices.AddToCart(obj);
      console.log(message);
      if (message) {

        reset();
        console.log(getValues('size'), 'sizeeeee');
        setSize(null)
        setAgeGroup(null)
        setValue("size", "");
        setValue("ageGroup", "");
        reset({ ageGroup: "", size: "" });

        if (!redirectionState) {
          Swal.fire({
            title: message,
            icon: "success",
            background: "#0f172a", // Background color
            color: "#fff",         // Text color
            showCancelButton: true,
            confirmButtonText: "Complete Order",
            cancelButtonText: "Add More Shirts",
            customClass: {
              confirmButton: 'swal-confirm-btn', // Add custom styles
              cancelButton: 'swal-confirm-btn'
            }
          }).then((result) => {
            if (result.isConfirmed) {
              navigate("/cart"); // Navigate on Complete Order
            }
            // Add More Shirts logic is handled by simply closing the Swal
          });
        } else {
          navigate("/cart");
        }
      }

    } catch (error) {
      console.log(error);
    } finally {
    }
  };
  const AddToCart2 = async (formData) => {
    console.log(getValues("size"), "formData22");
    console.log(getValues("ageGroup"), "formData22");
    console.log(getValues("quantity"), "formData22");
    if (getValues("size") && getValues("ageGroup") && getValues("quantity")) {
      try {
        let obj = {
          design_id: id,
          details: {
            size: getValues("size"),
            size_type: getValues("ageGroup"),
            shirt_type: printType?.id,
            shirt_color: allData.color,
            print_intensity: shirtQuality?.id,
            qty: getValues("quantity"),
            price: tshirtPrice,
          },
        };

        const { message } = await OrderServices.AddToCart(obj);
        if (message) {
          navigate("/cart");
        }
      } catch (error) {
        console.log(error);
      } finally {
      }
    } else {
      navigate("/cart");
    }
  };
  const getPricing = async () => {
    try {
      let params = {};
      const { data } = await OrderServices.getPricing(params);
      console.log(data);
      setEmirates(data?.pricing?.delivery_charges);
      setTshirtPrice(data?.pricing?.standard_price);
      setMaxOrder(data?.pricing?.max_per_order);
      setPricingDetails(data?.pricing);
      setPrintType({
        id: "fullTone",
        name: "FullTone",
        price: data?.pricing?.full_intensity,
      })
    } catch (error) {
      console.log(error);
    } finally {
    }
  };
  const handleQuantityChange = (size, event) => {
    const value = event.target.value;
    console.log(value, "asdasd");
    if (!isNaN(value) && value >= 0) {
      // Create a new variable to hold the updated quantities
      const updatedQuantities = {
        ...quantities,
        [size]: parseInt(value, 10),
      };

      // Set the state with the updated quantities
      setQuantities(updatedQuantities);

      // Prepare the order summary based on the updated quantities
      const details = Object.entries(updatedQuantities).map(([size, qty]) => ({
        size,
        qty,
        design: "abc",
      }));

      console.log(details, "detailsdetailsdetails");
      // Example usage:

      setOrderSummary(details);
    }
  };

  // const exportPDFWithMethodFront = () => {
  //     let gridElement = document.querySelector("#pdfFront");
  //     drawDOM(gridElement, {
  //         paperSize: "A4",
  //         scale: 2.7,
  //         fileName: "frontPrint",
  //     })
  //         .then((group) => {
  //             return exportPDF(group);
  //         })
  //         .then((dataUri) => {
  //             console.log(dataUri.split(";base64,")[1]);
  //             sendBlobFront(dataUri.split(";base64,")[1], moment().unix() + '_Front.pdf')
  //         });
  // };
  // const exportPDFWithMethodBack = () => {
  //     let gridElement = document.querySelector("#pdfBack");
  //     drawDOM(gridElement, {
  //         paperSize: "A4",
  //         scale: 2.7,
  //         fileName: "Backprint",
  //     })
  //         .then((group) => {
  //             return exportPDF(group);
  //         })
  //         .then((dataUri) => {
  //             console.log(dataUri.split(";base64,")[1]);
  //             sendBlobBack(dataUri.split(";base64,")[1], moment().unix() + '_Back.pdf')
  //         });
  // };
  // const sendScreenshotFront = async (blob, name) => {
  //     // Create FormData and append the blob and other fields
  //     const formData = new FormData();
  //     formData.append('document', blob, name);
  //     formData.append('filename', name);

  //     try {
  //         const response = await axios.post(
  //             process.env.REACT_APP_BASE_URL + '/api/system/upload',
  //             formData,
  //             {
  //                 headers: {
  //                     'Content-Type': 'multipart/form-data'
  //                 }
  //             }
  //         );
  //         setScreenshotUrl(response?.data?.data?.path)
  //         console.log('Upload successful222:', response.data.data.path);

  //     } catch (error) {
  //         console.error('Error uploading the file:', error);
  //     }
  // };

  const sendBlobBack = async (base64, name) => {
    let obj = {
      document: base64,
      filename: name,
    };

    try {
      const response = await axios.post(
        process.env.REACT_APP_BASE_URL + "/api/system/uploadDesign",
        obj
      );

      console.log("Upload successful:", response.data.data.path);
      setbackSSUrl(response.data.data.path);
    } catch (error) {
      console.error("Error uploading the file:", error);
    }
  };
  const sendBlobFront = async (base64, name) => {
    let obj = {
      document: base64,
      filename: name,
    };

    try {
      const response = await axios.post(
        process.env.REACT_APP_BASE_URL + "/api/system/uploadDesign",
        obj
      );

      console.log("Upload successful:", response.data.data.path);
      setFrontSSUrl(response.data.data.path);
    } catch (error) {
      console.error("Error uploading the file:", error);
    }
  };
  const sendBlobPreview = async (base64, name) => {
    let obj = {
      document: base64,
      filename: name,
    };

    try {
      const response = await axios.post(
        process.env.REACT_APP_BASE_URL + "/api/system/uploadDesign",
        obj
      );

      console.log("Upload successful:", response.data.data.path);
      setFrontUrl(response.data.data.path);
    } catch (error) {
      console.error("Error uploading the file:", error);
    }
  };
  const sendBlobPreviewBack = async (base64, name) => {
    console.log(base64, "base64");
    let obj = {
      document: base64,
      filename: name,
    };

    try {
      const response = await axios.post(
        process.env.REACT_APP_BASE_URL + "/api/system/uploadDesign",
        obj
      );

      console.log("Upload successful:", response.data.data.path);
      setBackUrl(response.data.data.path);
    } catch (error) {
      console.error("Error uploading the file:", error);
    }
  };
  function calculateDeliveryCharges(quantity, selected) {
    // Calculate the number of full maxOrder (5 items) and the remaining items
    const fullOrders = Math.floor(quantity / maxOrder);
    const remainingItems = quantity % maxOrder;
    console.log(fullOrders);
    console.log(remainingItems);
    console.log(selected, "selected");
    let value = selected
      ? parseInt(selected?.charge)
      : parseInt(selectedOption?.charge);
    // Calculate the total delivery charges
    let deliveryChargesresult = fullOrders * value;
    console.log(deliveryChargesresult, "deliveryChargesresult");
    // If there are any remaining items, add another delivery charge
    if (remainingItems > 0) {
      deliveryChargesresult += value;
    }
    console.log(deliveryChargesresult, "deliveryChargesresult2");

    return deliveryChargesresult;
  }

  useEffect(() => {
    const handleResize = () => {
      setIsLessThan1200(window.innerWidth < 1000);
      console.log(window.innerWidth < 1000);
    };

    window.addEventListener("resize", handleResize);

    // Clean up the event listener
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  useEffect(() => {
    const total = Object.values(quantities).reduce(
      (acc, quantity) => acc + (quantity || 0),
      0
    );
    console.log(total, "total");
    setTotalQuantity(total);

    const deliveryChargesresult = calculateDeliveryCharges(total, null);
    setDeliveryCharges(deliveryChargesresult);
  }, [quantities]);
  useEffect(() => {
    getPricing();
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    const details = Object.entries(quantities).map(([size, qty]) => ({
      size,
      qty,
    }));
    let obj = {
      quantities: details,
      frontScreenshot: frontUrl,
      backScreenshot: backUrl,
      totalQuantity: totalQuantity,
      totalAmount: totalQuantity * tshirtPrice,
      color: allData?.color ? allData?.color : "white",
    };

    console.log(frontSSUrl, "frontSSUrl");
    console.log(backSSUrl, "frontSSUrl");
    if (frontUrl && backUrl) {
      console.log("asdasdasdasdasd");
      obj = JSON.stringify(obj);
      localStorage.setItem("orderData", obj);
    }
  }, [frontUrl, backUrl, quantities]);
  useEffect(() => {
    let cartdata = localStorage.getItem("cartData");
    console.log(cartdata);
    if (cartdata) {
      cartdata = JSON.parse(cartdata);
      console.log(cartdata);
      setQuantities(cartdata?.quantities);
      setTotalQuantity(cartdata?.totalQuantity);
    }
    console.log(state, "state");
    if (state) {
      setAllData(state);
    } else {
      let data = localStorage.getItem("designArray");
      data = JSON.parse(data);
      console.log(data, "data");
      setAllData(data);
    }
    // Run captureScreenshot after the entire DOM content is fully loaded
    // Create a MutationObserver to detect when the element with the class 'pdf-data' is fully loaded
    const observer = new MutationObserver((mutationsList, observer) => {
      const pdfElement = document.querySelector(".pdf-data");
      console.log(pdfElement?.childNodes, "childs");

      if (pdfElement) {
        console.log("loaded");

        captureScreenshot();

        observer.disconnect(); // Stop observing once the element is fully loaded
      }
    });

    // Start observing the document for changes
    observer.observe(document.body, { childList: true, subtree: true });

    // Clean up the observer when the component unmounts
    return () => {
      observer.disconnect();
    };
  }, []);

  function convertToBase64(url) {
    return fetch(url)
      .then((response) => response.blob())
      .then(
        (blob) =>
          new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.onerror = reject;
            reader.readAsDataURL(blob);
          })
      );
  }

  const handleClose = () => setOpen(false);
  const handleClose1 = () => setOpen1(false);

  const loadFont = (fontName) => {
    const link = document.createElement("link");
    link.href = `https://fonts.googleapis.com/css2?family=${fontName?.replace(
      / /g,
      "+"
    )}&display=swap`;
    link.rel = "stylesheet";
    link.crossOrigin = "anonymous";
    document.head.appendChild(link);
  };
  const [base64Images, setBase64Images] = useState([]);
  const getDetail = async () => {
    try {
      let params = {
        id: id,
      };
      const { data } = await OrderServices.getDetail(params);
      console.log(data);
      let newData = JSON.stringify(data?.design?.design)
      setAllData(data?.design?.design);
      localStorage.setItem('designArray', newData)
    } catch (error) {
      console.log(error);
    } finally {
    }
  };

  useEffect(() => {
    const url =
      "https://corsproxy.io/?" +
      encodeURIComponent("https://api.domain.com/...");
  }, []);

  useEffect(() => {
    for (let index = 0; index < allData?.frontText?.length; index++) {
      const fontName = allData?.frontText[index]?.fontFamily;
      loadFont(fontName);
    }
    for (let index = 0; index < allData?.backText?.length; index++) {
      const fontName = allData?.backText[index]?.fontFamily;
      loadFont(fontName);
    }
    if (id) {
      getDetail();
    }
  }, []);

  return (
    <Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="md"
        fullWidth
        aria-labelledby="responsive-dialog-title"
        slotProps={{
          backdrop: {
            sx: {
              backdropFilter: "blur(8px)",
              backgroundColor: "transparent",
            }

          },


        }}
        PaperProps={{
          sx: {
            backgroundColor: '#0F172A',
            boxShadow: 'none',
            borderRadius: '12px',
            '::-webkit-scrollbar': {
              display: "none"
            }
          }
        }}

      >
        <DialogTitle id="responsive-dialog-title" >
          <Typography
            className="heading-font"
            sx={{
              textAlign: "center",
              fontSize: "2rem",
              fontWeight: 'bold'

            }}
          >
            Measurements
          </Typography>
        </DialogTitle>
        <DialogContent sx={{ backgroundColor: '#0F172A' }}>
          {/* Responsive Box with scroll handling */}
          <Box sx={{ overflowX: "auto", maxWidth: "100%" }}>
            <TableContainer component={Paper} sx={{ backgroundColor: '#0F172A' }}>
              <Table sx={{ minWidth: 500 }} aria-label="measurements table">
                <TableHead>
                  <TableRow>
                    <TableCell className="heading-font" sx={{ fontWeight: "bold" }} align="center">
                      SIZE
                    </TableCell>
                    <TableCell className="heading-font" sx={{ fontWeight: "bold" }} align="center">
                      Chest (Inches)
                    </TableCell>
                    <TableCell className="heading-font" sx={{ fontWeight: "bold" }} align="center">
                      Length (Inches)
                    </TableCell>
                    <TableCell className="heading-font" sx={{ fontWeight: "bold" }} align="center">
                      Sleeve Length (Inches)
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody sx={{ color: 'white' }}>
                  {sizeData.map((row, index) => (
                    <TableRow key={index}>
                      <TableCell sx={{ color: 'white' }} align="center">{row.size}</TableCell>
                      <TableCell sx={{ color: 'white' }} align="center">{row.width}</TableCell>
                      <TableCell sx={{ color: 'white' }} align="center">{row.length}</TableCell>
                      <TableCell sx={{ color: 'white' }} align="center">{row.sleeve}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>

              </Table>
            </TableContainer>
          </Box>
        </DialogContent>
      </Dialog>
      <Dialog
        open={open1}
        onClose={handleClose}
        fullWidth
        maxWidth="md" // Sets the max width of the dialog
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
      >
        <DialogTitle
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{ fontSize: "28px", fontFamily: "Plus Jakarta Sans" }}
          >
            Pick Your Fit
          </Typography>
          <IconButton onClick={() => setOpen1(false)} aria-label="close">
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent>
          <Grid container spacing={2}>
            {/* Standard T-shirt Card */}
            <Grid item xs={12} md={6} display="flex" justifyContent="center">
              <Card sx={{ maxWidth: 345 }}>
                <CardActionArea
                  onClick={() => {
                    if (frontSSUrl && backSSUrl) {
                      navigate(`/checkout`, {
                        state: {
                          frontSSUrl: frontSSUrl,
                          backSSUrl: backSSUrl,
                          tshirtType: "Standard",
                          halftone: checked,
                        },
                      });
                    }
                  }}
                >
                  <CardMedia
                    component="img"
                    height="140"
                    image={
                      "https://printmeup.s3.ap-southeast-1.amazonaws.com/Fits/Standard+Fit.png"
                    }
                    alt="standard"
                  />
                  <CardContent sx={{ p: "24px !important" }}>
                    <Typography gutterBottom variant="h5" component="div">
                      Standard
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{ color: "text.secondary" }}
                    >
                      <ul>
                        <li>High-quality cotton for everyday wear</li>
                        <li>Classic fit with breathable fabric</li>
                        <li>Offered in a range of sizes for a perfect fit</li>
                      </ul>
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>

            {/* Premium T-shirt Card */}
            <Grid item xs={12} md={6} display="flex" justifyContent="center">
              <Card sx={{ maxWidth: 345 }}>
                <CardActionArea
                  onClick={() => {
                    if (frontSSUrl && backSSUrl) {
                      navigate(`/checkout`, {
                        state: {
                          frontSSUrl: frontSSUrl,
                          backSSUrl: backSSUrl,
                          tshirtType: "Premium",
                          halftone: checked,
                        },
                      });
                    }
                  }}
                >
                  <CardMedia
                    component="img"
                    height="140"
                    image={
                      "https://printmeup.s3.ap-southeast-1.amazonaws.com/Fits/Premium+Fit.png"
                    }
                    alt="premium"
                  />
                  <CardContent sx={{ p: "24px !important" }}>
                    <Typography gutterBottom variant="h5" component="div">
                      Premium
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{ color: "text.secondary" }}
                    >
                      <ul>
                        <li>Superior cotton-polyester blend</li>
                        <li>Tailored fit with moisture-wicking tech</li>
                        <li>Offered in a range of sizes for a perfect fit</li>
                      </ul>
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
      <Box
        sx={{
          backgroundImage: `url("${Images?.contactBg}")`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          width: "100%",
          height: "300px", // Adjust height as needed
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <p
            className="banner-text heading-font"
            style={{
              fontSize: "35px",
              textAlign: "center",
              color: "white !important",
            }}
          >
            Preview <Box component={"span"} className="banner-text heading-font"
              sx={{
                fontSize: "35px",
                textAlign: "center",
                color: "white !important",
              }}>Order</Box>
          </p>
        </Box>
      </Box>

      <Grid
        container
        sx={{ paddingTop: "2%", paddingBottom: "5%" }}
        justifyContent={"center"}
      >
        <Grid component={"div"} item md={9} lg={9}>
          <Grid container justifyContent={"center"}>
            <Grid component={"div"} item md={11.5} lg={11.5}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  width: "100%",
                  p: "20px",
                }}
              >
                <SecondaryButton
                  sx={{ border: "none", borderRadius: "15px", background: "linear-gradient(to right, #0EA5EA, #0CC7D7, #0BD1D1)" }}
                  onClick={() => navigate("/product-final")}
                  p={"15px 30px"}
                  fontWeight={"bold"}
                  //  icon={<ArrowBackIcon sx={{ fontSize: '17px' }} color='white' />}
                  iconPosition={"start"}
                  color={"#0F172A"}
                  title={"Edit Design"}
                />
              </Box>
            </Grid>
            <Grid
              component={"div"}
              item
              md={11}
              lg={11}
              sm={12}
              xs={12}
              display={"flex"}
              justifyContent={"space-between"}
              alignItems={"center"}
              sx={{
                flexDirection: {
                  lg: "row",
                  md: "row",
                  sm: "column",
                  xs: "column",
                },
              }}
            >
              <Box
                component={"div"}
                className="heading-font"
                sx={{
                  color: "#0BD1D1",
                  fontSize: { lg: "35px", md: "35px", sm: "35px", xs: "30px" },
                  textAlign: "left",
                  fontWeight: 700,
                  display: "flex",
                  alignItems: "flex-end",
                }}
              >
                Finalize

                <Box
                  component={"span"}
                  sx={{
                    color: Colors.white,
                    WebkitTextFillColor: Colors.white,
                    px: 1,
                  }}
                >

                  Your
                </Box>
                <Box
                  component={"span"}
                  className="heading-font"
                  sx={{
                    color: "#0BD1D1",
                    fontSize: { lg: "35px", md: "35px", sm: "35px", xs: "30px" },
                    textAlign: "left",
                    fontWeight: 700,
                    display: "flex",
                    alignItems: "flex-end",
                  }}
                >
                  Design
                </Box>
              </Box>
              <Box
                display="flex"
                alignItems="flex-end"
                justifyContent="center"
                sx={{ ml: { lg: 0, md: 0, sm: 4, xs: 4 } }}
              >
                <Typography
                  component={"p"}
                  sx={{
                    textAlign: "center",
                    color: "white",
                    fontSize: "18px",
                    gap: 1,
                    mt: 1,
                    lineHeight: 3,
                    fontWeight: 600,
                  }}
                >
                  {" "}
                  Preview Halftone :
                </Typography>
                <Switch
                  sx={{ m: 1 }}
                  onChange={(event) => handleChangeTone(event.target.checked)}
                  checked={checked}
                  defaultChecked
                />
              </Box>
            </Grid>
            <Grid container mt={"-50px"}>
              {allData && (
                <Grid
                  component={"div"}
                  className={`tshirt-img-scale2`}
                  ref={elementFront}
                  item
                  lg={6}
                  md={12}
                  sm={12}
                  xs={12}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    height: "570px !important",
                    position: "relative",
                  }}
                >
                  <div style={{ position: "relative" }}>
                    <img
                      width={"500px"}
                      height={"570px"}
                      id="tshirt-backgroundpicture"
                      src={Images[allData?.color ? allData?.color : "white"]}
                    />
                    <div
                      style={{
                        position: "absolute",
                        top: 90,
                        left: 145,
                        backgroundColor: "rgba(0,0,0,0)",
                      }}
                    >
                      {!rotateDirection && (
                        <PDFExport
                          ref={pdfExportComponentFront}
                          paperSize="A4"
                          scale={2.7}
                          fileName="Frontprint"
                        >
                          <div
                            ref={divRef}
                            style={{ backgroundColor: "transparent" }}
                            id="pdfFront"
                          >
                            <div
                              className=" pdf-data"
                              ref={parentRef}
                              style={{
                                width: "220px",
                                height: "313px",
                                position: "relative",
                                overflow: "hidden",
                              }}
                            >
                              {allData?.frontText &&
                                allData.frontText.length > 0 &&
                                allData.frontText.map((item, index) => {
                                  console.log(item?.fontSize, "dedede");
                                  const textStyle = {
                                    fontSize: item?.fontSize
                                      ?.toString()
                                      .includes("px")
                                      ? item.fontSize
                                      : `${item.fontSize}px`,
                                    fontWeight: item.fontWeight,
                                    fill: item.color,
                                    fontFamily: item.fontFamily,
                                  };

                                  const circlePath = `M ${item.width / 2}, ${item.width / 2
                                    } m -${item.width / 3}, 0 a ${item.width / 3
                                    },${item.width / 3} 0 1,1 ${2 * (item.width / 3)
                                    },0 a ${item.width / 3},${item.width / 3
                                    } 0 1,1 -${2 * (item.width / 3)},0`;
                                  const ellipsePath = `M ${item.width / 2}, ${item.width / 2
                                    } m -${item.width / 3}, 0 a ${item.width / 3
                                    },${item.width / 6} 0 1,1 ${2 * (item.width / 3)
                                    },0 a ${item.width / 3},${item.width / 6
                                    } 0 1,1 -${2 * (item.width / 3)},0`;
                                  const wavePath = `M 10, ${item.width / 2} Q ${item.width / 5
                                    }, ${item.width / 5} ${2 * (item.width / 5)
                                    }, ${item.width / 2} T ${4 * (item.width / 5)
                                    }, ${item.width / 2} T ${item.width - 10}, ${item.width / 2
                                    }`;
                                  const straightPath = `M 0,${item.width / 2
                                    } L ${item.width},${item.width / 2}`;

                                  const shapePaths = {
                                    circlePath,
                                    ellipsePath,
                                    wavePath,
                                    straightPath,
                                  };

                                  const selectedPath =
                                    shapePaths[item.shape] || "";
                                  const uniqueId = `front-circlePath${index}`;

                                  return (
                                    <React.Fragment key={index}>
                                      <div
                                        id={index}
                                        style={{
                                          position: "absolute",
                                          width: `${item.width}px`,
                                          height: `${item.height}px`,
                                          top: item?.top
                                            ?.toString()
                                            .includes("px")
                                            ? item.top
                                            : `${item.top}px`,
                                          left: item?.left
                                            ?.toString()
                                            .includes("px")
                                            ? item.left
                                            : `${item.left}px`,
                                          transform: `rotate(${item.rotation}deg)`,
                                          zIndex: item.zIndexes,
                                          backgroundImage: `url('${item.url}')`,
                                          backgroundSize: "contain",
                                          backgroundRepeat: "no-repeat",
                                          backgroundPosition: "center",
                                        }}
                                      ></div>
                                    </React.Fragment>
                                  );
                                })}

                              {allData?.frontPic.length > 0 &&
                                allData?.frontPic.map((imageData, index) => {
                                  return (
                                    <div key={index}>
                                      <Grid
                                        component={"div"}
                                        className={`Design-${index}`}
                                        style={{
                                          clipPath: imageData.clipPath,
                                          width: `${imageData.width}px`,
                                          height: `${imageData.height}px`,
                                          position: "absolute",
                                          backgroundImage: `url(${imageData?.imageUrl})`,
                                          backgroundSize: "contain",
                                          backgroundPosition: "center",
                                          backgroundRepeat: "no-repeat",
                                          top: `${imageData.top}px`,
                                          left: `${imageData.left}px`,
                                          borderRadius: `${imageData.borderRadius}px`,
                                          transform: `${imageData.rotation}`,
                                          zIndex: imageData?.zIndexes,
                                        }}
                                      />
                                      {/* Add overlay image */}
                                      {checked && (
                                        <Grid
                                          component={"div"}
                                          className={`Overlay-${index}`}
                                          style={{
                                            width: `${imageData.width}px`,
                                            height: `${imageData.height}px`,
                                            position: "absolute",
                                            top: `${imageData.top}px`,
                                            left: `${imageData.left}px`,
                                            backgroundImage: `url(${allData?.color == "white"
                                              ? Images?.toneWhite
                                              : allData?.color == "black"
                                                ? Images?.toneBlack
                                                : allData?.color == "lightgreen"
                                                  ? Images?.toneGreen
                                                  : allData?.color == "darkgreen"
                                                    ? Images?.toneDarkGreen
                                                    : allData?.color == "blue"
                                                      ? Images?.toneBlue
                                                      : allData?.color == "yellow"
                                                        ? Images?.toneOrange
                                                        : allData?.color == "purple"
                                                          ? Images?.tonePurple
                                                          : "white"
                                              })`, // Path to the overlay image
                                            backgroundSize: "contain",
                                            backgroundPosition: "center",
                                            backgroundRepeat: "no-repeat",
                                            opacity: 0.5, // Adjust the transparency of the overlay image
                                            zIndex: imageData?.zIndexes + 1, // Place overlay above the image
                                          }}
                                        />
                                      )}
                                    </div>
                                  );
                                })}
                            </div>
                          </div>
                        </PDFExport>
                      )}
                    </div>
                  </div>
                </Grid>
              )}

              {allData && (
                <Grid
                  item
                  lg={6}
                  md={12}
                  sm={12}
                  xs={12}
                  className={`tshirt-img-scale2`}
                  ref={elementBack}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    height: "570px !important",
                  }}
                >
                  {" "}
                  <div style={{ position: "relative" }}>
                    <img
                      width={"500px"}
                      height={"570px"}
                      id="tshirt-backgroundpicture"
                      src={
                        Images[
                        allData?.color
                          ? allData?.color + "Back"
                          : "white" + "Back"
                        ]
                      }
                    />
                    <div style={{ position: "absolute", top: 90, left: 145 }}>
                      <PDFExport
                        ref={pdfExportComponentBack}
                        paperSize="A4"
                        scale={2.7}
                        fileName="Backprint"
                      >
                        <div
                          ref={divRefBack}
                          style={{ backgroundColor: "transparent" }}
                          id="pdfBack"
                        >
                          <div
                            className="pdf-data"
                            ref={parentRef}
                            style={{
                              width: "220px",
                              height: "313px",
                              position: "relative",
                              overflow: "hidden",
                            }}
                          >
                            {allData?.backText &&
                              allData?.backText.length > 0 &&
                              allData.backText.map((item, index) => {
                                const textStyle = {
                                  fontSize: item?.fontSize
                                    ?.toString()
                                    .includes("px")
                                    ? item.fontSize
                                    : `${item.fontSize}px`,
                                  fontWeight: item.fontWeight,
                                  fill: item.color,
                                  fontFamily: item.fontFamily,
                                };
                                const circlePath = `M ${item.width / 2}, ${item.width / 2
                                  } m -${item.width / 3}, 0 a ${item.width / 3},${item.width / 3
                                  } 0 1,1 ${2 * (item.width / 3)},0 a ${item.width / 3
                                  },${item.width / 3} 0 1,1 -${2 * (item.width / 3)
                                  },0`;
                                const ellipsePath = `M ${item.width / 2}, ${item.width / 2
                                  } m -${item.width / 3}, 0 a ${item.width / 3},${item.width / 6
                                  } 0 1,1 ${2 * (item.width / 3)},0 a ${item.width / 3
                                  },${item.width / 6} 0 1,1 -${item.width / 3},0`;
                                const wavePath = `M 10, ${item.width / 2} Q ${item.width / 5
                                  }, ${item.width / 5} ${2 * (item.width / 5)}, ${item.width / 2
                                  } T ${4 * (item.width / 5)}, ${item.width / 2
                                  } T ${item.width - 10}, ${item.width / 2}`;
                                const straightPath = `M 0,${item.width / 2} L ${item.width
                                  },${item.width / 2}`;

                                const shapePaths = {
                                  circlePath,
                                  ellipsePath,
                                  wavePath,
                                  straightPath,
                                };

                                const selectedPath =
                                  shapePaths[item.shape] || "";
                                const uniqueId = `back-circlePath${index}`;

                                return (
                                  <React.Fragment key={index}>
                                    <ContextMenuTrigger
                                      id={`context-menu-back-${index}`}
                                    >
                                      <div
                                        id={`back${index}`}
                                        style={{
                                          position: "absolute",
                                          width: `${item.width}px`,
                                          height: `${item.height}px`,
                                          top: item?.top
                                            ?.toString()
                                            .includes("px")
                                            ? item.top
                                            : `${item.top}px`,
                                          left: item?.left
                                            ?.toString()
                                            .includes("px")
                                            ? item.left
                                            : `${item.left}px`,
                                          transform: `rotate(${item.rotation}deg)`,
                                          zIndex: item.zIndexes,
                                          backgroundImage: `url('${item.url}')`,
                                          backgroundSize: "contain",
                                          backgroundRepeat: "no-repeat",
                                          backgroundPosition: "center",
                                        }}
                                      ></div>
                                    </ContextMenuTrigger>
                                  </React.Fragment>
                                );
                              })}

                            {allData?.backPic.length > 0 &&
                              allData?.backPic.map((imageUrl, index) => (
                                <div key={index}>
                                  <Grid
                                    component={"div"}
                                    className={`Design2-${index}`}
                                    style={{
                                      clipPath:
                                        allData?.backPic[index].clipPath,
                                      width: `${allData?.backPic[index].width}px`,
                                      height: `${allData?.backPic[index].height}px`,
                                      position: "absolute",
                                      backgroundImage: `url(${imageUrl?.imageUrl})`, // Use the base64 image
                                      backgroundSize: "contain",
                                      backgroundPosition: "center",
                                      backgroundRepeat: "no-repeat",
                                      top: `${allData?.backPic[index].top}px`,
                                      left: `${allData?.backPic[index].left}px`,
                                      borderRadius: ` ${allData?.backPic[index].borderRadius}px`,
                                      transform: `${allData?.backPic[index].rotation}`,
                                      zIndex: imageUrl?.zIndexes,
                                    }}
                                  />
                                  {checked && (
                                    <Grid
                                      component={"div"}
                                      className={`Overlay2-${index}`}
                                      style={{
                                        width: `${imageUrl.width}px`,
                                        height: `${imageUrl.height}px`,
                                        position: "absolute",
                                        top: `${imageUrl.top}px`,
                                        left: `${imageUrl.left}px`,
                                        backgroundImage: `url(${allData?.color == "white"
                                          ? Images?.toneWhite
                                          : allData?.color == "black"
                                            ? Images?.toneBlack
                                            : allData?.color == "lightgreen"
                                              ? Images?.toneGreen
                                              : allData?.color == "darkgreen"
                                                ? Images?.toneDarkGreen
                                                : allData?.color == "blue"
                                                  ? Images?.toneBlue
                                                  : allData?.color == "yellow"
                                                    ? Images?.toneOrange
                                                    : allData?.color == "purple"
                                                      ? Images?.tonePurple
                                                      : "white"
                                          })`, // Path to the overlay image
                                        backgroundSize: "contain",
                                        backgroundPosition: "center",
                                        backgroundRepeat: "no-repeat",
                                        opacity: 0.5, // Adjust the transparency of the overlay image
                                        zIndex: imageUrl?.zIndexes + 1, // Place overlay above the image
                                      }}
                                    />
                                  )}
                                </div>
                              ))}
                          </div>
                        </div>
                      </PDFExport>
                    </div>
                  </div>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={9} display={"flex"} mb={7.5}>
          <Typography variant="h6" color="white" sx={{ fontWeight: 700 }}>
            &nbsp; Complete your Order :
          </Typography>
        </Grid>
        <Box
          component={"form"}
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
          onSubmit={handleSubmit2(AddToCart)}
        >
          <Grid container xs={9} display={"flex"} spacing={2}>
            <Grid item lg={3} md={6} sm={6} xs={12}>
              <InputLabel style={{ color: "white" }}>
                Select Age Group
              </InputLabel>
              <FormControl
                fullWidth
                variant="outlined"
                sx={{
                  mt: 3,
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "#3b4556",
                    },
                    "&:hover fieldset": {
                      borderColor: "#3b4556",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "#3b4556",
                    },
                    "& .MuiSelect-icon": {
                      color: "white",
                    },
                    fieldset: {
                      borderRadius: "15px",
                    },
                  },
                }}
              >
                <Select
                  displayEmpty
                  value={ageGroup}
                  sx={{
                    color: "white",
                    "& .MuiSelect-placeholder": {
                      color: "#5a5f68",
                    },
                  }}
                  defaultValue=""
                  {...register2("ageGroup", {
                    required: "Please select an age group",
                    onChange: (event) => {
                      setAgeGroup(event.target.value); // Update the state when the value changes
                    },
                  })}
                >
                  <MenuItem value="" disabled>
                    <span style={{ color: "#5a5f68" }}>Select</span>
                  </MenuItem>
                  <MenuItem value="Child">Child</MenuItem>
                  <MenuItem value="Adult">Adult</MenuItem>
                </Select>

              </FormControl>

              {errors2.ageGroup && !ageGroup &&  (
                <Box sx={{ color: "#8c2929" }}>{errors2.ageGroup.message}</Box>
              )}
            </Grid>

            <Grid item lg={3} md={6} sm={6} xs={12}>
              <InputLabel style={{ color: Colors.white }}>
                Select Size
              </InputLabel>
              <FormControl
                fullWidth
                variant="outlined"
                sx={{
                  mt: 3,
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "#3b4556",
                    },
                    "&:hover fieldset": {
                      borderColor: "#3b4556",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "#3b4556",
                    },
                    fieldset: {
                      borderRadius: "15px",
                    },
                  },
                  "& .MuiSelect-icon": {
                    color: "white",
                  },
                }}
              >
                <Select
                  displayEmpty
                  defaultValue=""
                  value={size}
                  sx={{
                    color: "white",
                    "& .MuiMenuItem-root": {
                      color: "#5a5f68",
                    },
                  }}
                  {...register2("size", {
                    required: "Please select a size",
                    onChange: (event) => {
                      setSize(event.target.value); // Update the state when value changes
                    },
                  })}
                >
                  <MenuItem value="" disabled>
                    <span style={{ color: "#5a5f68" }}>Select</span>
                  </MenuItem>
                  <MenuItem value="Small">Small</MenuItem>
                  <MenuItem value="Medium">Medium</MenuItem>
                  <MenuItem value="Large">Large</MenuItem>
                  <MenuItem value="Extra Large">Extra Large</MenuItem>
                </Select>

              </FormControl>

              {errors2.size && !size && (
                <Box sx={{ color: "#8c2929" }}>{errors2.size.message}</Box>
              )}
            </Grid>

            <Grid item lg={3} md={6} sm={6} xs={12}>
              <InputLabel style={{ color: Colors.white }}>
                Enter Quantity
              </InputLabel>
              <TextField
                fullWidth
                variant="outlined"
                // label="Enter Quantity"
                placeholder="Enter Quantity"
                type="number"
                InputLabelProps={{ style: { color: "#C9D1D9" } }}
                inputProps={{
                  style: { color: "white" },
                  inputMode: "numeric",
                  pattern: "[0-9]*",
                  sx: {
                    "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button":
                    {
                      display: "none",
                    },
                    "&[type=number]": {
                      MozAppearance: "textfield",
                    },
                  },
                }}
                sx={{
                  mt: 3,
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "#3b4556",
                    },
                    "&:hover fieldset": {
                      borderColor: "#3b4556",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "#3b4556",
                    },
                    fieldset: {
                      borderRadius: "15px",
                    },
                  },
                }}
                {...register2("quantity", {
                  required: "Please enter a quantity",
                  valueAsNumber: true,
                  min: { value: 1, message: "Quantity must be at least 1" },
                })}
              />
              {errors2.quantity && (
                <Box sx={{ color: "#8c2929" }}>{errors2.quantity.message}</Box>
              )}
            </Grid>

            <Grid item lg={3} md={6} sm={6} xs={12} sx={{ mt: 6 }}>
              <SecondaryButton
                sx={{ border: "none", width: "100%", borderRadius: "15px", background: "linear-gradient(to right, #0EA5EA, #0CC7D7, #0BD1D1)" }}
                fontWeight={600}
                onClick={() => setOpen(true)}
                p={"15px 30px"}
                iconPosition={"end"}
                icon={<FaTshirt style={{ fontSize: "17px" }} color="#0F172A" />}
                color={"#0F172A"}
                title={"View Size Guide"}
              />
            </Grid>
          </Grid>

          <Grid container xs={9} mt={4} spacing={4}>
            <Grid item md={6} width={"100%"}>
              <Typography
                variant="h3"
                sx={{
                  textAlign: "left",
                  color: Colors.white,
                  fontSize: "20px",
                }}
              >
                Select Shirt Quality
              </Typography>
            </Grid>
            <Grid
              item
              md={6}
              width={"100%"}
              sx={{
                display: { lg: "flex", md: "flex", sm: "none", xs: "none" },
              }}
            >
              <Typography
                variant="h3"
                sx={{
                  textAlign: "left",
                  color: Colors.white,
                  fontSize: "20px",
                }}
              >
                Select Print Type
              </Typography>
            </Grid>
            {[
              {
                id: "standard",
                name: "Standard",
                price: pricingDetails?.standard_price,
                description: [
                  "High-quality cotton for everyday wear",
                  "Classic fit with breathable fabric",
                  "Offered in a range of sizes for a perfect fit",
                ],
              },
              {
                id: "premium",
                name: "Premium",
                price: pricingDetails?.premium_price,
                description: [
                  "Luxurious, soft-touch cotton",
                  "Slim fit with enhanced durability",
                  "Available in exclusive colors and designs",
                ],
              },
            ].map((item, index) => (
              <Grid key={index} item md={3} width={"100%"}>
                <CardMedia
                  image={Images[item?.id]}
                  onClick={() => setShirtQuality(item)}
                  sx={{
                    height: "200px",
                    backgroundSize: "cover",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    position: "relative",
                    cursor: "pointer",
                    width: "100%",
                    borderRadius: "10px",
                    border: item.id === shirtQuality?.id ? "3px solid #0BD1D1" : "3px solid transparent",
                    transition: "border-color 0.5s ease, color 0.5s ease",
                  }}
                >
                  <Box sx={{ position: "absolute", top: 1, left: 10 }}>
                    <Radio
                      checked={item.id === shirtQuality?.id}
                      onChange={() => {
                        setShirtQuality(item);
                        console.log(item);
                      }}
                      sx={{ "& .MuiSvgIcon-root": { fontSize: 32 } }}
                    />
                  </Box>
                  <Box
                    sx={{
                      position: "absolute",
                      top: 5,
                      right: 10,
                      fontWeight: 500,
                      backgroundColor: "#0db5e1",
                      borderRadius: "8px",
                      p: 0.7,
                      fontSize: "11px",
                      px: 2,
                    }}
                  >
                    AED {item?.price}
                  </Box>
                  <Box
                    sx={{
                      position: "absolute",
                      bottom: 5,
                      left: 5,
                      color: Colors.white,
                      textAlign: "left",

                      borderRadius: "8px",
                    }}
                  >
                    <Typography
                      variant="h6"
                      sx={{ fontWeight: "bold", fontSize: "15px" }}
                    >
                      {item.name}
                    </Typography>
                    <Box
                      component="ul"
                      sx={{ paddingLeft: 2, margin: 0, color: Colors.white }}
                    >
                      {item.description.map((line, i) => (
                        <Box
                          component="li"
                          key={i}
                          sx={{ fontSize: "11px", lineHeight: 1.5 }}
                        >
                          {line}
                        </Box>
                      ))}
                    </Box>
                  </Box>
                </CardMedia>
              </Grid>
            ))}

            <Grid
              item
              md={6}
              width={"100%"}
              sx={{
                display: { lg: "none", md: "none", sm: "flex", xs: "flex" },
              }}
            >
              <Typography
                variant="h3"
                sx={{
                  textAlign: "left",
                  color: Colors.white,
                  fontSize: "20px",
                }}
              >
                Select Print Type
              </Typography>
            </Grid>
            {[
              {
                id: "fullTone",
                name: "FullTone",
                price: pricingDetails?.full_intensity,
              },
              {
                id: "halfTone",
                name: "HalfTone",
                price: pricingDetails?.halftone,
              },
            ].map((item, index) => (
              <Grid key={index} item md={3} width={"100%"}>
                <CardMedia
                  image={Images[item?.id]}
                  onClick={() => setPrintType(item)}
                  sx={{
                    height: "200px",
                    backgroundSize: "cover",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    position: "relative",
                    cursor: "pointer",
                    width: "100%",
                    borderRadius: "10px",
                    border: "3px solid transparent",  
                    borderColor: item.id === printType?.id ? "#0BD1D1" : "transparent",  
                    transition: "border-color 0.5s ease, color 0.5s ease",
                    
                  }}
                >
                  <Box sx={{ position: "absolute", top: 5, left: 10 }}>
                    <Radio
                      checked={item.id === printType?.id}
                      onChange={() => {
                        setPrintType(item);
                        console.log(item);
                      }}
                      sx={{ "& .MuiSvgIcon-root": { fontSize: 32 } }}
                    />
                  </Box>
                  {/* <Box
                    sx={{
                      position: "absolute",
                      top: 5,
                      right: 10,
                      fontWeight: 500,
                      backgroundColor: "#0db5e1",
                      borderRadius: "8px",
                      p: 0.7,
                      fontSize: "11px",
                      px: 2,
                    }}
                  >
                    AED {item?.price}
                  </Box> */}
                  <Typography
                    variant="h4"
                    sx={{ textAlign: "center", color: Colors.white }}
                  >
                    {item.label}
                  </Typography>
                </CardMedia>
              </Grid>
            ))}
          </Grid>
          <Grid container justifyContent={"center"} mt={6} gap={4}>
            <PrimaryButton
              title={"Add to Cart"}
              color={"#0F172A"}
              sx={{ fontWeight: 600, borderRadius: "15px", background: "linear-gradient(to right, #0EA5EA, #0CC7D7, #0BD1D1)" }}
              backgorundColor={"#0EA5EA"}
              type={"submit"}
              onClick={() => setRedirectionState(false)}
            />
            {/* <PrimaryButton
              title={"Go to Cart"}
              sx={{
                p: "10px 40px !important",
                fontWeight: 600,
                borderRadius: "15px",
              }}
              onClick={() => {
                navigate("/cart");
              }}
            /> */}
          </Grid>
        </Box>
        {/* <Grid item md={12} lg={12}>
                    <>

                        <ThemeProvider theme={theme}>


                            <Typography sx={{ textAlign: 'center', color: 'white', fontSize: '28px', mt: '1%', fontFamily: "Plus Jakarta Sans", fontWeight: 'bold' }}>Order Details:</Typography>

                          

                            <Grid container justifyContent={'center'}>
                                {sizes.map((size) => (
                                    <Grid item lg={1} md={3} sm={3} xs={3} display={'flex'} justifyContent={'center'}>
                                        <div key={size} className="size-quantity-box">
                                            <h6 style={{ color: 'white', textAlign: 'center', fontFamily: "Plus Jakarta Sans", fontWeight: 400, fontSize: '14px' }}>{size == 'S' ? 'Small' : size == "M" ? 'Medium' : size == "L" ? 'Large' : 'Extra Large'}</h6>

                                            <div className="quantity-controls" style={{ display: 'flex', justifyContent: 'center' }}>

                                                <input type="text" placeholder='0' style={{ backgroundColor: 'transparent', border: 'none', outline: "none", color: 'white', border: '1px solid white', textAlign: 'center', width: '40px', height: '40px', borderRadius: '7px', fontSize: '22px', fontFamily: "Plus Jakarta Sans", marginTop: '10px' }} value={isNaN(quantities[size]) ? '' : quantities[size] ? quantities[size] : ''} onChange={(event) => handleQuantityChange(size, event)} />

                                            </div>
                                        </div>
                                    </Grid>
                                ))}
                                <Grid item md={12} sm={12} xs={12} display={'flex'} justifyContent={'center'}>
                                    <Box sx={{ mt: "40px" }}><span style={{ color: 'white', textAlign: 'center', fontFamily: "Plus Jakarta Sans", fontSize: '25px' }}>Total Quantity :<span style={{ color: '#0bcfd2', fontWeight: 'bold' }}> {totalQuantity}</span> </span>&nbsp;</Box>

                                </Grid>
                                <Grid item md={12} sm={12} xs={12} display={'flex'} justifyContent={'center'}>

                                    <Box sx={{ mt: "40px", mb: '30px' }}><span style={{ color: 'white', textAlign: 'center', fontFamily: "Plus Jakarta Sans", fontSize: '25px' }}>Subtotal : </span>&nbsp; <span style={{ color: 'white', textAlign: 'center', fontFamily: "Plus Jakarta Sans", fontSize: '25px', color: '#0bcfd2', fontWeight: 'bold' }}>AED {totalQuantity * tshirtPrice}</span></Box>
                                </Grid>
                                {checkOut && <Grid item md={12} sm={12} xs={12} display={'flex'} justifyContent={'center'}>
                                    <Box sx={{ mt: "40px" }}><span style={{ color: 'white', textAlign: 'center', fontFamily: "Plus Jakarta Sans", fontSize: '20px', color: 'red' }}>Please Enter Quantity </span></Box>

                                </Grid>}
                                <SecondaryButton onClick={() => {


                                    console.log(totalQuantity > 0, 'totalQuantitytotalQuantity');
                                    if (totalQuantity > 0) {
                                        let currentUser = localStorage.getItem('user')
                                        currentUser = JSON.parse(currentUser)
                                        if (!currentUser) {

                                            handleGoogleLogin()
                                        }
                                        else {
                                            let obj = {
                                                quantities: quantities,
                                                totalQuantity: totalQuantity,
                                                totalAmount: totalQuantity * tshirtPrice,
                                                // frontScreenshot:frontSSUrl,
                                                // backScreenshot:backSSUrl
                                            }
                                            obj = JSON.stringify(obj)
                                            console.log(obj);

                                            localStorage.setItem('cartData', obj)
                                            setOpen1(true)
                                        }
                                    }
                                    else {
                                        setCheckOut(true)

                                    }
                                }} className={'checkout-btn'} iconPosition={'end'} m={'20px'} p={"15px 40px"} fontWeight={'bold'} color={'#0C1427'} icon={(frontSSUrl && backSSUrl) && <ArrowForwardIcon sx={{ color: '#0C1427' }} />} title={frontSSUrl && backSSUrl ? 'Proceed To Checkout' : <CircularProgress style={{ color: '#000' }} />} />
                            </Grid>

                        </ThemeProvider>
                    </>
                </Grid> */}
      </Grid>
    </Fragment>
  );
}
