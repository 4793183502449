import React, { useEffect, useState } from 'react'
import { Images } from '../../../assests'
import { Box, Button, CardMedia, Divider, Grid, IconButton, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from '@mui/material'
import Colors from '../../../assests/style'
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import OrderServices from '../../../services/OrderServices';
import { PrimaryButton } from '../../../components/Buttons';
import ConfirmationDialog from '../../../components/DialogBoxComponent';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';



const Cart = () => {
    const navigate = useNavigate()
    const [cartItems, setCartItems] = useState([])
    const [confirmationDialog, setConfirmationDialog] = useState(false)
    const [selectedItem, setSelectedItem] = useState(null)

    const UpdateCart = async (cartData) => {

        console.log(cartItems, 'cartItems');



        try {
            let obj = {

                items:cartData ?  cartData : cartItems
            }

            const { message } = await OrderServices.UpdateCart(obj)
            Swal.fire({
                title: message,
        
                icon: "success",
                background: "#0f172a", // Change to your desired background color
                color: "#fff", // Change to your desired text color
              }).then((result) => {
                if (result.isConfirmed) {
                  // window.location.href = '/my-account'; // Navigate to the home page
                }
              });

        } catch (error) {
            console.log(error);
        } finally {

        }
    }


    const handleDelete = async (item) => {
        setConfirmationDialog(false)
        let selectedId = selectedItem?._id
        setCartItems(cartItems?.filter((item => item?._id != selectedId)))
       
        let cartData=cartItems?.filter((item => item?._id != selectedId))
      
        if(cartData.length == 0){

            UpdateCart(cartData)
        }



    }
    const getCartData = async (sendData) => {
        console.log(sendData, 'data');
        try {
            let params = {

            }
            const { data } = await OrderServices.CartData(params)
            console.log(data?.cart?.designs);
            const extractedDetails = data?.cart?.designs?.flatMap(item =>
                item.details.map(detail => ({
                    ...detail,
                    generated_art: item.design_id.generated_art,
                    design_id: item.design_id?._id
                }))
            );

        
            setCartItems(extractedDetails)

        } catch (error) {
            console.log(error);
        } finally {

        }
    }

    // Add the handlers outside the component mapping
    const handleIncrease = (index) => {
        const updatedItems = [...cartItems];
        updatedItems[index].qty += 1;
        setCartItems(updatedItems);
    };

    const handleDecrease = (index) => {
        const updatedItems = [...cartItems];
        if (updatedItems[index].qty > 1) {
            updatedItems[index].qty -= 1;
            setCartItems(updatedItems);
        }
    };
    const total = cartItems?.reduce((acc, item) => acc + item.price * item.qty, 0);
    useEffect(() => {
        getCartData()
    }, [])




    return (
        <div>
            {/* ========== Confirmation Dialog ========== */}
            <ConfirmationDialog
                open={confirmationDialog}
                onClose={() => setConfirmationDialog(false)}
                message={"Confirm to delete?"}
                deleteBox={true}
                description={'Are you sure you want to delete this item from cart?'}
                sx={{  '& .MuiDialog-paper': {
                    width: {lg:'30% !important',md:'40% !important',sm:'50% !important',xs:'80% !important'},
                   height: "auto",
                   borderRadius: 2,
                   py: { xs: 2, md: 4 },
                   px: { xs: 3, md: 6 },
                   backgroundColor: '#0F172A'
                 }}}
                action={handleDelete}
            />

            <Box
                sx={{
                    backgroundImage: `url("${Images.contactBg}")`,
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                    width: '100%',
                    height: '300px', // Adjust height as needed
                    display: 'flex',
                    alignItems: 'center', justifyContent: 'center',
                    position: "relative"
                }}
            >
                {/* <Box
                    sx={{
                        position: "absolute",
                        top: 0,
                        width: "100%",
                        height: "100%",
                        background: Colors.backgroundColor1
                    }}
                /> */}
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                    <p className='banner-text heading-font' style={{ fontSize: '35px', textAlign: 'center', fontFamily: "Noto Sans, sans-serif", }}> My Cart  </p>
                    {/* <p style={{ fontSize: '25px', textAlign: 'center', color: 'white', fontFamily: "Noto Sans, sans-serif", }}>Homepage || <span style={{ color: Colors.secondary }}>Contact Us</span></p> */}
                </Box>
            </Box>
            <Grid container justifyContent={'center'} py={10}>
                <Grid item lg={8} md={8} sm={12} xs={12}>
                    <Box sx={{ color: '#00bcd4', p: 4, borderRadius: 2 }}>
                        <Typography className='heading-font' variant="h4" sx={{ mb: 3, textAlign: 'center', fontWeight: 'bold' }}>
                            Confirm <Box component={"span"}  variant="h4" sx={{ mb: 3, textAlign: 'center', fontWeight: 'bold' ,color:"white !important" ,WebkitTextFillColor:"white" }}  >Your Creation</Box> <Box className='heading-font' component={"span"} sx={{ mb: 3, textAlign: 'center', fontWeight: 'bold' }}>Now</Box> 
                        </Typography>
                        <Box sx={{ backgroundColor: '#151d2f', p: 2, borderRadius: "15px", mb: 2, display: { lg: 'block', md: 'block', sm: 'none', xs: 'none' } }}>
                            <Grid container spacing={2} alignItems="center">
                                {/* Item Image */}
                                <Grid item xs={6} sx={{ color: '#878b94' }}>
                                    Name
                                </Grid>



                                {/* Price and Quantity */}
                                <Grid item xs={2} textAlign="center">
                                    <Typography sx={{ color: '#878b94' }}>Price </Typography>

                                </Grid>
                                <Grid item xs={2} textAlign="center">
                                    <Typography component="span" sx={{  color: '#878b94' }}>
                                        Quantity
                                    </Typography>
                                </Grid>
                                {/* Total and Remove Button */}
                                <Grid item xs={2} textAlign="center">
                                    <Typography sx={{ color: '#878b94' }}>Total</Typography>

                                </Grid>
                            </Grid>
                        </Box>
                        {cartItems?.length > 0 ? cartItems?.map((item, index) => (
                            <Box key={index} sx={{ backgroundColor: '#15273f', p: 2, borderRadius: "15px", mb: 2, position: 'relative' }}>
                                <Box component={'img'} width={'12px'} onClick={() => { setSelectedItem(item); setConfirmationDialog(true) }} src={Images.crossIcon} sx={{ position: 'absolute', top: 10, right: 10, fontWeight: 'bold', cursor: 'pointer' }} ></Box>
                                <Grid container spacing={2} alignItems="center">
                                    {/* Item Image */}
                                    <Grid item lg={6} md={6} sm={12} xs={12} display={'flex'} justifyContent={'flex-start'} alignItems={'center'} gap={2}>
                                        <Box
                                            sx={{
                                                position: 'relative',
                                                width: '100px',
                                                height: 'auto',
                                                '&:hover .overlay': {
                                                    opacity: 1,
                                                },
                                            }}
                                        >
                                            <Box sx={{ p: '10px', backgroundColor: '#0BD1D14D', display: 'flex', justifyContent: 'center' }}>


                                                <Box
                                                    component="img"
                                                    src={item.generated_art}
                                                    alt="Item"
                                                    sx={{
                                                        width: '80px',
                                                        height: '100px',
                                                        borderRadius: 1,
                                                    }}
                                                />
                                            </Box>
                                            <Box
                                                className="overlay"
                                                sx={{
                                                    position: 'absolute',
                                                    top: 0,
                                                    left: 0,
                                                    width: '100%',
                                                    height: '100%',
                                                    backgroundColor: 'rgba(0, 0, 0, 0.5)',  // semi-transparent overlay
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    opacity: 0,
                                                    transition: 'opacity 0.3s ease',
                                                    borderRadius: 1,
                                                    color: 'white',
                                                }}
                                            >
                                                <Button
                                                    onClick={() => navigate(`/order/${item?.design_id}`)}
                                                    sx={{ color: 'white', textTransform: 'none' }}
                                                >
                                                    Preview
                                                </Button>
                                            </Box>
                                        </Box>
                                        <Box>
                                            <Typography variant="body2">Age Group: <span style={{ color: 'white' }}>{item?.size_type} </span></Typography>
                                            <Typography variant="body2">Size:  <span style={{ color: 'white' }}>{item?.size} </span></Typography>
                                            <Typography variant="body2">Shirt Quality:  <span style={{ color: 'white', textTransform: 'capitalize' }}>{item?.print_intensity}</span></Typography>
                                            <Typography variant="body2">Print Quality:<span style={{ color: 'white', textTransform: 'capitalize' }}> {item?.shirt_type}</span></Typography>
                                        </Box>
                                    </Grid>



                                    {/* Price and Quantity */}
                                    <Grid item lg={2} md={2} sm={4} xs={4} textAlign="center">
                                        <Typography sx={{ color: 'white' }}>AED {item?.price}</Typography>

                                    </Grid>
                                    <Grid item lg={2} md={2} sm={4} xs={4} textAlign="center">
                                        <Box display="flex" alignItems="center" justifyContent="center">
                                            <Box
                                                component={'div'}
                                                sx={{
                                                    width: '30px',
                                                    height: '30px',
                                                    backgroundColor: '#0a84ff',
                                                    cursor: 'pointer',
                                                    borderRadius: '50%', // This makes it circular,
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    color: '#131E38',
                                                    fontWeight: 'bold',
                                                    background:'linear-gradient(to right, #0EA5EA, #0CC7D7, #0BD1D1)'
                                                }}

                                                onClick={() => handleDecrease(index)}
                                            >
                                                -
                                            </Box>

                                            <Typography component="span" sx={{ fontSize: '1.2rem', color: 'white', mx: 1 }}>
                                                {item?.qty}
                                            </Typography>
                                            <Box
                                                component={'div'}
                                                sx={{
                                                    width: '30px',
                                                    height: '30px',
                                                    backgroundColor: '#0a84ff',
                                                    cursor: 'pointer',
                                                    borderRadius: '50%', // This makes it circular,
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    color: '#131E38',
                                                    fontWeight: 'bold',
                                                    background:'linear-gradient(to right, #0EA5EA, #0CC7D7, #0BD1D1)'
                                                }}

                                                onClick={() => handleIncrease(index)}
                                            >
                                                +
                                            </Box>
                                        </Box>
                                    </Grid>
                                    {/* Total and Remove Button */}
                                    <Grid item lg={2} md={2} sm={4} xs={4} textAlign="center">
                                        <Typography sx={{ color: 'white' }}>AED {item.price * item.qty}</Typography>

                                    </Grid>
                                </Grid>
                            </Box>
                        )) : <Box sx={{ backgroundColor: '#151d2f', p: 2, borderRadius: "15px", mb: 2 }}>
                            <Grid container spacing={2} alignItems="center" justifyContent={'flex-end'}>




                                <Grid item xs={12} textAlign="center">
                                    <Typography component="span" sx={{ fontSize: '1.2rem', fontWeight: 'bold' }}>
                                        No Items Available
                                    </Typography>
                                </Grid>

                            </Grid>
                        </Box>}

                        <Box sx={{ backgroundColor: '#151d2f', p: 2, borderRadius: "15px", mb: 2 }}>
                            <Grid container spacing={2} alignItems="center" justifyContent={'flex-end'}>




                                <Grid item xs={2} textAlign="center">
                                    <Typography component="span" sx={{ fontSize: '1.2rem', fontWeight: 'bold' }}>
                                        Total
                                    </Typography>
                                </Grid>
                                {/* Total and Remove Button */}
                                <Grid item xs={2} textAlign="center">
                                    <Typography sx={{ fontWeight: 'bold' }} >AED {total}</Typography>

                                </Grid>
                            </Grid>
                        </Box>
                        {cartItems?.length > 0 && <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2 }}>

                            <PrimaryButton
                                title={"Update Cart"}
                                sx={{ p: "10px 40px !important", fontWeight: 600, textAlign: 'center',borderRadius:'15px' ,background:'linear-gradient(to right, #0EA5EA, #0CC7D7, #0BD1D1)',color:"rgba(15, 23, 42, 1)"}}
                                type={'submit'}
                                onClick={() => UpdateCart()}

                            />
                            <PrimaryButton
                                title={"Checkout"}
                                sx={{ p: "10px 40px !important", fontWeight: 600, textAlign: 'center',borderRadius:'15px',background:'linear-gradient(to right, #0EA5EA, #0CC7D7, #0BD1D1)',color:"rgba(15, 23, 42, 1)" }}

                                onClick={() => navigate('/checkout')}

                            />
                        </Box>}
                    </Box>
                </Grid>
            </Grid>
        </div>
    )
}

export default Cart
