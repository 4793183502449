import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  CircularProgress,
  Container,
  Dialog,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { Fragment, useContext, useEffect, useRef, useState } from "react";
import PersonIcon from "@mui/icons-material/Person";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import SettingsIcon from "@mui/icons-material/Settings";
import LogoutIcon from "@mui/icons-material/Logout";
import background from "../../../assests/images/mayaccountbg.png";
import EditIcon from "@mui/icons-material/Edit";
import { CartIcon, Images, LibraryIcon, MapIcon, UserIcon } from "../../../assests";
import OrderServices from "../../../services/OrderServices";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import CloseIcon from "@mui/icons-material/Close";
import { FaTshirt } from "react-icons/fa";
import { FaClipboardList } from "react-icons/fa";
import CircleIcon from "@mui/icons-material/Circle";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import TrendingDownIcon from "@mui/icons-material/TrendingDown";
import DrawIcon from '@mui/icons-material/Draw';
import { PrimaryButton, SecondaryButton } from "../../../components/Buttons";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import Colors from "../../../assests/style";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import axios from "axios";

import UploadIcon from "@mui/icons-material/Upload";
import AuthServices from "../../../services/AuthServices";
import OTPInput from "react-otp-input";
import { AuthContext } from "../../../Context/AuthContext";
export default function MyAccount() {
  const { state } = useLocation();
  console.log(state, 'state');
  const [loadingStates, setLoadingStates] = useState({});

  const handleImageLoad = (index) => {
    setLoadingStates((prevState) => ({
      ...prevState,
      [index]: true,
    }));
  };
  const [partnerModal, setPartnerModal] = useState(false)
  const [partnerModal2, setPartnerModal2] = useState(false)
  const [partnerModal3, setPartnerModal3] = useState(false)
  const [loadingStates2, setLoadingStates2] = useState({});
  const [showPasswordLogin, setShowPasswordLogin] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);

  const handleImageLoad2 = (index) => {
    setLoadingStates2((prevState) => ({
      ...prevState,
      [index]: true,
    }));
  };
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    watch,
    reset
  } = useForm();
  const {
    register: register2,
    handleSubmit: handleSubmit2,
    formState: { errors: errors2 },
    setValue: setValue2,
    getValues: getValues2,
    watch: watch2,
    reset: reset2
  } = useForm();
  const {
    register: register4,
    handleSubmit: handleSubmit4,
    formState: { errors: errors4 },
    setValue: setValue4,
    getValues: getValues4,
    watch: watch4,
    reset: reset4
  } = useForm();
  const fileInputRef = useRef(null);
  const [selectedTab, setSelectedTab] = useState({ id: 0, name: "My Account", icon: <UserIcon /> });
  const [userData, setUserData] = useState();
  const [orders, setOrders] = useState([]);
  const [totalQuantity, setTotalQuantity] = useState(0);
  const navigate = useNavigate();
  const [nextHover1, setNextHover1] = useState(false);
  const [nextHover2, setNextHover2] = useState(false);
  const [nextHover3, setNextHover3] = useState(false);
  const [nextHover4, setNextHover4] = useState(false);
  const [imageURL, setImageURL] = useState()
  const [hovered, setHovered] = useState(false);
  const [emirates, setEmirates] = useState([])
  const [selectedOption, setSelectedOption] = useState(null)
  const [otpToken, setOtpToken] = useState(null);
  const [otpError, setOtpError] = useState(false)
  const [designLoader, setDesignLoader] = useState(true)
  const [libraryLoader, setLibraryLoader] = useState(true)
  const [otpEnable, setOtpEnable] = useState(false);
  const [otp, setOtp] = useState("");
  const { user, setUser } = useContext(AuthContext);


  console.log(selectedTab)
  const [credits, setCredits] = useState([]);
  const [designs, setDesigns] = useState([]);

  const handleImageClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = async (e) => {
    try {
      const formData = new FormData();
      formData.append("document", e.target.files[0]);

      const response = await axios.post(
        process.env.REACT_APP_BASE_URL + '/api/system/upload',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      );


      setImageURL(response?.data?.data?.path);


    } catch (error) {

    }
  };
  const addressData = [

    {
      id: 3,
      type: "Friend",
      name: "John Doe",
      addressLine1: "4045 Schoolhouse lane",
      addressLine2: "Prottville, AL 36087",
      landmark: "Opposite Well Society",
      phone: "98765-535322",
      isDefault: false,
    },
  ];
  const getPricing = async () => {
    try {
      let params = {

      }
      const { data } = await OrderServices.getPricing(params)
      console.log(data);
      setEmirates(data?.pricing?.delivery_charges)




    } catch (error) {
      console.log(error);
    } finally {

    }
  }
  const onSubmit = async (formData) => {
    setPartnerModal(false)
    try {
      let obj = {
        name: formData?.name,
        phone: formData?.mobile,
        picture: imageURL
      };

      const { message } = await OrderServices.UpdateProfile(obj);
      console.log(message);
      if (message) {

        userProfile()
      }
      Swal.fire({
        title: message,

        icon: "success",
        background: "#0f172a", // Change to your desired background color
        color: "#fff", // Change to your desired text color
      }).then((result) => {
        if (result.isConfirmed) {
          // window.location.href = '/my-account'; // Navigate to the home page
        }
      });
      reset();


    } catch (error) {
      console.error(error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "There was an error sending your message. Please try again later.",
        confirmButtonText: "OK",
      });
    }
  };
  const onSubmit2 = async (formData) => {
    setPartnerModal2(false)
    try {
      let obj = {
        address_1: formData?.address1,
        address_2: formData?.address2,
        zip_code: formData?.zip,
        emirate: selectedOption?.emirate,
        emirate_id: selectedOption?._id

      };

      const { message } = await OrderServices.UpdateProfile(obj);
      console.log(message);
      if (message) {

        userProfile()
      }
      Swal.fire({
        title: 'Address Updated',

        icon: "success",
        background: "#0f172a", // Change to your desired background color
        color: "#fff", // Change to your desired text color
      }).then((result) => {
        if (result.isConfirmed) {
          // window.location.href = '/my-account'; // Navigate to the home page
        }
      });
      register2();


    } catch (error) {
      console.error(error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "There was an error sending your message. Please try again later.",
        confirmButtonText: "OK",
      });
    }
  };
  const [libraryImages, setLibraryImages] = useState([]);
  const Tabs = [
    { id: 0, name: "My Account", icon: <UserIcon nextHover1={nextHover1} /> },
    { id: 1, name: "My Library", icon: <LibraryIcon nextHover2={nextHover2} /> },
    { id: 2, name: "My Designs", icon: <DrawIcon nextHover2={nextHover2} /> },
    { id: 3, name: "Address", icon: <MapIcon nextHover3={nextHover3} /> },
    // { id: 4, name: "My Orders", icon: <CartIcon nextHover4={nextHover4} /> },
    { id: 5, name: "Prompt Credits", icon: <FaClipboardList style={{ fontSize: "20px" }} /> },
    //  { id: 5, name: 'Log out', icon: <LogoutIcon /> }
  ];

  const [isLessThan1200, setIsLessThan1200] = useState(
    window.innerWidth < 1200
  );

  const handleLogout = async () => {
    try {
      localStorage.clear();
      window.location.href = "/";

      console.log("User signed out");
    } catch (error) {
      console.error("Error during sign out: ", error);
    }
  };
  const handleChange = (event) => {

    setSelectedOption(event.target.value);

  };

  const userProfile = async (sendData) => {
    console.log(sendData, "data");
    try {
      let params = {};
      const { data } = await OrderServices.userProfile(params);
      console.log(data?.details, 'data');

      setUserData(data?.details);
      setUser(data?.details)
    } catch (error) {
      console.log(error);
    } finally {
    }
  };
  const getLibrary = async (sendData) => {
    console.log(sendData, "data");
    try {
      let params = {};
      const { data } = await OrderServices.getLibrary(params);
      console.log(data);
      setLibraryImages(data?.library);
      setLibraryLoader(false)
    } catch (error) {
      console.log(error);
    } finally {
    }
  };
  const getDesigns = async (sendData) => {
    console.log(sendData, "data");
    try {
      let params = {};
      const { data } = await OrderServices.getDesigns(params);
      console.log(data);

      setDesigns(data?.designs);
      setDesignLoader(false)
    } catch (error) {
      console.log(error);
    } finally {
    }
  };
  const getOrderList = async () => {
    try {
      let params = {};
      const { data } = await OrderServices.getOrderList(params);
      console.log(data);
      setOrders(data?.orders);
    } catch (error) {
      console.log(error);
    } finally {
    }
  };
  const SendOtp = async (sendData, result) => {
    console.log(sendData, "data");
    setOtp('')
    reset4()
    try {
      let obj = {
        email: userData.email
      };

      const data = await AuthServices.SendOtp(obj);
      console.log(data);
      if (data.responseCode == 206) {
        setOtpEnable(true)
        setPartnerModal3(true);
      }
    } catch (error) {


    } finally {
    }
  };
  const SubmitOTP2 = async (sendData, result) => {
    console.log(sendData, "data");
    try {
      let obj = {
        email: userData?.email,
        otp: otp,
      };

      const data = await AuthServices.SendOtp(obj);
      console.log(data, 'tesetttt');
      if (data.responseCode == 206) {

        setOtpToken(data?.data?.otp_token)
        setOtpEnable(false)



      }
    } catch (error) {
      setOtpError(true)
    } finally {
    }
  };
  const UpdatePassword = async (sendData, result) => {

    try {
      let obj = {
        otp_token: otpToken,
        email: userData?.email,
        password: getValues4('password'),
        confirm_password: getValues4('confirmPassword'),
      };

      const data = await AuthServices.SendOtp(obj);
      console.log(data);

      if (data.responseCode == 200) {
        setOtp('')
        setPartnerModal3(false)
        setOtpEnable(false)
        reset4()

        Swal.fire({
          title: data?.message,

          icon: "success",
          background: "#0f172a", // Change to your desired background color
          color: "#fff", // Change to your desired text color
        }).then((result) => {
          if (result.isConfirmed) {
            // window.location.href = '/my-account'; // Navigate to the home page
          }
        });

      }
    } catch (error) {
      setOtpError(true)
    } finally {
    }
  };
  const getCreditList = async () => {
    try {
      let params = {};
      const { data } = await OrderServices.getCreditList(params);
      setCredits(data?.history);
    } catch (error) {
      console.log(error);
    } finally {
    }
  };
  useEffect(() => {
    const handleResize = () => {
      setIsLessThan1200(window.innerWidth < 1200);
      console.log(window.innerWidth < 1000);
    };

    window.addEventListener("resize", handleResize);

    // Clean up the event listener
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  useEffect(() => {
    userProfile();
    getLibrary();
    getDesigns();
    getCreditList();
    getOrderList();
    getPricing()

  }, []);

  useEffect(() => {
    if (state) {


      setSelectedTab(Tabs.find((item => item.id == state)))
    }
    else {
      setSelectedTab({ id: 0, name: "My Account", icon: <UserIcon nextHover1={nextHover1} /> })
    }
  }, [state]);
  return (
    <Fragment>
      <Dialog
        open={partnerModal}
        onClose={() => setPartnerModal(false)}
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
        sx={{
          ".MuiPaper-root": {
            background: '#0c1427',
            borderRadius: "12px",
          }
        }}
        maxWidth="sm" // Adjust the maxWidth as per your needs
      >

        <Box
          sx={{
            backgroundColor: '#0c1427',
            boxShadow: 24,
            p: 4,
            borderRadius: '12px',
            overflow: 'hidden'
          }}
        >

          <Typography
            className="heading-font2"
            id="dialog-title"
            variant="h6"
            sx={{ color: 'white', textAlign: 'center' }}
            component="h2"
          >
            Edit Profile
          </Typography>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={2} mt={2} mb={2}>
              <Grid item xs={12}>
                <Box
                  component={"p"}
                  sx={{
                    fontFamily: "Noto Sans, sans-serif",
                    color: "white",
                    fontSize: {
                      lg: "15px",
                      md: "15px",
                      sm: "15px",
                      xs: "12px",
                    },
                  }}
                >
                  Profile Picture :
                </Box>
                <Box
                  component={'div'}
                  onMouseEnter={() => setHovered(true)}
                  onMouseLeave={() => setHovered(false)}
                  sx={{
                    position: "relative",
                    width: 75,
                    height: 75,
                    mt: 2,
                  }}

                >
                  <Avatar

                    src={imageURL ? process.env.REACT_APP_BASE_URL + imageURL : ""}
                    alt={userData?.name}
                    sx={{
                      position: 'relative',
                      width: "100%",
                      height: "100%",
                      borderRadius: "50%",
                      fontSize: 24
                      , backgroundColor: imageURL ? '' : '#0EA5EA',
                      color: "white",
                      cursor: "pointer",
                      objectFit: "cover",
                      textTransform: 'capitalize'
                    }}
                    onClick={handleImageClick}
                  >

                  </Avatar>
                  {hovered && (
                    <IconButton
                      sx={{
                        position: "absolute",
                        top: "0",
                        left: "0",
                        width: "100%",
                        padding: "9px 15px",

                        color: "white",
                        backgroundColor: "rgba(0, 0, 0, 0.5)",
                        borderRadius: "50%",
                        display: "block",
                        "&:hover": {
                          backgroundColor: "rgba(0, 0, 0, 0.7)",
                        },
                      }}
                      onClick={handleImageClick}
                    >
                      <UploadIcon />
                      <Box sx={{ fontSize: "12px" }}>Upload Image</Box>
                    </IconButton>
                  )}
                  <input
                    type="file"
                    ref={fileInputRef}
                    style={{ display: "none" }}
                    onChange={handleFileChange}
                    accept="image/*"
                  />
                </Box>
              </Grid>
              <Grid item xs={12} mt={2}>
                <TextField

                  label="Name"
                  {...register('name', { required: true })}
                  error={!!errors.name}
                  sx={{
                    input: { color: 'white' },
                    label: { color: 'white' },
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: `1px solid ${Colors.secondary}`,
                      },
                      "&:hover fieldset": {
                        border: `1px solid ${Colors.secondary}`,
                      }
                    },
                  }}
                  helperText={errors.name ? 'name is required' : ''}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} mt={2}>
                <TextField
                  id="mobile"
                  name="mobile"
                  label={'Mobile Number'}
                  type='number'
                  InputLabelProps={{
                    shrink: watch('mobile')
                  }}
                  variant="outlined"

                  sx={{
                    input: { color: 'white' },
                    label: { color: 'white' },
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: `1px solid ${Colors.secondary}`,
                      },
                      "&:hover fieldset": {
                        border: `1px solid ${Colors.secondary}`,
                      }
                    },
                  }}
                  {...register("mobile", {
                    required: "Please enter your mobile number.",
                    pattern: {
                      value: /^971[0-9]{9}$/,
                      message: "Please enter a valid UAE phone number (starting with 971 and 9 digits)."
                    }
                  })}
                  helperText={errors?.mobile?.message}
                  error={!!errors?.mobile}
                  fullWidth
                  autoComplete="tel"
                />
              </Grid>


              <Grid item xs={12} display={'flex'} justifyContent={'center'}>
                <SecondaryButton
                  type="submit"
                  bgColor="#0bc1d9"

                  className="checkout-btn"
                  iconPosition="end"

                  p="12px 40px"
                  fontWeight="bold"
                  color="#0C1427"
                  title="Update"
                />
              </Grid>
            </Grid>
          </form>
        </Box>

      </Dialog>
      <Dialog
        open={partnerModal2}
        onClose={() => setPartnerModal2(false)}
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
        sx={{
          ".MuiPaper-root": {
            background: '#0c1427',
            borderRadius: "12px",
          }
        }}
        maxWidth="sm" // Adjust the maxWidth as per your needs
      >

        <Box
          sx={{
            backgroundColor: '#0c1427',
            boxShadow: 24,
            p: 4,
            borderRadius: '12px',
            overflow: 'hidden'
          }}
        >

          <Typography
            className="heading-font2"
            id="dialog-title"
            variant="h6"
            sx={{ color: 'white', textAlign: 'center' }}
            component="h2"
          >
            Edit Profile
          </Typography>
          <form onSubmit={handleSubmit2(onSubmit2)}>
            <Grid container spacing={2} mt={2} mb={2}>

              <Grid item xs={12} mt={2}>
                <TextField

                  label="Address Line 1"
                  {...register2('address1', { required: true })}
                  error={!!errors.address1}
                  sx={{
                    input: { color: 'white' },
                    label: { color: 'white' },
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: `1px solid ${Colors.secondary}`,
                      },
                      "&:hover fieldset": {
                        border: `1px solid ${Colors.secondary}`,
                      }
                    },
                  }}
                  helperText={errors.address1 ? 'address is required' : ''}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} mt={2}>
                <TextField

                  label="Address Line 2"
                  {...register2('address2', { required: false })}

                  sx={{
                    input: { color: 'white' },
                    label: { color: 'white' },
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: `1px solid ${Colors.secondary}`,
                      },
                      "&:hover fieldset": {
                        border: `1px solid ${Colors.secondary}`,
                      }
                    },
                  }}

                  fullWidth
                />
              </Grid>
              <Grid item xs={12} mt={2}>

                <FormControl fullWidth error={!!errors2?.emirates}>
                  <InputLabel id="demo-simple-select-label" sx={{ color: "white" }}>Emirates</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    name="emirates"
                    label={'Emirates'}

                    {...register2("emirates", {
                      required: selectedOption ? false : "Emirates is Required",
                    })}
                    sx={{
                      input: { color: 'white' },
                      label: { color: 'white' },
                      color: 'white',
                      "&.MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: "white",
                          border: `1px solid ${Colors.secondary}`,
                          color: 'white',
                        },
                        "&:hover fieldset": {
                          border: `1px solid ${Colors.secondary}`,
                        }
                      },
                      svg: {
                        color: "white"
                      }
                    }}
                    value={selectedOption}
                    onChange={handleChange}
                  >
                    {emirates?.map((option) => (
                      <MenuItem key={option} value={option}>
                        {option?.emirate}
                      </MenuItem>
                    ))}
                  </Select>
                  {errors2?.emirates && (
                    <FormHelperText sx={{ color: 'red' }}>
                      {errors2.emirates.message}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} mt={2}>
                <TextField

                  label="ZIP Code"
                  {...register2('zip', { required: true })}
                  error={!!errors.zip}
                  sx={{
                    input: { color: 'white' },
                    label: { color: 'white' },
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: `1px solid ${Colors.secondary}`,
                      },
                      "&:hover fieldset": {
                        border: `1px solid ${Colors.secondary}`,
                      }
                    },
                  }}
                  helperText={errors.zip ? 'zip is required' : ''}
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} display={'flex'} justifyContent={'center'}>
                <SecondaryButton
                  type="submit"
                  bgColor="#0bc1d9"

                  className="checkout-btn"
                  iconPosition="end"

                  p="12px 40px"
                  fontWeight="bold"
                  color="#0C1427"
                  title="Update"
                />
              </Grid>
            </Grid>
          </form>
        </Box>

      </Dialog>
      <Dialog
        open={partnerModal3}
        onClose={() => setPartnerModal3(false)}
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
        sx={{
          ".MuiPaper-root": {
            background: '#0c1427',
            borderRadius: "12px",
          }
        }}
        maxWidth="md" // Adjust the maxWidth as per your needs
      >

        <Box
          sx={{
            backgroundColor: '#0c1427',
            boxShadow: 24,
            p: 4,
            borderRadius: '12px',
            overflow: 'hidden'
          }}
        >


          <form onSubmit={handleSubmit4(UpdatePassword)}>
            <Typography
              className="heading-font"
              variant="h5"
              mb={2}
              sx={{
                fontWeight: 600,
                textAlign: "center",
                fontFamily: "Plus Jakarta Sans",
              }}
            >
              {otpEnable ? 'Enter OTP' : 'Update Password'}
            </Typography>
            <Grid container spacing={2} mt={2} mb={2} display={'block'} justifyContent={'center'}>

              {otpEnable ? (
                <>

                  <div className="otp-container">
                    <OTPInput
                      value={otp}
                      onChange={setOtp}
                      numInputs={4}
                      renderSeparator={<span className="separator">-</span>}
                      renderInput={(props) => (
                        <input className="otp-input" {...props} />
                      )}
                    />
                  </div>{" "}
                  {otpError && <div style={{ color: 'red', marginTop: '5px', fontSize: '12px' }}> &nbsp; OTP is Invalid </div>}
                  <Button
                    fullWidth
                    variant="contained"
                    disabled={otp?.length < 4}
                    sx={{
                      backgroundColor: "#0EA5EA",
                      color: "#22272d",
                      padding: "6px",
                      fontSize: "16px",
                      fontWeight: "bold",
                      borderRadius: 2,
                      textTransform: "capitalize",
                      mt: 3,

                      "&:hover": { backgroundColor: "#0EA5EA" },
                    }}
                    onClick={() => SubmitOTP2()}
                  >
                    Submit
                  </Button>{" "}
                </>
              ) : <Grid container sx={{ width: "100%" }}  >
                {/* Password Field */}
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    placeholder="Password"
                    variant="outlined"

                    type={showPassword ? "text" : "password"}
                    {...register4("password", {
                      required: "Password is required",
                    })}
                    error={!!errors4.password}
                    // helperText={
                    //   errors4.password ? errors4.password.message : ""
                    // }
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Box
                            component={"img"}
                            src={Images.passwordIcon}
                            width={"17px"}
                          ></Box>
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() => setShowPassword(!showPassword)}
                            edge="end"
                          >
                            {showPasswordLogin ? (
                              <VisibilityIcon
                                sx={{ color: "#0F172A", fontSize: "20px" }}
                              />
                            ) : (
                              <VisibilityOffIcon
                                sx={{ color: "#0F172A", fontSize: "20px" }}
                              />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                      style: {
                        color: "#0F172A",
                        fontSize: "16px",
                        borderRadius: 8, // Rounded corners
                      },
                    }}
                    sx={{
                      my: 1,
                      backgroundColor: "#1E1E1E", // Dark background color
                      "& .MuiOutlinedInput-root": {
                        backgroundColor: "white",
                        "& fieldset": { borderColor: "transparent" }, // Blue border
                        "&:hover fieldset": { borderColor: "transparent" },
                        "&.Mui-focused fieldset": {
                          borderColor: "transparent",
                        },
                      },
                      "&.MuiFormControl-fullWidth": {
                        background: "white !important",
                        borderRadius: '10px !important'
                      },
                      "& .MuiInputLabel-root": { color: "black" },
                      "& .MuiInputLabel-root.Mui-focused": { color: "black" },
                    }}
                  />
                  {errors4.password && (
                    <Box sx={{ color: "red", fontSize: "12px", mt: 0.5 }}>
                      {errors4.password.message}
                    </Box>
                  )}
                </Grid>
                {/* Confirm Password Field */}
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    placeholder="Confirm Password"

                    variant="outlined"
                    type={showPassword ? "text" : "password"}
                    {...register4("confirmPassword", {
                      required: "Confirm Password is required",
                      validate: (value) =>
                        value === getValues4("password") ||
                        "Passwords do not match",
                    })}
                    error={!!errors4.confirmPassword}
                    // helperText={
                    //   errors4.confirmPassword
                    //     ? errors4.confirmPassword.message
                    //     : ""
                    // }
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Box
                            component={"img"}
                            src={Images.passwordIcon}
                            width={"17px"}
                          ></Box>
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() => setShowPassword2(!showPassword2)}
                            edge="end"
                          >
                            {showPasswordLogin ? (
                              <VisibilityIcon
                                sx={{ color: "#0F172A", fontSize: "20px" }}
                              />
                            ) : (
                              <VisibilityOffIcon
                                sx={{ color: "#0F172A", fontSize: "20px" }}
                              />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                      style: {
                        color: "#0F172A",
                        fontSize: "16px",
                        borderRadius: 8, // Rounded corners
                      },
                    }}
                    sx={{
                      my: 1,
                      backgroundColor: "#1E1E1E", // Dark background color
                      "& .MuiOutlinedInput-root": {
                        backgroundColor: "white",
                        "& fieldset": { borderColor: "transparent" }, // Blue border
                        "&:hover fieldset": { borderColor: "transparent" },
                        "&.Mui-focused fieldset": {
                          borderColor: "transparent",
                        },
                      },
                      "&.MuiFormControl-fullWidth": {
                        background: "white !important",
                        borderRadius: '10px !important'
                      },
                      "& .MuiInputLabel-root": { color: "black" },
                      "& .MuiInputLabel-root.Mui-focused": { color: "black" },
                    }}
                  />
                  {errors4.confirmPassword && (
                    <Box sx={{ color: "red", fontSize: "12px", mt: 0.5 }}>
                      {errors4.confirmPassword.message}
                    </Box>
                  )}
                </Grid>


                <Button
                  fullWidth
                  variant="contained"
                  type="submit"
                  sx={{
                    backgroundColor: "#0EA5EA",
                    color: "#22272d",
                    padding: "6px",
                    fontSize: "16px",
                    fontWeight: "bold",
                    borderRadius: 2,
                    textTransform: "capitalize",
                    mt: 3,

                    "&:hover": { backgroundColor: "#0EA5EA" },
                  }}

                >
                  Submit
                </Button>{" "}
              </Grid>}





            </Grid>
          </form>
        </Box>

      </Dialog>
      <Box
        sx={{
          backgroundImage: `url("${Images.contactBg}")`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          width: "100%",
          height: {lg:"300px",md:'300px',sm:'220px',xs:'220px'},
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Typography
            component="span"
            sx={{
              display: "flex",
              flexWrap: "wrap",
              gap: "8px",
            }}
          >

            <Typography

              component="span"
              sx={{
                fontWeight: "bold",
                fontSize: "35px",
                textAlign: "center",
                fontFamily: "Noto Sans, sans-serif",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
                display: "inline",
                cursor: "pointer",

              }}
              className="heading-font textHover"
            >
              {selectedTab?.name}
            </Typography>

          </Typography>

          {/* <p style={{ fontSize: '25px', textAlign: 'center', color: 'white', fontFamily: "Noto Sans, sans-serif", }}>Homepage || <span style={{ color: Colors.secondary }}>Contact Us</span></p> */}
        </Box>
      </Box>
      {isLessThan1200 && (
        <Box sx={{ display: "flex", width: "87%", margin: "0 auto" }}>
          {Tabs.map((item, index) => (
            <Box
              component={"div"}
              onClick={() => {

                setSelectedTab(item);
              }}
              key={index}
              sx={{
                display: "flex",
                justifyContent: "center",

                width: "100%",
                color: selectedTab.id == item.id ? "#0EA5EA" : "#a1acb1",
                marginTop: "10%",
                cursor: "pointer",
                paddingBottom: "14px",
                fontFamily: "Noto Sans, sans-serif",
                fontSize: "13px",
              }}
            >
              <Box
                sx={{
                  borderBottom:
                    selectedTab.id == item.id
                      ? "2px solid #0EA5EA"
                      : "2px solid rgba(127, 146, 176, 1)",
                  // borderRadius: '50%',
                  width: "50px",
                  height: "50px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fill: selectedTab.id == item.id ? "#0EA5EA" : "#a1acb1"
                }}
              >
                {item.icon}
              </Box>
            </Box>
          ))}
        </Box>
      )}
      <Box sx={{ py: 8, px: { md: 8, sm: 2, xs: 2 } }}>
        <Grid
          container
          justifyContent={"space-between"}
          gap={5}
          // p={4}
          pt={0}
        >
          {!isLessThan1200 && (
            <Grid item md={3} lg={3}>
              {Tabs.map((item, index) => (
                <Box
                  component={"div"}
                  onClick={() => {
                    console.log("na");
                    setSelectedTab(item);
                  }}
                  key={index}
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    borderBottom:
                      selectedTab.id == item.id
                        ? "1px solid #0EA5EA"
                        : "1px solid rgba(127, 146, 176, 1)",
                    width: "100%",
                    color: selectedTab.id == item.id ? "#0EA5EA" : "#a1acb1",
                    marginTop: "10%",
                    cursor: "pointer",
                    paddingBottom: "14px",
                    fontFamily: "Noto Sans, sans-serif",
                    fontSize: "13px",
                  }}
                >
                  <Box component={'span'} className="heading-font2">{item.name}</Box>
                  <Box sx={{ fill: selectedTab.id == item.id ? "#0EA5EA" : "#a1acb1" }}>{item.icon}</Box>
                </Box>
              ))}
            </Grid>
          )}
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={7.5}
            sx={{
              display: "flex",
              justifyContent: "center",
              // mt: "2%",
              p: "10px",
            }}
          >
            {selectedTab.id == 0 && (
              <Box sx={{ width: "100%", margin: "0 auto" }}>
                <Box
                  sx={{
                    mt: "2%",
                    display: "flex",
                    justifyContent: "space-around",
                    textAlign: "left",
                  }}
                >
                  <Box
                    sx={{
                      width: { lg: "50%", md: "50%", sm: "50%", xs: "100%" },
                    }}
                  >
                    <Box
                      component={"p"}
                      sx={{
                        fontFamily: "Noto Sans, sans-serif",
                        color: "#a1acb1",
                        fontSize: {
                          lg: "15px",
                          md: "15px",
                          sm: "15px",
                          xs: "12px",
                        },
                      }}
                    >
                      Profile Picture:
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      width: "50%",
                      justifyContent: {
                        lg: "flex-start",
                        md: "flex-end",
                        sm: "flex-end",
                        xs: "flex-end",
                      },
                      display: {
                        lg: "flex",
                        md: "flex",
                        sm: "flex",
                        xs: "flex",
                      },
                    }}
                  >
                    <Box component={'div'} display={'flex'} sx={{ justifyContent: { lg: 'space-between', md: 'space-between', sm: 'flex-end', xs: 'flex-end' } }} flexWrap={'wrap'} gap={2}  >
                      <PrimaryButton
                        title={"Edit Profile"}
                        fontSize={{
                          lg: "15px",
                          md: "15px",
                          sm: "15px",
                          xs: "8px"
                        }}
                        sx={{
                          background: Colors.contactGradient2,
                          "-webkit-text-fill-color": "rgba(15, 23, 42, 1)",
                          borderRadius: "15px",
                          padding: { lg: "7px 45px !important", md: "7px 45px !important", sm: "7px 45px !important", xs: "5px 23px !important" },

                          fontWeight: "bold",


                        }}
                        onClick={() => { console.log(userData); setPartnerModal(true); setValue('name', userData?.name); setValue('mobile', userData?.phone); setImageURL(userData?.picture) }}
                      />
                      {!userData?.google_id && <PrimaryButton
                        title={"Update Password"}
                        fontSize={{
                          lg: "15px",
                          md: "15px",
                          sm: "15px",
                          xs: "8px"
                        }}
                        sx={{
                          background: Colors.contactGradient2,
                          "-webkit-text-fill-color": "rgba(15, 23, 42, 1)",
                          borderRadius: "15px",
                          padding: { lg: "7px 20px !important", md: "7px 20px !important", sm: "7px 20px !important", xs: "5px 10px !important" },

                          fontWeight: "bold",


                        }}
                        onClick={() => { console.log(userData); SendOtp() }}
                      />}
                    </Box>
                  </Box>
                </Box>
                <Box sx={{ display: "flex", mt: 2 }}>
                  <Box sx={{ width: "50%" }}>
                    <Avatar
                      sx={{ width: "60px", height: "60px", textTransform: 'capitalize', backgroundColor: userData?.picture ? '' : '#0EA5EA' }}

                      src={userData?.picture ? process.env.REACT_APP_BASE_URL + userData?.picture : ""}
                      alt={userData?.name}
                    />
                  </Box>
                </Box>
                <Box
                  sx={{
                    mt: "5%",
                    display: "flex",
                    justifyContent: "space-around",
                    textAlign: "left",
                  }}
                >
                  <Box sx={{ width: "50%" }}>
                    <Box
                      component={"p"}
                      sx={{
                        fontFamily: "Noto Sans, sans-serif",
                        color: "#a1acb1",
                        fontSize: {
                          lg: "15px",
                          md: "15px",
                          sm: "15px",
                          xs: "12px",
                        },
                      }}
                    >
                      Name:
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      width: "50%",
                      display: "flex",
                      justifyContent: {
                        lg: "flex-start",
                        md: "flex-end",
                        sm: "flex-end",
                        xs: "flex-end",
                      },
                    }}
                  >
                    <Box
                      component={"p"}
                      sx={{
                        fontFamily: "Noto Sans, sans-serif",
                        color: "white",
                        fontSize: {
                          lg: "15px",
                          md: "15px",
                          sm: "15px",
                          xs: "12px",
                        },
                      }}
                    >
                      {userData?.name}
                    </Box>
                  </Box>
                </Box>
                <Box
                  sx={{
                    mt: "5%",
                    display: "flex",
                    justifyContent: "space-around",
                    textAlign: "left",
                  }}
                >
                  <Box sx={{ width: "50%" }}>
                    <Box
                      component={"p"}
                      sx={{
                        fontFamily: "Noto Sans, sans-serif",
                        color: "#a1acb1",
                        fontSize: {
                          lg: "15px",
                          md: "15px",
                          sm: "15px",
                          xs: "12px",
                        },
                      }}
                    >
                      Email Address:
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      width: "50%",
                      display: "flex",
                      justifyContent: {
                        lg: "flex-start",
                        md: "flex-end",
                        sm: "flex-end",
                        xs: "flex-end",
                      },
                    }}
                  >
                    <Typography
                      sx={{
                        fontFamily: "Noto Sans, sans-serif",
                        color: "white",
                        fontSize: {
                          lg: "15px",
                          md: "15px",
                          sm: "15px",
                          xs: "12px",
                        },
                      }}
                    >
                      {userData?.email}
                    </Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    mt: "5%",
                    display: "flex",
                    justifyContent: "space-around",
                    textAlign: "left",
                  }}
                >
                  <Box sx={{ width: "50%" }}>
                    <Box
                      component={"p"}
                      sx={{
                        fontFamily: "Noto Sans, sans-serif",
                        color: "#a1acb1",
                        fontSize: {
                          lg: "15px",
                          md: "15px",
                          sm: "15px",
                          xs: "12px",
                        },
                      }}
                    >
                      Phone Number:
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      width: "50%",
                      display: "flex",
                      justifyContent: {
                        lg: "flex-start",
                        md: "flex-end",
                        sm: "flex-end",
                        xs: "flex-end",
                      },
                    }}
                  >
                    <Box
                      component={"p"}
                      sx={{
                        fontFamily: "Noto Sans, sans-serif",
                        color: "white",
                        fontSize: {
                          lg: "15px",
                          md: "15px",
                          sm: "15px",
                          xs: "12px",
                        },
                      }}
                    >
                      {userData?.phone}
                    </Box>
                  </Box>
                </Box>
                <Box
                  sx={{
                    mt: 2,
                    width: "100%",
                    justifyContent: "center",
                    display: { lg: "none", md: "none", sm: "none", xs: "flex" },
                  }}
                >
                  {/* <Box
                    sx={{
                      background:
                        "linear-gradient(270deg, #0BD1D1 -88.59%, #0EA5EA 96.66%)",
                      width: "100px",
                      borderRadius: "10px",
                      textAlign: "center",
                      fontWeight: "bold",
                      py: 1,
                      px: 1,
                      cursor: "pointer",
                    }}
                  >
                    Edit Profile
                  </Box> */}
                </Box>
              </Box>
            )}
            {selectedTab.id == 1 && (
              <Box sx={{ width: "100%", margin: "0 auto",display:'flex' }}>
                <Grid
                  container
                  sx={{
                    justifyContent: { lg: "flex-start", md: "center", sm: "center", xs: "center" },
                  }}
                >
                  {!libraryLoader ? (
                    libraryImages && libraryImages.length > 0 ? (
                      libraryImages.map((item, index) => {
                        const isLoaded = loadingStates[index] || false;
                        console.log(isLoaded, "isloaded");

                        return (
                          <Grid
                            key={index}
                            onClick={() => navigate(`/gallery`, { state: item })}
                            item
                            sx={{
                              cursor: "pointer",
                              p: "10px",
                              width: "auto",
                            }}
                          >
                            <Box
                              sx={{
                                position: "relative",
                                backgroundColor: "#0f4363",
                                borderRadius: "15px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                width: "210px",
                                height: "210px",
                              }}
                            >
                              {!isLoaded && (
                                <Box
                                  component={"img"}
                                  src={Images.loader}
                                  width={"100px"}
                                  height={"100px"}
                                />
                              )}
                              <Box

                              className="image-selection"
                                component="img"
                                src={item?.url}
                                width={"100%"}
                                height={"100%"}
                                onLoad={() => handleImageLoad(index)}
                                onError={(e) => {
                                  e.target.onerror = null;
                                  e.target.style.backgroundColor = "grey";
                                  e.target.innerHTML = "No preview available";
                                }}
                                sx={{
                                  borderRadius: "15px",
                                  objectFit: "contain",
                                  display: isLoaded ? "block" : "none",
                                }}
                              />
                            </Box>
                          </Grid>
                        );
                      })
                    ) : (
                      <Grid item xs={12} display={"flex"} justifyContent={"center"} alignItems={'center'}>
                        <Box
                          sx={{
                            mt: 5,
                            fontSize: "18px",
                            color: "gray",
                            textAlign: "center",
                          }}
                        >
                          No images available
                        </Box>
                      </Grid>
                    )
                  ) : (
                    <Grid item xs={12} display={"flex"} justifyContent={"center"}>
                      <Box
                        component={"img"}
                        src={Images.loader}
                        sx={{ mt: 5 }}
                        width={"200px"}
                        height={"200px"}
                      />
                    </Grid>
                  )}

                </Grid>
              </Box>
            )}
            {selectedTab.id == 2 && (
              <Box sx={{ width: "100%", margin: "0 auto",display:'flex' }}>
                <Grid container sx={{ justifyContent: { lg: "flex-start", md: "center", sm: 'center', xs: "center" } }}>
                  {!designLoader ? (
                    designs && designs.length > 0 ? (
                      designs.map((item, index) => {
                        const isLoaded = loadingStates2[index] || false;

                        return (
                          <Grid
                            key={index}
                            onClick={() => {
                              navigate(`/product-final`);
                              localStorage.removeItem("saveDesign");
                              localStorage.setItem("designArray", JSON.stringify(item?.design));
                              localStorage.setItem("saveDesign", JSON.stringify(item));
                            }}
                            item
                            sx={{
                              cursor: "pointer",
                              p: "10px",
                              width: "auto",
                            }}
                          >
                            <Box
                              sx={{
                                position: "relative",
                                backgroundColor: "#0f4363",
                                borderRadius: "15px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                width: "210px",
                                height: "210px",
                              }}
                            >
                              {!isLoaded && (
                                <Box component={"img"} src={Images.loader} width={"100px"} height={"100px"} />
                              )}
                              <Box
                              className="image-selection"
                                component="img"
                                src={item?.generated_art}
                                width={"100%"}
                                height={"100%"}
                                onLoad={() => handleImageLoad2(index)}
                                onError={(e) => {
                                  e.target.onerror = null;
                                  e.target.style.backgroundColor = "grey";
                                  e.target.innerHTML = "No preview available";
                                }}
                                sx={{
                                  borderRadius: "15px",
                                  objectFit: "contain",
                                  display: isLoaded ? "block" : "none",
                                }}
                              />
                              <Box
                                sx={{
                                  position: "absolute",
                                  top: 0,
                                  left: 0,
                                  width: "100%",
                                  height: "100%",
                                  backgroundColor: "rgba(0, 0, 0, 0.6)",
                                  color: "white",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  flexDirection: "column",
                                  opacity: 0,
                                  borderRadius: "15px",
                                  transition: "opacity 0.3s ease-in-out",
                                  "&:hover": {
                                    opacity: 1,
                                  },
                                }}
                              >
                                <Box
                                  component="span"
                                  sx={{
                                    fontSize: "16px",
                                    fontWeight: "bold",
                                    textAlign: "center",
                                  }}
                                >
                                  {item.name}
                                </Box>
                                <Box
                                  component="span"
                                  sx={{
                                    fontSize: "16px",
                                    fontWeight: "bold",
                                    textAlign: "center",
                                  }}
                                >
                                  {moment(item.created_at).format("DD-MM-YYYY")}
                                </Box>
                              </Box>
                              <Box
                                sx={{
                                  position: "absolute",
                                  bottom: 10,
                                  right: 10,
                                  backgroundColor: "black",
                                  opacity: 0.6,
                                  borderRadius: "15px",
                                  p: 0.5,
                                }}
                              >
                                <Box component={"img"} sx={{ width: "50px" }} src={Images.mainlogo}></Box>
                              </Box>
                            </Box>
                          </Grid>
                        );
                      })
                    ) : (
                      <Grid item xs={12} display={"flex"} justifyContent={"center"} alignItems={'center'}>
                        <Box
                          sx={{
                            mt: 5,
                            fontSize: "18px",
                            color: "gray",
                            textAlign: "center",
                          }}
                        >
                          No designs available
                        </Box>
                      </Grid>
                    )
                  ) : (
                    <Grid item xs={12} display={"flex"} justifyContent={"center"}>
                      <Box component={"img"} src={Images.loader} sx={{ mt: 5 }} width={"200px"} height={"200px"} />
                    </Grid>
                  )}

                </Grid>
              </Box>
            )}

            {selectedTab.id == 3 && (
              // <Box sx={{ width: '80%', margin: '0 auto' }}>

              //     <Box sx={{ mt: '5%', display: 'flex', justifyContent: 'space-around', textAlign: 'left' }}>
              //         <Box sx={{ width: '50%' }}>
              //             <p style={{ fontFamily: "Noto Sans, sans-serif", color: '#a1acb1' }}>Address Line 1:</p></Box>
              //         <Box sx={{ width: '50%' }}>
              //             <p style={{ fontFamily: "Noto Sans, sans-serif", color: 'white' }}>{userData?.address_1}</p>
              //         </Box>
              //     </Box>
              //     <Box sx={{ mt: '5%', display: 'flex', justifyContent: 'space-around', textAlign: 'left' }}>
              //         <Box sx={{ width: '50%' }}>
              //             <p style={{ fontFamily: "Noto Sans, sans-serif", color: '#a1acb1' }}>Address Line 2:</p></Box>
              //         <Box sx={{ width: '50%' }}>
              //             <p style={{ fontFamily: "Noto Sans, sans-serif", color: 'white' }}>{userData?.address_2}</p>
              //         </Box>
              //     </Box>
              //     <Box sx={{ mt: '5%', display: 'flex', justifyContent: 'space-around', textAlign: 'left' }}>
              //         <Box sx={{ width: '50%' }}>
              //             <p style={{ fontFamily: "Noto Sans, sans-serif", color: '#a1acb1' }}>Emirates:</p></Box>
              //         <Box sx={{ width: '50%' }}>
              //             <p style={{ fontFamily: "Noto Sans, sans-serif", color: 'white' }}>{userData?.emirate}</p>
              //         </Box>
              //     </Box>

              //     <Box sx={{ mt: '5%', display: 'flex', justifyContent: 'space-around', textAlign: 'left' }}>
              //         <Box sx={{ width: '50%' }}>
              //             <p style={{ fontFamily: "Noto Sans, sans-serif", color: '#a1acb1' }}>ZIP Code:</p></Box>
              //         <Box sx={{ width: '50%' }}>
              //             <p style={{ fontFamily: "Noto Sans, sans-serif", color: 'white' }}>{userData?.zip_code}</p>
              //         </Box>
              //     </Box>

              // </Box>
              <Grid container spacing={3} sx={{ justifyContent: { lg: 'flex-start', md: 'center', sm: 'center', xs: 'center' } }}>
                {addressData.map((address, index) => (
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={9}
                    key={address.id}
                    sx={{ mb: 3, mt: 1}}
                  >
                    <Card
                      sx={{
                        background:
                          "linear-gradient(359.74deg, rgba(152, 161, 174, 0) 6.49%, rgba(11, 209, 209, 0.08) 100%)",
                        color: "#ffffff",
                        borderRadius: "10px",
                        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                        padding: "16px",
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                      }}
                    >
                      {/* Card Header */}
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        mb={2}
                      >
                        <Typography
                          variant="subtitle2"
                          sx={{
                            fontSize: "16px",
                            color: address.isDefault ? "#a5c9ff" : "#0EA5EA",
                          }}
                        >
                          {address.isDefault ? "Default Address" : "Address"}
                        </Typography>

                        <PrimaryButton
                          title={"Edit Address"}
                          fontSize={{
                            lg: "15px",
                            md: "15px",
                            sm: "15px",
                            xs: "10px"
                          }}
                          sx={{
                            background: Colors.contactGradient2,
                            "-webkit-text-fill-color": "rgba(15, 23, 42, 1)",
                            borderRadius: "15px",
                            padding: { lg: "7px 20px !important", md: "7px 20px !important", sm: "7px 20px !important", xs: "5px 10px !important" },

                            fontWeight: "bold",


                          }}
                          onClick={() => {
                            console.log(userData); setPartnerModal2(true); setValue2('address1', userData?.address_1); setValue2('address2', userData?.address_2); setValue2('zip', userData?.zip_code);
                            let filterOption = emirates?.find((item => item?._id == userData?.emirate_id))
                            if (filterOption) {
                              console.log(filterOption, 'filterOption');

                              setSelectedOption(filterOption)
                            }
                            else {
                              setSelectedOption(null)
                            }
                          }}
                        />
                      </Box>

                      {/* Title */}
                      <Typography
                        variant="h6"
                        component="h2"
                        sx={{
                          color: "#0EA5EA",
                          fontWeight: "bold",
                          marginBottom: "8px",
                        }}
                      >
                        {address.isDefault ? "Home" : ""}
                      </Typography>

                      {/* Card Content */}
                      <CardContent sx={{ padding: "0", flexGrow: 1 }}>
                        <Box display="flex" justifyContent="space-between" alignItems="flex-start" sx={{ marginBottom: "28px" }}>
                          <Typography sx={{ color: "#a1acb1" }} >Name :</Typography>
                          <Typography>{userData?.name || "N/A"}</Typography>
                        </Box>
                        <Box display="flex" justifyContent="space-between" alignItems="flex-start" sx={{ marginBottom: "28px", gap: 1 }}>
                          <Typography sx={{ color: "#a1acb1", width: '50%' }} >Address Line 1:</Typography>
                          <Typography sx={{
                            width: '50%', textWrap: 'wrap', wordBreak: 'break-all', textAlign: 'right'
                          }}>{userData?.address_1 || "N/A"}</Typography>
                        </Box>
                        <Box display="flex" justifyContent="space-between" alignItems="flex-start" sx={{ marginBottom: "28px" }}>
                          <Typography sx={{ color: "#a1acb1", width: '50%' }} >Address Line 2 :</Typography>
                          <Typography sx={{
                            width: '50%', textWrap: 'wrap', wordBreak: 'break-all', textAlign: 'right'
                          }}>{userData?.address_2 || "N/A"}</Typography>
                        </Box>
                        <Box display="flex" justifyContent="space-between" alignItems="flex-start" sx={{ marginBottom: "28px" }}>
                          <Typography sx={{ color: "#a1acb1", width: '50%' }} >Emirate :</Typography>
                          <Typography sx={{
                            width: '50%', textWrap: 'wrap', wordBreak: 'break-all', textAlign: 'right'
                          }}>{userData?.emirate || "N/A"}</Typography>
                        </Box>
                        <Box display="flex" justifyContent="space-between" alignItems="flex-start" sx={{ marginTop: "28px" }}>
                          <Typography sx={{ color: "#a1acb1", width: '50%' }} >Zip Code :</Typography>
                          <Typography sx={{
                            width: '50%', textWrap: 'wrap', wordBreak: 'break-all', textAlign: 'right'
                          }}>{userData?.zip_code || "N/A"}</Typography>
                        </Box>
                      </CardContent>


                      {/* Button */}
                      {/* {!address.isDefault && (
                        <Box mt={2} textAlign="center">
                          <Button
                            variant="contained"
                            sx={{
                              background:
                                "linear-gradient(to right, #0EA5EA, #0CC7D7, #0BD1D1)",
                              fontWeight: "bold",
                              color: "rgba(15, 23, 42, 1)",
                              borderRadius: "20px",
                              textTransform: "none",
                              padding: "6px 16px",
                              "&:hover": {
                                backgroundColor: "#00a4e0",
                              },
                            }}
                          >
                            Make Default
                          </Button>
                        </Box>
                      )} */}
                    </Card>
                  </Grid>
                ))}
              </Grid>
            )}
            {selectedTab.id == 4 && (
              <Grid container rowGap={3} justifyContent={"space-between"}>
                {orders.length > 0 &&
                  orders.map((product) => {
                    return (
                      <Grid
                        item
                        lg={5.8}
                        md={5.8}
                        sm={12}
                        xs={12}
                        p={3}
                        onClick={() =>
                          navigate(`/order-detail`, { state: product })
                        }
                        sx={{
                          cursor: "pointer",
                          backgroundColor: "#1b2335",
                          borderRadius: "15px",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexWrap: "wrap",
                            justifyContent: "space-between",
                            width: "100%",
                            alignItems: "baseline",
                            fontSize: { md: "15px", sm: "15px", xs: "13px" },
                          }}
                        >
                          <Box sx={{ color: "#7F92B0" }}>Order ID</Box>
                          <Box
                            component={"div"}
                            className="heading-font"
                            sx={{ fontWeight: 700 }}
                          >
                            {product?.order_no}
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              width: "100px",
                              height: "25px",
                              textAlign: "center",
                              color: "White",
                              backgroundColor: "#005fc4",
                              borderRadius: "15px",
                              fontWeight: "bold",
                              verticalAlign: "middle",
                              mt: { lg: 0, md: 0, sm: 0, xs: 1 },
                            }}
                          >
                            {/* <Box >
                                                {product?.status == 'pending' ? 'Pending' : product?.status == 'confirmed' ? 'Confirmed' : product?.status == 'delivered' ? 'Delivered' : product?.status == 'processing' ? 'Processing' : "Rejected"    }
                                                </Box> */}
                            <Button
                              variant="outlined"
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: 1,
                                padding: "5px 50px",
                                borderRadius: "8px",
                                textTransform: "none",

                                fontWeight: "bold",
                                backgroundColor:
                                  product?.status == "delivered"
                                    ? "#143D39"
                                    : "#5A4A1E", // Dark green background
                                border:
                                  product?.status == "delivered"
                                    ? "2px solid #3FBF43"
                                    : "2px solid #D4AF37", // Green border
                                color: "#FFFFFF",
                                ":hover": {
                                  fontWeight: "bold",
                                  backgroundColor:
                                    product?.status == "delivered"
                                      ? "#143D39"
                                      : "#5A4A1E", // Dark green background
                                  border:
                                    product?.status == "delivered"
                                      ? "2px solid #3FBF43"
                                      : "2px solid #D4AF37",
                                },
                              }}
                            >
                              <CircleIcon
                                sx={{ fontSize: 12, color: "white" }}
                              />
                              <Typography
                                sx={{
                                  textTransform: "capitalize",
                                  fontSize: {
                                    md: "15px",
                                    sm: "15px",
                                    xs: "13px",
                                  },
                                }}
                              >
                                {product?.status}
                              </Typography>
                            </Button>
                          </Box>
                        </Box>

                        <Divider
                          style={{
                            width: "100%",
                            borderColor: "#B0B0B04D",
                            marginTop: "20px",
                          }}
                        />
                        <Grid
                          container
                          sx={{ mb: 2, mt: 2 }}
                          alignItems={"center"}
                        >
                          <Grid item xs={6} mt={2}>
                            {" "}
                            <Typography
                              variant="body2"
                              sx={{ color: "#8d919a", fontWeight: 500 }}
                            >
                              Order Date{" "}
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            xs={6}
                            sx={{ color: "white", fontWeight: 700 }}
                            mt={2}
                          >
                            {moment(product?.created_at).format("DD-MM-YYYY")}
                          </Grid>
                          <Grid item xs={6} mt={2}>
                            {" "}
                            <Typography
                              variant="body2"
                              sx={{ color: "#8d919a", fontWeight: 500 }}
                            >
                              Quantity
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            xs={6}
                            sx={{ color: "white", fontWeight: 700 }}
                            mt={2}
                          >
                            {product?.total_qty}
                          </Grid>
                          <Grid item xs={6} mt={2}>
                            {" "}
                            <Typography
                              variant="body2"
                              sx={{ color: "#8d919a", fontWeight: 500 }}
                            >
                              Delivery Date{" "}
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            xs={6}
                            sx={{ color: "white", fontWeight: 700 }}
                            mt={2}
                          >
                            TBD
                          </Grid>
                          <Grid item xs={6} mt={2}>
                            {" "}
                            <Typography
                              variant="body2"
                              sx={{ color: "#8d919a", fontWeight: 500 }}
                            >
                              Payment Method{" "}
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            xs={6}
                            sx={{ color: "white", fontWeight: 700 }}
                            mt={2}
                          >
                            Card
                          </Grid>
                          <Grid item xs={6} mt={2}>
                            {" "}
                            <Typography
                              variant="body2"
                              sx={{ color: "#8d919a", fontWeight: 500 }}
                            >
                              Total Pricing{" "}
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            xs={6}
                            sx={{ color: "white", fontWeight: 700 }}
                            mt={2}
                          >
                            {product?.total_amount} AED
                          </Grid>
                        </Grid>
                      </Grid>
                    );
                  })}
              </Grid>
            )}

            {selectedTab.id == 5 && (
              <Box container sx={{ width: "100%" }}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    mb: 1,
                  }}
                >
                  <Typography
                    className="heading-font"
                    sx={{
                      fontSize: { lg: "25px", md: "25px", sm: "25px", xs: '19px' },
                      fontWeight: 600,
                    }}
                  >
                    Credit <Box
                      className="heading-font"
                      sx={{
                        fontSize: { lg: "25px", md: "25px", sm: "25px", xs: '19px' },

                        fontWeight: 600,
                      }}
                      component={"span"}
                    >  Activity
                    </Box>
                  </Typography>
                  <Button
                    variant="outlined"
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 1,
                      padding: "0px 20px",
                      borderRadius: "8px",
                      textTransform: "none",
                      fontSize: { lg: "15px", md: "15px", sm: "15px", xs: '12 px' },

                      fontWeight: "bold",
                      backgroundColor: "#5A4A1E", // Dark green background
                      border: "2px solid #D4AF37", // Green border
                      color: "#FFFFFF",
                      ":hover": {
                        backgroundColor: "#5A4A1E", // Dark green background
                        border: "2px solid #D4AF37", // Green border
                        color: "#FFFFFF",
                      },
                    }}
                  >
                    <Typography sx={{
                      textTransform: "capitalize", fontSize: { lg: "15px", md: "15px", sm: "15px", xs: '12px' },
                    }}>
                      Available Credits : {userData?.available_credit}
                    </Typography>
                  </Button>
                </Box>
                <Box
                  container
                  component={"div"}
                  className="custom-scrollbar"
                  sx={{
                    cursor: "pointer",
                    backgroundColor: "#1b2335",
                    borderRadius: "15px",
                    p: 2,
                    height: "500px",
                    overflow: "auto",
                  }}
                >
                  {credits.length > 0 &&
                    credits.map((item) => {
                      return (
                        <List>
                          <ListItem sx={{ p: 1 }}>
                            <ListItemIcon>
                              {item?.credit_type == "In" ? (
                                <TrendingUpIcon
                                  sx={{ color: "green", fontSize: "35px" }}
                                />
                              ) : (
                                <TrendingDownIcon
                                  sx={{ color: "red", fontSize: "35px" }}
                                />
                              )}
                            </ListItemIcon>
                            <ListItemText
                              primary={
                                <Box
                                  display="flex"
                                  justifyContent="space-between"
                                  alignItems="center"
                                >
                                  <Typography
                                    sx={{
                                      fontSize: "25px",
                                      fontWeight: "bold",
                                      color: "#0ea5ea",
                                    }}
                                  >
                                    {item?.credit_amount}{" "}
                                    <span
                                      style={{
                                        fontWeight: 400,
                                        fontSize: "20px",
                                      }}
                                    >
                                      {" "}
                                      {item?.credit_amount > 1
                                        ? "Credits"
                                        : "Credit"}
                                    </span>
                                  </Typography>
                                  <Typography
                                    sx={{
                                      fontSize: "11px",
                                      color: "gray !important",
                                    }}
                                  >
                                    {moment(item?.created_at).format(
                                      "DD-MM-YYYY"
                                    )}
                                  </Typography>
                                </Box>
                              }
                              secondary={
                                item?.reason == "monthly_reward"
                                  ? "A recurring treat to thank you for staying with us month after month."
                                  : item?.reason == "registration_reward"
                                    ? "A token of appreciation for signing up and joining our community."
                                    : item?.reason == "purchase"
                                      ? `You've topped up your account with 50 credits for the amount of ${item.price} AED.`
                                      : item?.reason == "order_reward"
                                        ? " A little something extra for placing an order and being a valued customer."
                                        : item?.reason == "usage" ? "We've detected your regular usage and activity on our platform—thank you for being active!"

                                          : "A reward for actively engaging with and using our platform regularly."
                              }
                              secondaryTypographyProps={{
                                fontSize: "13px",
                                color: "gray !important",
                              }}
                            />
                          </ListItem>
                          <Divider sx={{ backgroundColor: "gray", m: 1.5 }} />
                        </List>
                      );
                    })}
                </Box>
              </Box>
            )}
          </Grid>
        </Grid>
      </Box>
    </Fragment >
  );
}
